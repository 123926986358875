export * from './dashboard/DashboardPage';
export * from './client/ListagemClientesPage';
export * from './client/DetalheClientesPage';
export * from './user/ListagemUsuariosPage';
export * from './user/DetalheUsuariosPage';
export * from './analysis/AnalysisRequestPage';
export * from './analysis/AnalysisHiringPage';
export * from './analysis/AnalysisDetailPage';
export * from './analysis/AnalysisListPage';
export * from './proposal/ProposalListPage';
export * from './proposal/ProposalDetailPage';
export * from './proposal/ProposalRenovatePage';
export * from './sinister/SinisterListPage';
export * from './sinister/SinisterDetailsPage';
export * from './sinister/SinisterValuesPage';
export * from './financial/FinancialListPage';
export * from './relatorios/ListagemRelatoriosPage';
export * from './gerais/ListagemGeraisPage';
export * from './graficos/GraficosPage';
export * from './permissions/ListPermissionsPage';
export * from './permissions/PermissionsDetailsPage';
export * from './forgot-password/ForgotPasswordPage';
export * from './reset-password/ResetPasswordPage';
export * from './login/LoginPage';
export * from './client/ClientDetailPage';
export * from './financial/NfseListPage';
export * from './financial/FinancialHealthPage';
export * from './fire/budget/components/BudgetPresentation';
export * from './contract/ListagemContratosPage';


