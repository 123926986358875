import moment from "moment";
import { globalBackground, globalColorText, globalTranslate } from "../../../../shared/utils"
import { Box, Button, Grid, Icon, Paper, Tooltip, Typography } from "@mui/material";

interface ISinisterHeaderProps {
    status?: string | null;
    showButtonStatus: boolean;
    showButtonStateProperty?: boolean;
    onclickbuttonStatus?: () => void;
    onclickStateProperty?: () => void;
    textButtonStatus?: string;

    name?: string | null;
    openResponse?: string;
    createAt?: string | null;
    openSinister?: string;
    closeSinister?: string;
    certificate?: string;
    LMIvalue?: number;
    LMIResult?: number;
    proportyBusy?: string;
    sinisterResponse?: string;
    emailClient?: string;
    borderoTotally?: number;
}

export const SinisterHeader: React.FC<ISinisterHeaderProps> = ({
    status = '',
    onclickbuttonStatus,
    onclickStateProperty,
    showButtonStateProperty,
    showButtonStatus = false,
    textButtonStatus = 'Status',
    name = '',
    openResponse = '',
    createAt = '',
    openSinister = '',
    closeSinister = '',
    certificate = '',
    LMIResult = '',
    LMIvalue = '',
    proportyBusy = '',
    sinisterResponse = '',
    emailClient = '',
    borderoTotally = ''
}) => {

    return (
        <Box display={'flex'} flexDirection={'column'} component={Paper} width={"95%"} paddingX={3} paddingY={1} ml={4} borderRadius={1} mt={3}>
            <Grid container item direction="row" spacing={1} sx={{ padding: '1px', marginTop: '8px' }}>
                <Grid item md={7} sm={12}>
                    <Typography variant="h6" color={"primary"}>
                        Dados do Sinistro
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={5} display={"flex"} gap={2} displayPrint={"flex"} justifyContent={'flex-end'}>
                    <Typography variant="h4" color={globalColorText(status ? status : 'open')}>
                        {status ? globalTranslate(status, "sinister") : ''}
                    </Typography>
                    {showButtonStatus && (
                        <Tooltip title="Alterar Status" placement="top-start">
                            <Button
                                color="primary"
                                disableElevation
                                variant="contained"
                                onClick={onclickbuttonStatus}
                                startIcon={<Icon>cached_Outlined_Icon</Icon>}>
                                {textButtonStatus}
                            </Button>
                        </Tooltip>)}
                </Grid>
            </Grid>

            <Grid container item direction="row" spacing={1} sx={{ padding: '1px', marginTop: '8px' }}>
                <Grid container item direction="row" spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant="caption" color={"text.secondary"}>
                            Inquilino
                        </Typography>
                        <Typography variant="subtitle2">
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography variant="caption" >
                            Responsável abertura sinistro
                        </Typography>
                        <Typography variant="subtitle2">
                            {openResponse}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Typography variant="subtitle2">
                            {createAt ? moment(createAt).format("L") : ''}
                        </Typography>
                        <Typography variant="caption" >
                            {"Criada "}
                            {createAt ? moment(createAt).startOf("day").fromNow() : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item direction="row" spacing={1} sx={{ backgroundColor: "#f0f1f1", padding: '1px', marginTop: '8px' }}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption" >
                        Abertura Sinistro
                    </Typography>
                    <Typography variant="subtitle2">
                        {openSinister ? moment(openSinister).format("L") : ''}
                    </Typography>
                </Grid>
                <Grid
                    item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption" color={"text.secondary"}>
                        Encerramento Sinistro
                    </Typography>
                    <Typography variant="subtitle2">
                        {closeSinister ? moment(closeSinister).format("L") : ''}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption" color={"text.secondary"}>
                        Certificado
                    </Typography>
                    <Typography variant="subtitle2">
                        {certificate ? certificate : ''}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <Typography variant="subtitle2">
                        Lmi : {LMIvalue ? parseFloat(`${LMIvalue}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                    </Typography>
                    <Typography variant="subtitle2" color={"red"}>
                        Saldo: {LMIResult ? parseFloat(`${LMIResult}`).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item direction="row" spacing={1} sx={{ padding: '1px', marginTop: '8px' }}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Box display={"flex"} flexDirection={'column'} alignItems={'start'}>
                        <Typography variant="caption" color={"text.secondary"}>
                            Situação do Imóvel
                        </Typography>
                        <Typography display={"flex"} sx={{ alignItems: "center" }}>
                            <Typography variant="subtitle2" sx={{ borderRadius: 2, background: proportyBusy ? globalBackground(proportyBusy) : '', color: globalColorText(proportyBusy) }} paddingX={'25px'} paddingY={'2px'} >
                                {proportyBusy ? globalTranslate(proportyBusy) : ''}
                            </Typography>
                            {showButtonStateProperty && (< Tooltip title="Alterar Situação do imóvel" placement="top-start">
                                <Button
                                    color="primary"
                                    disableElevation
                                    variant="text"
                                    onClick={onclickStateProperty}
                                    startIcon={<Icon>cached_Outlined_Icon</Icon>}>
                                </Button>
                            </Tooltip>
                            )}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption" color={"text.secondary"}>
                        Responsável pelo Sinistro
                    </Typography>
                    <Typography variant="subtitle2">
                        {sinisterResponse}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption" color={"text.secondary"}>
                        Email do Parceiro
                    </Typography>
                    <Typography variant="subtitle2">
                        {emailClient}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography variant="caption" color={"text.secondary"}>
                        Total de Borderôs
                    </Typography>
                    <Typography variant="subtitle2">
                        {borderoTotally}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}