import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { VTextField } from '../../../../shared/forms';
import { AutoCompleteClientType } from '../../../../shared/components/autoCompleteClientType/AutoCompleteClientType';
import { VMaskTextField } from '../../../../shared/forms/VMaskTextField';
import { Email } from '@mui/icons-material';

interface IGenerateClientProps {
  isLoading: boolean;
  handleClientType: (e: any) => void;
  setNome: (e: any) => void;
  ClientType: string;
}

export const GenerateClient: React.FC<IGenerateClientProps> = ({
  isLoading,
  handleClientType,
  setNome,
  ClientType,
}) => {
  return (
    <Box
      margin={1}
      display="flex"
      flexDirection="column"
      component={Paper}
      variant="outlined"
    >
      <Grid container direction="column" padding={2} spacing={2}>
        <Grid container item direction="row" spacing={2}>
          <Grid item xs={8}>

            <Typography variant="h6" color={'primary'}>
              Informações Gerais
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}> 
            <VTextField
              fullWidth
              label="Origem Cadastro"
              name="origem"
              variant='standard'
              disabled={true}
            />
          </Grid> 
        </Grid>
        <Grid container item direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <VTextField
              fullWidth
              label="Status"
              name="status"
              disabled={isLoading}
              select
            >
              <MenuItem value={'active'}>Ativo</MenuItem>
              <MenuItem value={'inactive'}>Inativo</MenuItem>
              <MenuItem value={'pending'}>Pendente</MenuItem>
              <MenuItem value={'blocked'}>Bloqueado</MenuItem>
            </VTextField>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <VTextField
              fullWidth
              label="Tipo de Cliente"
              name="type_person"
              disabled={isLoading}
              select
              onChange={handleClientType}
            >
              <MenuItem value={'legal'}>Juridica</MenuItem>
              <MenuItem value={'physical'}>Fisica</MenuItem>
            </VTextField>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <AutoCompleteClientType
              isExternalLoading={isLoading}
              labelSelect={'Perfil'}
              namecampo={'typeId'}
            />
          </Grid>
        </Grid>

        <Grid container item direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <VMaskTextField
              fullWidth
              label="CPF/CNPJ"
              name="document"
              disabled={isLoading}
              mask={ClientType === 'legal' ? 'cnpj' : 'cpf'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <VTextField
              fullWidth
              label="Nome"
              name="name"
              disabled={isLoading}
              onChange={(e) => setNome(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <VTextField
              fullWidth
              label="Fantasia"
              name="trade_name"
              disabled={isLoading}
              // size="normal"
            />
          </Grid>
        </Grid>

        <Grid container item direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <VTextField
              fullWidth
              label="Nome do Responsável"
              name="responsible_name"
              disabled={isLoading}
              // size="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <VMaskTextField
              fullWidth
              label="CPF do Responsável"
              name="responsible_document"
              disabled={isLoading}
              mask={'cpf'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <VTextField
              fullWidth
              label="E-mail Responsável"
              name="email"
              disabled={isLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <Email />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
         
        </Grid>

        <Grid container item direction="row" spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <VTextField
              fullWidth
              label="CRECI"
              name="creci"
              disabled={isLoading}
              // size="normal"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <VMaskTextField
              fullWidth
              label="Celular"
              name="phone"
              disabled={isLoading}
              mask="phone"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <VMaskTextField
              fullWidth
              label="Telefone"
              name="mobilePhone"
              disabled={isLoading}
              mask="cell"
            />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <VTextField
              fullWidth
              label="Origem Cadastro"
              name="origem"
              disabled={true}
            />
          </Grid> */}
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <VTextField
              fullWidth
              name="observationPartner"
              disabled={isLoading}
              sx={{ resize: 'none' }}
              maxRows={3}
              label="Informações da parceria"
              multiline={true}
              size="medium"
              rows={3}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
