import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { useProfile } from "../../../context/ProfileContext";
import { globalColorLine } from "../../../shared/utils";

interface IDetailUserProps {
    name?: string;
    updateAt?: string;
    email?: string;
    dataLoading: boolean;

}

export const DetailUser: React.FC<IDetailUserProps> = ({
    name = '',
    updateAt = '',
    email = '',
    dataLoading
}) => {

    const { isSuperAdmin } = useProfile();
    const ColorTytle = '#1c1b1b';

    return (

        <Grid container component={Paper} sx={{ width: '100%', maxWidth: '1300px', padding: 2 }}>
            <Grid container item direction="row" spacing={1}>
                <Grid item xs={isSuperAdmin ? 7 : 8} >
                    <Typography variant="h6" color={ColorTytle}>
                        Dados do Usuário
                    </Typography>
                </Grid>
            </Grid >
            {!dataLoading && (
                <Grid container item direction="row" sx={{ padding: .71, background: globalColorLine(1) }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Nome
                        </Typography>
                        <Typography variant="subtitle2" >
                            {name}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="caption" color={'text.secondary'}>
                            Email
                        </Typography>
                        <Typography variant="subtitle2" >
                            {email}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                        <Typography variant="caption" color={'text.secondary'}>
                            Última Atualização
                        </Typography>
                        <Typography variant="subtitle2" >
                            {updateAt}
                        </Typography>
                    </Grid>
                </Grid >
            )}
            {dataLoading && (
                <Skeleton height={"50px"} width={"70rem"} />
            )}
        </Grid >
    )
}