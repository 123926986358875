import { Autocomplete, Box, Button, CircularProgress, FormControl, Grid, Icon, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { ListSearchParams } from "../../../pages/analysis/types/list-search-params.type";
import { useEffect, useMemo, useState } from "react";
import { useProfile } from "../../../context/ProfileContext";
import { ToastContent } from '..';
import { useDebounce, useToast } from '../../hooks';
import { returnMessageError } from '../../utils/returnMessageError';
import { Search } from "@mui/icons-material";
import { contractService, IListagemContract, TContractAllPromise } from "../../services/api/contract/contractService";
import { formatDateBR } from "../../utils/format-date";
import { isEmpty } from "lodash";

export type TAutoCompleteOption = {
    id: string;
    label: string;
}

interface IToolClientContractProps {

    showButtonBack?: boolean;
    showButtonBackLoading?: boolean;
    clickBack?: () => void;

    onClick?: () => void;

    showInputContractFilter?: boolean;
    onChangeContractFilter?: (text: string) => void;

    showStatus?: boolean;
    onChangeStatus?: (event: SelectChangeEvent) => void;

    showStatusClient?: boolean;
    onChangeStatusClient?: (event: SelectChangeEvent) => void;

    filterInitialParams?: ListSearchParams;

    showInputBusca?: boolean;
    loading?: boolean | undefined;

}


export const ToolbarClientContract: React.FC<IToolClientContractProps> = ({
    showButtonBack = true,
    showButtonBackLoading = false,

    showInputContractFilter = true,
    onChangeContractFilter,

    showStatus = true,
    onChangeStatus,

    showStatusClient = true,
    onChangeStatusClient,

    showInputBusca = true,
    loading = false,

    clickBack,
    onClick,

}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
    const [selectedId, setSelectedId] = useState<string | undefined>();
    const [busca, setBusca] = useState('');
    const { userClientId, isSuperAdmin, permissionLevel } = useProfile();
    const toastHandler = useToast();
    const theme = useTheme();
    const { debounce } = useDebounce();

    const formatLabelValue = (contract: IListagemContract | null, value?: string): string => {

        if (value?.trim()?.length) return value.split("(")[0].trim();

        let label = "";

        if (isEmpty(contract)) return label;

        const { createdAt, title } = contract as IListagemContract;

        if (title?.trim()?.length) {
            const dateCreate = formatDateBR(createdAt, false);
            label = `${title.trim()} (${dateCreate})`;

        }

        return label;

    }


    const getContracts = () => {

        debounce(() => {

            setIsLoading(true);
            contractService.getAll(1, formatLabelValue(null, busca))
                .then((result: TContractAllPromise) => {
                    setIsLoading(false);

                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    } else {
                        const options = result?.results
                            ?.map((contract: IListagemContract) => ({ id: contract.id, label: formatLabelValue(contract) }));

                        if (options?.length == 1) {
                            setSelectedId(options[0].id);
                        }

                        setOpcoes(options);
                    }
                }).catch((err: any) => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        durationMs: 4000,
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                })
        });
    }


    useEffect(() => {
        getContracts();
    }, [busca]);


    const autoCompleteSelectedOption = useMemo(() => {
        if (!selectedId) return null;
        const selectedOption = opcoes.find(opcao => opcao.id === selectedId);
        if (!selectedOption) return null;
        return selectedOption;
    }, [selectedId, opcoes]);

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(5)}
            component={Paper}
        >

            <Grid container display="flex" flexDirection="row">
                {(showButtonBack && !showButtonBackLoading) && (
                    <Grid item xs={12} md={1.5} paddingRight={1}>
                        <Button
                            color="primary"
                            sx={{
                                height: "40px", borderColor: "rgb(192, 192, 192);", color: "rgba(0, 0, 0, 0.6);",
                                "&.MuiButtonBase-root.MuiButton-root:hover": {
                                    borderColor: "rgba(0, 0, 0, 0.87);",
                                    bgcolor: "white"
                                }
                            }}
                            disableElevation
                            variant="outlined"
                            onClick={clickBack}
                            startIcon={<Icon>keyboard_double_arrow_left_icon</Icon>}
                        >
                            <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                Voltar
                            </Typography>
                        </Button>
                    </Grid>
                )}

                {showInputContractFilter && (
                    <Grid item xs={12} md={5.5} paddingRight={1}>
                        <FormControl fullWidth>
                            <Autocomplete
                                fullWidth
                                openText="Abrir"
                                closeText="Fechar"
                                noOptionsText="Nenhuma opção"
                                loadingText="Loading ..."
                                disablePortal
                                value={autoCompleteSelectedOption}
                                options={opcoes}
                                disabled={loading}
                                loading={isLoading}
                                size={'small'}
                                onInputChange={(_, newValue) => setBusca(newValue)}
                                onChange={(_, newValue) => {
                                    onChangeContractFilter?.(newValue?.label ? formatLabelValue(null, newValue.label) : '' as string);
                                    setSelectedId(newValue?.id ? newValue.id : '' as string);
                                }}
                                renderInput={(params) => <TextField
                                    {...params} label="Contrato" name="contrato"
                                />}
                            />
                        </FormControl>
                    </Grid>
                )}



                {showStatus && (
                    <Grid item xs={12} md={2}>
                        <FormControl fullWidth>
                            <InputLabel id="id_status">Status</InputLabel>
                            <Select
                                labelId="id_status"
                                id="id_status"
                                defaultValue=""
                                size="small"
                                label={"Status"}
                                name="status"
                                disabled={loading}
                                onChange={onChangeStatus}
                            >
                                <MenuItem key={0} value={''}></MenuItem>
                                <MenuItem key={1} value={'pending_signature'}>Pendente</MenuItem>
                                <MenuItem key={2} value={'signed_signature'}>Assinado</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {showStatusClient && (
                    <Grid item xs={12} md={2}>
                        <FormControl fullWidth>
                            <InputLabel id="id_status_client">Status Cliente</InputLabel>
                            <Select
                                labelId="id_status_client"
                                id="id_status_client"
                                defaultValue=""
                                size="small"
                                label={"Status Cliente"}
                                name="statusClient"
                                disabled={loading}
                                onChange={onChangeStatusClient}
                            >
                                <MenuItem key={0} value={''}></MenuItem>
                                <MenuItem key={1} value={'active'}>Ativo</MenuItem>
                                <MenuItem key={2} value={'inactive'}>Inativo</MenuItem>
                                <MenuItem key={3} value={'pending'}>Pendente</MenuItem>
                                <MenuItem key={4} value={'blocked'}>Bloqueado</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}


                {showInputBusca && (
                    <Grid item xs={12} md={1} sx={{ marginLeft: "30px" }}>
                        <Box display="flex" justifyContent="end">
                            {loading ? <CircularProgress /> :
                                <Tooltip title="Clique para Pesquisar">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={onClick}
                                        disableRipple
                                        disableFocusRipple
                                        disabled={loading}
                                        edge="end"
                                        sx={{
                                            color: 'white', backgroundColor: 'red', borderRadius: "4px",
                                            width: "120%"
                                        }}
                                    >
                                        <Search />
                                        <Typography marginLeft={1}>
                                            Filtrar
                                        </Typography>
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    </Grid>
                )}

            </Grid>

        </Box>
    );
};