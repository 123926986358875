export enum AnalysisContractTypeEnum {
  InitialInspection = 'initial_inspection',
  LeaseContract = 'lease_contract',
  GaranttiTenantContract = 'garantti_tenant_contract_document_path',
  KeyDeliveryTerm = 'key_delivery_term',
}

export const formatAnalysisContractTypeEnum = (type: AnalysisContractTypeEnum) => {
  switch (type) {
    case AnalysisContractTypeEnum.InitialInspection:
      return 'Vistoria Inicial';
    case AnalysisContractTypeEnum.LeaseContract:
      return 'Contrato de Locação';
    case AnalysisContractTypeEnum.GaranttiTenantContract:
      return 'Contrato Garantti Locatário';
    case AnalysisContractTypeEnum.KeyDeliveryTerm:
        return 'Termo de Entrega de Chaves e quitação de débitos';
    default:
      return 'Outro';
  }
}
