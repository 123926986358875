import { string } from 'yup/lib/locale';
import { Environment } from '../../../environment';
import { Api } from "../axios-config";

export interface IListagemPlan {
    id: string,
    description: string,
    lmi: string,
    residentialFee: string,
    commercialFee: string,
    bonusAmount: string;
    status: string,
    personalized:string,
    coverage?:string,
    setupTransfer?:string,
    proposalMin?:string;
    product?: {
        id?: string,
        description?: string,
        text?: string,
    }
}

export interface IPlanUpdate{
    id: string;
    status:string;
}


type TPlanTotalCount = {
    data: IListagemPlan[];
    totalCount: number;
}

export interface IClientPlan{
    id: string;
    status: string;
    clientId: string;
    planId: string;
    productId: string;
    lmi: string;
    residentialFee: string;
    commercialFee: string;
    bonusAmount: string;
    flagAnalysis: string;
    flagRenovation: string;
    
    plan: {
    id: string;
    description: string;
    lmi: string;
    residentialFee: string;
    commercialFee: string;
    bonusAmount: string;
    status: string;
    }

}


const getAll = async (page = 1, filter = ''): Promise<TPlanTotalCount | Error> => {
    try {
        const urlRelativa = `/Plan?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&nome_like=${filter}`;
        const { data, headers } = await Api().get(urlRelativa);
        if (data.results) {
            return {
                data: data.results,
                totalCount: data.total,
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};


const getAllNotExists = async ( client = '',filter = '',): Promise<TPlanTotalCount | Error> => {
    try {

        const urlRelativa = `/client-plan/plan-not-exists/${client}?nome_like=${filter}`;
        const { data, headers } = await Api().get(urlRelativa);

        if (data.results) {
            return {
                data: data.results,
                totalCount: data.total,
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const getById = async (id: number): Promise<IListagemPlan | Error> => {
    try {
        const { data } = await Api().get(`/Plan/${id}`);

        if (data) {
            return data;
        }
        return new Error('Erro ao consultar o registro.');

    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IListagemPlan, 'id'>): Promise<string | Error> => {
    try {
        const { data } = await Api().post<IListagemPlan>('/Plan', dados);

        if (data) {
            return data.id;
        }
        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const updateById = async (id: string, dados:IPlanUpdate): Promise<void | Error> => {
    try {
        await Api().patch(`/client-plan/${id}`, dados);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string): Promise<void | Error> => {
    try {
        await Api().delete(`/client-plan/${id}`);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};

const planByClient = async (clientId: string,flagAnalysisRenovation?:string): Promise<IClientPlan[] | Error> => {
    try {
        const { data } = await Api().get(`/client-plan/client/${clientId}/${flagAnalysisRenovation}`);
        console.log('plan client.... services...', data)
        if (data) {
            return data;
            //     data: data.results,
            //     totalCount: data.total,
            // };
        }
        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao localizar planos do cliente.');
    }
}

export const PlanServices = {
    getAll,
    getById,
    create,
    updateById,
    deleteById,
    getAllNotExists,
    planByClient
};