import { PeopleService } from "../services/api/people/PeopleService";
import { useToast } from '../hooks';
import { ToastContent } from '../../shared/components/';
import { useVForm } from "../../shared/forms";
import { useState } from "react";
import { Environment } from "../environment";


export const globalSearchCPF = (value: string) => {
    const toastHandler = useToast();
    const { formRef } = useVForm();
    const [isCPF, setIsCPF] = useState(false);
    const documento = value.replace(/\D/g, "").trim();
    const ur = `${Environment.URL_API_CPF}`  ;//'https://api.cpfcnpj.com.br/548312cf26cd55f10c94de3c123f5c83/2/'
    
    setIsCPF(false);
    if (value?.trim()?.length && value?.trim()?.length >= 11) {
        setIsCPF(true);
    }

    PeopleService.getPersonDataByDocument(documento)
        .then(data => {
            formRef.current?.setFieldValue("name", data.naturalPersonData?.name);
            formRef.current?.setFieldValue("birthDate", data.naturalPersonData?.birthDate);
            formRef.current?.setFieldValue("motherName", data.naturalPersonData?.motherName);
        })
        .catch(err => {
            const messageTitle = 'Erro na pesquisa'
            const messageContent = 'CPF não localizado na base, verifique se esta correto.'

            toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: <ToastContent title={messageTitle} content={messageContent} />
            })
        })
        ;
}