
import * as React from 'react';
import Modal from '@mui/material/Modal';
import { VTextField, VForm, useVForm, IVFormErros } from "../../forms";
import { useEffect, useState } from "react";
import {
    Box, Grid, Icon, IconButton, InputAdornment, LinearProgress, MenuItem, Paper, Table, TableContainer,
    Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme
} from "@mui/material";

import { useNavigate, useParams } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import CheckIcon from '@mui/icons-material/Check';
import DraftsIcon from '@mui/icons-material/Drafts';
import { textAlign } from '@mui/system';




const typeMessage: ITypeMessage[] = [
    {
        type: 'error',
        title: 'Erro',
        icon: 'cancel_Outlined_Icon',
        color: '#e87b9e'
    },
    {
        type: 'alert',
        title: 'Atenção',
        icon: 'info_Outlined_Icon',
        color: '#ffc107'
    },
    {
        type: 'success',
        title: 'Sucesso',
        icon: 'check_circle_Outline_Icon',
        color: '#39c463'
    },
    {
        type: 'info',
        title: 'Informação',
        icon: 'error_outline_Outlined_Icon',
        color: '#d32f2f'
    },
];

interface ITypeMessage {
    title: string;
    icon: string;
    color: string;
    type: string;
}

interface IApprovedProps {
    textButtonConfirm?: string;
    showButtonCancel?: boolean;
    isOpenModal: boolean;
    messageType: string;
    // IMessage: React.ReactNode[];
    onclickConfirm?: () => void;
    isCloseModal?: () => void;
}


interface IFormData {
    // id      :string,
    document: string;
    status: string;
    email: string;
    creci: string;
    name: string;
    trade_name: string;
}


export const ModalApproved: React.FC<IApprovedProps> = ({ textButtonConfirm, 
    showButtonCancel, isOpenModal, messageType, isCloseModal, onclickConfirm }) => {

    const setTypeMessage = () => {
        switch (messageType) {
            case 'error':
                return typeMessage[0];
            case 'alert':
                return typeMessage[1];
            case 'success':
                return typeMessage[2];
            case 'info':
                return typeMessage[3];
            default: return typeMessage[0];
        }
    }

    const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
    const messageStyle: ITypeMessage = setTypeMessage();
    const { id = 'novo' } = useParams<'id'>();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [value, setValue] = useState(0);

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));



    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        maxHeight: '90%',
        bgcolor: 'background.paper',
        border: '1px solid #605e5e',
        boxShadow: 24,
        overflow: 'auto',
        p: 2,
        borderRadius: '6px',
        display: "flex",
        flexDirection: smDown ? 'column' : 'row'
    };


    const handleSave = (dados: IFormData) => {
        // setValue(dados[id]);
    }


    return (
        <div>
            <Modal
                open={isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/* <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                    {IMessage}
                </DialogContent> */}
                <List sx={style} >
                    <Grid container sm={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        gap={2}
                    >
                        <Grid container sm={12}
                            sx={{ border: "none", display: 'flex', justifyContent: 'center' }}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            padding={2}
                        >
                            <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Icon style={{ color: `${messageStyle.color}`, fontSize: "3rem", fontWeight: 100 }}>
                                    {messageStyle.icon}
                                </Icon>
                                <Typography
                                    variant='caption'
                                    color={`${messageStyle.color}`}
                                    marginBottom="1rem"
                                >
                                    {messageStyle.title}
                                </Typography>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    color={`${messageStyle.color}`}
                                >
                                    Análise Aprovada !!!
                                </Typography>
                                <Typography
                                    id="modal-modal-title"
                                    variant="caption"
                                >
                                    Escolha seu plano
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <Grid container sm={12}
                            sx={{ border: "none", alignContent: "center", textAlign: "center", justifyContent: "center" }}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            component={Paper}
                            padding={2}
                        >

                            <Grid container item sm={12} md={2}
                                sx={{ border: "none", display: 'flex', justifyContent: 'left' }}
                                display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            // padding={2}
                            >
                                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <Typography variant="subtitle1" sx={{ color: "text.secondary", textAlign: "left" }}>
                                        Planos
                                    </Typography>
                                    <Typography variant="h5" sx={{ color: 'white', fontWeight: 700, fontFamily: "arial" }}>
                                        .
                                        <Typography variant="subtitle2" sx={{ color: 'white' }}>
                                            .
                                        </Typography>
                                    </Typography>

                                    <Button
                                        color="error"
                                        disableElevation
                                        variant={"outlined"}
                                        sx={{ mt: 2, mb: 2, border: "none", color: 'white' }}
                                    >
                                        .
                                    </Button>
                                    <Divider />
                                    <nav aria-label="secondary mailbox folders">
                                        <List>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="LMI" sx={{ textAlign: "left", color: "text.secondary" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding >
                                                <ListItemButton>
                                                    <ListItemText primary="Valor Prêmio" sx={{ textAlign: "left", color: "text.secondary" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="Valor LMI" sx={{ textAlign: "left", color: "text.secondary" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="Danos ao imóvel" sx={{ textAlign: "left", color: "text.secondary" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="Condominio" sx={{ textAlign: "left", color: "text.secondary" }} />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </nav>
                                </Box>
                            </Grid>
                            <Grid container item sm={12} md={3}
                                sx={{ border: "none", display: 'flex', justifyContent: 'center' }}
                                display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            // padding={2}
                            >
                                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                                        Silver
                                    </Typography>
                                    <Typography variant="h5" sx={{ color: "#7924c7 ", fontWeight: 700, fontFamily: "arial" }}>
                                        R$ 67,00
                                        <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                            /mes
                                        </Typography>
                                    </Typography>

                                    <Button
                                        color="error"
                                        disableElevation
                                        variant={"contained"}
                                        startIcon={< Icon > checkIcon</Icon >}
                                        onClick={() => navigate('/analises/contratacao')}
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        CONTRATAR
                                    </Button>
                                    <Divider />
                                    <nav aria-label="secondary mailbox folders">
                                        <List>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="48x" sx={{ textAlign: "center" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding sx={{ backgroundColor: "#f0f0f0" }}>
                                                <ListItemButton>
                                                    <ListItemText primary="RS 36.000,00" sx={{ textAlign: "center" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="RS 20.000,00" sx={{ textAlign: "center" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding sx={{ backgroundColor: "#f0f0f0" }}>
                                                <ListItemButton sx={{ justifyContent: "center", textAlign: "center", color: "#39c463" }}>
                                                    <CheckIcon />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton sx={{ justifyContent: "center", textAlign: "center", color: "#39c463" }}>
                                                    <CheckIcon />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </nav>
                                </Box>
                            </Grid>
                            <Grid container item sm={12} md={3}
                                sx={{ border: "none", display: 'flex', justifyContent: 'center' }}
                                display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            // padding={2}
                            >
                                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                                        Gold
                                    </Typography>
                                    <Typography variant="h5" sx={{ color: "#E9B801", fontWeight: 700, fontFamily: "arial" }}>
                                        R$ 150,00
                                        <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                            /mes
                                        </Typography>
                                    </Typography>

                                    <Button
                                        color="primary"
                                        disableElevation
                                        variant={"text"}
                                        startIcon={< Icon > checkIcon</Icon >}
                                        onClick={() => navigate('/analises/contratacao')}
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        CONTRATAR
                                    </Button>
                                    <Divider />
                                    <nav aria-label="secondary mailbox folders">
                                        <List>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="48x" sx={{ textAlign: "center" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding sx={{ backgroundColor: "#f0f0f0" }}>
                                                <ListItemButton>
                                                    <ListItemText primary="RS 36.000,00" sx={{ textAlign: "center" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="RS 20.000,00" sx={{ textAlign: "center" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding sx={{ backgroundColor: "#f0f0f0" }}>
                                                <ListItemButton sx={{ justifyContent: "center", textAlign: "center", color: "#39c463" }}>
                                                    <CheckIcon />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton sx={{ justifyContent: "center", textAlign: "center", color: "#39c463" }}>
                                                    <CheckIcon />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </nav>
                                </Box>
                            </Grid>
                            <Grid container item sm={12} md={3}
                                sx={{ border: "none", display: 'flex', justifyContent: 'center' }}
                                display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            // padding={2}
                            >
                                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                                        Personal
                                    </Typography>
                                    <Typography variant="h5" sx={{ color: "#7924c7 ", fontWeight: 700, fontFamily: "arial" }}>
                                        R$ 12,00
                                        <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                                            /mes
                                        </Typography>
                                    </Typography>

                                    <Button
                                        color="error"
                                        disableElevation
                                        variant={"contained"}
                                        startIcon={< Icon > checkIcon</Icon >}
                                        onClick={() => navigate('/analises/contratacao')}
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        CONTRATAR 
                                    </Button>
                                    <Divider />
                                    <nav aria-label="secondary mailbox folders">
                                        <List>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="48x" sx={{ textAlign: "center" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding sx={{ backgroundColor: "#f0f0f0" }}>
                                                <ListItemButton>
                                                    <ListItemText primary="RS 36.000,00" sx={{ textAlign: "center" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary="RS 20.000,00" sx={{ textAlign: "center" }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding sx={{ backgroundColor: "#f0f0f0" }}>
                                                <ListItemButton sx={{ justifyContent: "center", textAlign: "center", color: "#39c463" }}>
                                                    <CheckIcon />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton sx={{ justifyContent: "center", textAlign: "center", color: "#39c463" }}>
                                                    <CheckIcon />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </nav>
                                </Box>
                            </Grid>

                        </Grid>
                        <Grid container sm={12}
                            sx={{ border: "none", display: 'flex', justifyContent: 'center' }}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            padding={2}
                        >
                            <DialogActions>
                                <Button
                                    color="error"
                                    disableElevation
                                    variant={"outlined"}
                                    startIcon={< Icon > checkIcon</Icon >}
                                    onClick={onclickConfirm}
                                >
                                    Contratar Depois
                                </Button>
                                {showButtonCancel && (
                                    <Button
                                        onClick={isCloseModal}
                                        color="error"
                                        disableElevation
                                        variant={"outlined"}
                                        startIcon={< Icon > closeIcon</Icon >}
                                    >
                                        Cancelar
                                    </Button>
                                )}
                            </DialogActions>
                        </Grid>

                    </Grid>


                </List>
            </Modal>
        </div >
    )
}
