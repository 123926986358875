import { Typography, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useReset } from '../../../../context';
import { BasicModal } from '../../VModal/VModal';

const RecoverPasswordForm = () => {
    const [passwordError, setPasswordError] = useState('');
    // const [password, setPassword] = useState('');
    // const [confirmPassword, setConfirmPassword] = useState('');
    // const [token, setToken] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const {
        password, 
        confirmPassword, 
        token, 
        setPassword, 
        setConfirmPassword,
        setToken
    } = useReset();
    return (
    <>
        <Typography variant='h6' align='center'>Recuperação de Senha</Typography>
        <Typography variant='caption' align='center'>Digite o código enviado no seu email</Typography>
        <TextField
            fullWidth
            label='Código de Verificação'
            type='text'
            value={token}
            disabled={isLoading}
            error={!!passwordError}
            helperText={passwordError}
            onKeyDown={() => setPasswordError('')}
            onChange={e => setToken(e.target.value)}
            size={'small'}
        />
        <TextField
            fullWidth
            label='Senha'
            type='password'
            value={password}
            disabled={isLoading}
            error={!!passwordError}
            helperText={passwordError}
            onKeyDown={() => setPasswordError('')}
            onChange={e => setPassword(e.target.value)}
            size={'small'}
        />
        <TextField
            fullWidth
            label='Confirmar Senha'
            type='password'
            value={confirmPassword}
            disabled={isLoading}
            error={!!passwordError}
            helperText={passwordError}
            onKeyDown={() => setPasswordError('')}
            onChange={e => setConfirmPassword(e.target.value)}
            size={'small'}
        />
    </>
  )
}

export default RecoverPasswordForm