import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { useProfile } from "../../../context/ProfileContext";
import moment from "moment";
import { globalColorLine, globalTranslateMaritalStatus, globalTranslateProfession, maskPhone } from "../../../shared/utils";

interface IDetailLessorProps {
    name?: string;
    document?: string;
    birthDate?: string;
    mother?: string;
    profession?: string;
    maritalStatus?: string;
    mobilePhone?: string;
    phone?: string;
    email?: string;
}

export const DetailLessor: React.FC<IDetailLessorProps> = ({
    name = '',
    document = '',
    birthDate = '',
    mother = '',
    profession = '',
    maritalStatus = '',
    mobilePhone = '',
    phone = '',
    email = ''


}) => {

    const { isSuperAdmin } = useProfile();
    const ColorTytle = '#1c1b1b';

    return (
        <Grid container component={Paper} sx={{ width: '100%', maxWidth: '1300px', padding: 2 }}>
            <Grid container item direction="row" spacing={1}>
                <Grid item xs={isSuperAdmin ? 7 : 8} >
                    <Typography variant="h6" color={ColorTytle}>
                        Dados do Locador
                    </Typography>
                </Grid>
            </Grid >

            <Grid container item direction="row" sx={{ padding: 1, background: globalColorLine(1) }}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="caption" color={'text.secondary'}>
                        Documento
                    </Typography>
                    <Typography variant="subtitle2" >
                        {document.length > 11 ?
                            `${document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                            :
                            `${document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                        }
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="caption" color={'text.secondary'}>
                        Nome
                    </Typography>
                    <Typography variant="subtitle2" >
                        {name}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="caption" color={'text.secondary'}>
                        Nascimento
                    </Typography>
                    <Typography variant="subtitle2" >
                        {birthDate != '1899-11-30' ? moment(birthDate).format("L") : ''}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item direction="row" sx={{ padding: 1, background: globalColorLine(2) }}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                    <Typography variant="caption" color={'text.secondary'}>
                        Nome da Mãe
                    </Typography>
                    <Typography variant="subtitle2" >
                        {mother}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item direction="row" sx={{ padding: 1, background: globalColorLine(3) }}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="caption" color={'text.secondary'}>
                        Celular
                    </Typography>
                    <Typography variant="subtitle2" >
                        {maskPhone(`${phone}`)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="caption" color={'text.secondary'}>
                        Telefone
                    </Typography>
                    <Typography variant="subtitle2" >
                        {maskPhone(`${mobilePhone}`)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="caption" color={'text.secondary'}>
                        Email
                    </Typography>
                    <Typography variant="subtitle2" >
                        {email}
                    </Typography>
                </Grid>
            </Grid>
        </Grid >

    )
}