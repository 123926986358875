import { Environment } from "../../../environment";
import { IMessage } from "../../../types/message";
import { Api } from "../axios-config";
import { IDetalheCliente } from "../client/clientService";
import { IDetalheContract } from "../contract/contractService";

export interface IListagemClientContract {
  id: string;
  createdAt: string;
  updatedAt?: string;
  signed_dateAt?: string;
  uploadedAt?: string;
  signersCopies?: string;
  clientIdAdmin?: string;
  clientId?: string;
  contractId?: string;
  statusSignature: string;
  clientId_admin?: IDetalheCliente;
  client_id?: IDetalheCliente;
  contract?: IDetalheContract;
}

export interface IDetalheClientContract {
  id: string;
  createdAt: string;
  updatedAt?: string;
  signed_dateAt?: string;
  uploadedAt?: string;
  signersCopies?: string;
  clientIdAdmin?: string;
  clientId?: string;
  contractId?: string;
  statusSignature: string;
  clientId_admin?: IDetalheCliente;
  client_id?: IDetalheCliente;
  contract?: IDetalheContract;
}

type TClientContractTotalCount = {
  results: IListagemClientContract[];
  total: number;
};

export type TClientContractPromise = string | Error | undefined;

export type TClientContractAllPromise = TClientContractTotalCount | Error;

const getAll = async (
  page: number = 1,
  title: string = "",
  status: string = "",
  statusClient: string = ""
): Promise<TClientContractAllPromise> => {
  try {
    const urlRelativa = `/client-contract?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&title=${title}&status=${status}&statusClient=${statusClient}`;

    const { data } = await Api().get(urlRelativa);

    if (data.results) return data;

    return new Promise((_, reject) =>
      reject(new Error("Erro ao listar registros."))
    );
  } catch (error: any) {
    console.error(error);
    throw new Promise((_, reject) => reject(error));
  }
};

const shootQueue = async (id: string): Promise<IMessage<null> | Error> => {
  try {
    await Api().post("/client-contract/queue", { id });

    return new Promise((resolve) =>
      resolve({
        message: "Sucesso!",
        description: "Disparo para a fila realizado com sucesso!",
      })
    );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getByID = async (id: string): Promise<IDetalheClientContract | Error> => {
  try {
    const { data } = await Api().get(`/client-contract/${id}`);
    if (data) return data;

    return new Promise((_, reject) =>
      reject(new Error("Erro ao listar registro."))
    );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const updateById = async (
  id: string,
  dados: IDetalheClientContract
): Promise<void | Error> => {
  try {
    await Api().patch(`/client-contract/${id}`, dados);
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

export const clientContractService = {
  getAll,
  getByID,
  updateById,
  shootQueue,
};
