import { string } from 'yup/lib/locale';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';
import { addMonths, format } from 'date-fns';
import { Logs, UpdateProposalLogs } from '../../../interfaces';
// import { ClientPlan } from '../../../../../client_api/model/clientPlan';
// import { Client } from '../../../../../client_api/model/client';
import { IMessage } from '../../../types/message';

export interface IProposalList {
  certificateNumber?: string;
  id: string;
  clientId: string;
  analysisId: string;
  status: string;
  name: string;
  motherName?: string;
  type_person?: string;
  document: string;
  birthDate?: string;
  canceledReason?: string;
  canceledType?: string;
  mobilePhone?: string;
  createdAt?: string;
  updatedAt?: string;
  canceledAt?: string;
  canceledOpen?: Date | null;
  canceledConclude?: Date | null;
  renovateAt?: string;
  termBegin?: string;
  expiresAt?: string;
  expiredAt?: string;
  installmentsNumber?: string;
  createdFrom?: string;
  employmentRelationShip?: string;
  maritalStatus?: string;
  wifeName?: string;
  email?: string;
  isTipping?: string;
  phone?: string;
  rentalCost?: string;
  sendSerasa?: string;
  propertyType?: string;
  propertyFinally?: string;
  addressZipcode?: string;
  addressStreet?: string;
  addressResidenceType?: string;
  addressNumber?: string;
  addressComplement?: string;
  addressDistrict?: string;
  addressCity?: string;
  addressState?: string;
  addressIbge?: string;
  serasaId?: string;
  serasaResponse?: string;
  serasaAnalysisStatus?: string;
  paymentMethod?: string;
  rentMonthsCount?: string;
  residentialFee?: string;
  commercialFee?: string;
  rentAmount?: string;
  condominiumAmount?: string;
  electricEnergyAmount?: string;
  waterAmount?: string;
  gasAmount?: string;
  iptuAmount?: string;
  // analysisPlan?: string;
  assuranceMonthAmount?: string;
  lmi?: string;
  lmiAmount?: string;
  lmiBalance?: string;
  userId?: string;
  relation?: string;
  planId?: string;
  peopleId?: string;
  reasonId?: string;
  lessorName?: string;
  lessorCpf?: string;
  propertyId?: string;
  lessorTypePerson?: string;
  lessorBirthDate?: string;
  lessorEmail?: string;
  lessorPhone?: string;
  lessorMobilePhone?: string;
  contractBegin?: string;
  contractEnd?: string;
  renovatedId?: string;
  obs?: string;
	baseCalc?: string;
  sinister?: any[];
  clientPlan?: {
    id?: string;
    lmi?: Number;
    residentialFee?: string;
    commercialFee?: string;
  };
  client: {
    id?: string;
    document: string;
    type_person?: string;
    status?: string;
    email?: string;
    creci?: string;
    name: string;
    trade_name?: string;
    responsible_name?: string;
    phone?: string;
    mobilePhone?: string;
    address_zipcode?: string;
    address_street?: string;
    address_number?: string;
    address_complement_type?: string;
    address_complement?: string;
    address_district?: string;
    address_city?: string;
    address_state?: string;
    address_ibge?: string;
    wallet_properties_count?: string;
    accept_terms_service?: string;
    residential_fee?: string;
    commercial_fee?: string;
    created_from?: string;
    lmi?: string;
    invoice_due_date?: string;
    agency_number?: string;
    bank_code?: string;
    bank_manager?: string;
    agency_digit?: string;
    account_number?: string;
    account_digit?: string;
    pix_key?: string;
    pix_key_type?: string;
    created_at?: string;
    updated_at?: string;
    typeId?: string;
	
    clientType?: {
      id?: string;
      description?: string;
      status?: string;
      level?: string;
      created_at?: string;
      updated_at?: string;
    };
  };
  plan: {
    id: string;
    description: string;
    lmi: Number;
    residentialFee: string;
    commercialFee: string;
    status: string;
    created_at: string;
    updated_at: string;
    productId: string;
  };
  renovatedProposalTermBegin: Date | null;
  renovatedProposalExpiresAt: Date | null;
  renovatedProposalCreatedAt: Date | null;
  transactionSetup?: {
    id: string;
    rawAmount: number;
    status: string;
  };
}
export interface IReportProposalList {
  id: string;
  name: string;
  document: string;
  status: string;
  client: string;
  property_finally: string;
  address_zipcode: string;
  address_street: string;
  address_residence_type: string;
  address_number: string;
  address_complement: string;
  address_district: string;
  address_city: string;
  address_state: string;
  assurance_month_amount: string;
  rent_amount: string;
  term_begin: string;
  created_from: string;
}
export interface IProposalCount {
  Status: string;
  quantidade: Number;
  aluguel: Number;
  lmi_total: Number;
  lmi_saldo: Number;
  valor_parcela: Number;
}

export interface ISinistercheckList {
  id: string;
  propertyType: string;
  description: string;
  ocupation: string;
  open: string;
  busy: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  openView: string;
  busyView: string;
  sendFile: string;
  repair: string;
}

export interface IISinistercheckList {
  data: ISinistercheckList[];
  totalCount: number;
  message?: string;
}

export type TGarantiasTotalCount = {
  data: IProposalList[];
  totalCount: number;
};

export interface IInstallmentGenerate {
  id: string;
  status?: string;
  number?: string;
  paymentMethod?: string;
  type?: string;
  proposalId?: string;
  amount?: string;
  dueDate?: string;
  obs?: string;
  typeAmount?: string;
}
export interface IInstallmentProposal {
  id: string;
  status: string;
  number: number;
  paymentMethod: string;
  type: string;
  proposalId: string;
  paymentLinkId: string;
  acquirerPaymentId: string;
  invoiceId: string;
  amount: string;
  dueDate: string;
  obs?: string;
  typeAmount: string;
}
export interface ITotalStatusInstallmentProposal {
  total?: number;
  ativo?: number;
  cancelada?: number;
  pendente?: number;
  faturada?: number;
  paga?: number;
}
type TInstallmentProposalCount = {
  data: IInstallmentProposal[];
  totalCount: number;
};

export interface IProposalCountPorMes {
  mes: string;
  ano: Number;
  quantidade: Number;
  aluguel: Number;
  valorgarantia: Number;
  totalgarantia: Number;
  lmi: Number;
  lmi_amount: Number;
}

export interface ITKMedio {
  STATUS: string;
  quantidade: number;
  aluguel: number;
  total_garantia: number;
  ticket_medio: number;
  prazo_medio_duracao: number;
  soma_garantia: number;
  tkmedio_garantia: number;
  soma_lmi: number;
  soma_lmi_amount: number;
  tkmedio_soma_lmi: number;
  tkmedio_soma_lmi_amount: number;
}

export interface IDuracaoVigencia {
  duracao: number;
  quantidade: number;
  lmi_total: number;
  valor_parcela: number;
}

export interface ITempoMedio {
  quantidade: number;
  duracao_real: number;
  media_real: number;
  duracao_vigencia: number;
  media_vigencia: number;
}

export interface ICancelAntes {
  quantidade: number;
  saldo: number;
  total: number;
  aluguel: number;
  parcela: number;
}

export interface ICancelProposal {
  canceledType: string;
  canceledReason: string;
  canceledAt: string;
  //  file: File;
}

export interface ICancelProposalConclude {
  confirmCanceledAt: string;
  confirmCanceledType: string;
  confirmCanceledReason: string;
}

export interface IGarantiaTaxas {
  taxa: number;
  quantidade: number;
}

export interface IRenovateProposal {
  termBegin: string;
  contractBegin: string;
  contractEnd: string;
  rentAmount: string;
  condominiumAmount: string;
  electricEnergyAmount: string;
  waterAmount: string;
  gasAmount: string;
  iptuAmount: string;
  assuranceMonthAmount: string;
  lmi: string;
  planId: string;
  fee: string;
  residentialFee: string;
  commercialFee: string;
}

type TGarantiasPorMes = {
  data: IProposalCountPorMes[];
  totalCount: number;
};

export type TInstallmentbyClientProps = {
  Installment_id: string;
  Installment_payment_method: string;
  Installment_type: string;
  Installment_status: string;
  Installment_number: string;
  Installment_proposalId: string;
  Installment_clientId: string;
  Installment_paymentLinkId: string;
  Installment_acquirerPaymentId: string;
  Installment_invoiceId: string;
  Installment_type_amount: string;
  Installment_amount: string;
  Installment_due_date: string;
  Installment_obs: string;
  Installment_nfseId: string;
  Installment_created_at: string;
  Installment_updated_at: string;
  Installment_reason: string;
  tenant_name: string;
  tenant_document: string;
  client_document: string;
  client_name: string;
  tenant_status: string;
};

export type TCountProposal = {
  total: number;
};
export interface IproposalTotalStatus {
  status: string;
  total: number;
}

export type TProposalCountStatus = {
  data: IproposalTotalStatus[];
  totalCount: number;
};

export interface IFormInstallmentCancel {
  obs: string;
}

export interface IUpdateProposalSuperAdmin {
  id?: string;
  status: string;
}

const updateProposalSuperAdmin = async (
  dataInfo: IUpdateProposalSuperAdmin
): Promise<IMessage<null> | Error> => {
  const { id } = dataInfo;
  delete dataInfo.id;

  try {
    const { data } = await Api().patch(
      `/proposal/update/superadmin/${id}`,
      dataInfo
    );

    if (!data)
      return new Promise((_, reject) =>
        reject(
          new Error(
            'Não foi possível de atualizar a garantia do id específico: ' + id
          )
        )
      );

    return new Promise((resolve) =>
      resolve({
        message: 'Sucesso!',
        description: 'Garantia atualizado com sucesso!',
      })
    );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getProposalStatus = async (): Promise<IproposalTotalStatus[] | Error> => {
  try {
    const { data } = await Api().get(`/proposal/totalstatus`);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao consultar o registro.'
    );
  }
};

const getTotal = async (): Promise<TCountProposal | Error> => {
  try {
    const { data } = await Api().get(`/proposal/total`);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao consultar o registro.'
    );
  }
};

const getAll = async (
  page = 1,
  busca = '',
  tipo = '',
  origem = '',
  status = '',
  dataini = '',
  datafim = '',
  clientFilter = '',
  managerId = '',
  typePeriod = '',
  tombamento = '0',
  Mylimit = 0
): Promise<TGarantiasTotalCount | Error> => {
  try {
    //estava vindo vazio.. correcao paliativa.. até verificar o motivo Rogerio
    const sdataini = dataini == '' ? '2019-01-01' : dataini;
    const sdatafim = datafim == '' ? format(new Date(), 'yyyy-MM-dd') : datafim;

    const urlRelativa = `/proposal?_page=${page}&_limit=${
      Mylimit > 0 ? Mylimit : Environment.LIMITE_LINHAS_GREAT
    }&nome_like=${busca}&tipo=${tipo}&origem=${origem}&status=${status}&dataini=${sdataini}&datafim=${sdatafim}&clientId=${clientFilter}&managerId=${managerId}&typePeriod=${typePeriod}&tombamento=${tombamento}`;
    const { data, headers } = await Api().get(urlRelativa);
    if (data.results) {
      return {
        data: data.results,
        totalCount: data.total,
      };
    }
    return new Promise((_, reject) =>
      reject(new Error('Erro ao listar registros.'))
    );
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getSinisterCheck = async (
  ocupacao = ''
): Promise<IISinistercheckList | undefined> => {
  try {
    const urlRelativa = `/sinister-check?nome_like=&property_type&ocupation=${ocupacao}&openBusy=${ocupacao}&status=`;
    const { data } = await Api().get(urlRelativa);

    if (data.results) {
      return {
        data: data.results,
        totalCount: data.total,
      };
    }

    //  return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return undefined;
    //return new Error((error as { message: string }).message || 'Erro ao listar registros.');
  }
};

const getCertificate = async (id: string): Promise<boolean | Error> => {
  try {
    const { data } = await Api().get(`/proposal/certificate/pdf/${id}`, {
      responseType: 'blob',
    });
    if (data) {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    return new Promise((_, reject) => true);
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getContract = async (
  id: string
): Promise<IProposalList | Error | undefined> => {
  try {
    const { data } = await Api().get(`/proposal/contractsigned/${id}`, {
      responseType: 'blob',
    });

    if (data) {
      if (data.message) {
        return new Promise((_, reject) => reject(new Error(data.message)));
      }
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return;
    }

    return new Promise((_, reject) =>
      reject(new Error('Erro ao consultar contrato.'))
    );
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getAllInstallmentProposal = async (
  id: string
): Promise<TInstallmentProposalCount | Error> => {
  try {
    const urlRelativa = `/installment/proposal/${id}`;
    const { data, headers } = await Api().get(urlRelativa);
    // console.log("testes Leandro", data.results);
    if (data.results) {
      return {
        data: data.results,
        totalCount: data.total,
      };
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const getIntallmentsByClientId = async (id: string): Promise<any[] | Error> => {
  try {
    const { data } = await Api().get(`/proposal/client/${id}`);
    if (data) {
      return data;
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const getById = async (id: string): Promise<IProposalList | Error> => {
  try {
    const { data } = await Api().get(`/proposal/${id}`);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao consultar o registro.'
    );
  }
};

const getRenovateById = async (id: string): Promise<IProposalList | Error> => {
  try {
    const { data } = await Api().get(`/proposal/renovate/${id}`);
    if (data) {
      const dataPlan = await Api().get(
        `/client-plan/by-client/${data.clientId}?${data.propertyFinally}`
      );

      // console.log('dataplan', dataPlan.data)
      return { ...data, clientPlan: { ...dataPlan.data[0] } };
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao consultar o registro.'
    );
  }
};

const createInstallment = async (
  dados: Omit<IInstallmentGenerate, 'id'>
): Promise<string | Error> => {
  try {
    const { data } = await Api().post<IInstallmentGenerate>(
      '/installment',
      dados
    );
    if (data) {
      return data.id;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao criar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IProposalList, 'id'>
): Promise<string | Error> => {
  try {
    const { data } = await Api().post<IProposalList>('/garantias', dados);
    if (data) {
      return data.id;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao criar o registro.'
    );
  }
};

const updateById = async (
  id: string,
  dados: IProposalList
): Promise<void | Error> => {
  try {
    await Api().put(`/garantias/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao atualizar o registro.'
    );
  }
};

const updateProposalClient = async (
  id: string,
  clientId: string
): Promise<void | Error> => {
  try {
    await Api().patch(`/proposal/${id}`, { clientId });
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao atualizar o registro.'
    );
  }
};

const RenovateProposal = async (
  id: string,
  dados: IRenovateProposal
): Promise<void | Error> => {
  try {
    console.log('renovateproposel service...', dados);
    await Api().post(`/proposal/renovate/${id}`, dados);
  } catch (error) {
    console.error(error);
    // return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api().delete(`/garantias/${id}`);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao apagar o registro.'
    );
  }
};

const GarantiasVigentes = async (): Promise<IProposalCount | Error> => {
  try {
    const { data, headers } = await Api().get(`/garantiasvigentes`);
    if (data) {
      // console.log('garantias vigentes', data);
      // console.log('...... espaco');
      return {
        Status: data[0].Status,
        quantidade: data[0].quantidade,
        aluguel: data[0].aluguel,
        lmi_total: data[0].lmi_total,
        lmi_saldo: data[0].lmi_saldo,
        valor_parcela: data[0].valor_parcela,
      };
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const GarantiasARenovar = async (): Promise<IProposalCount | Error> => {
  try {
    const { data, headers } = await Api().get(`/garantiasarenovar`);
    if (data) {
      return {
        Status: data[0].Status,
        quantidade: data[0].quantidade,
        aluguel: data[0].aluguel,
        lmi_total: data[0].lmi_total,
        lmi_saldo: data[0].lmi_saldo,
        valor_parcela: data[0].valor_parcela,
      };
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const GarantiasCanceladas = async (): Promise<IProposalCount | Error> => {
  try {
    const { data, headers } = await Api().get(`/garantiascanceladas`);

    if (data) {
      return {
        Status: data[0].Status,
        quantidade: data[0].quantidade,
        aluguel: data[0].aluguel,
        lmi_total: data[0].lmi_total,
        lmi_saldo: data[0].lmi_saldo,
        valor_parcela: data[0].valor_parcela,
      };
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const GarantiasExpiradas = async (): Promise<IProposalCount | Error> => {
  try {
    const { data, headers } = await Api().get(`/garantiasexpiradas`);
    if (data) {
      return {
        Status: data[0].Status,
        quantidade: data[0].quantidade,
        aluguel: data[0].aluguel,
        lmi_total: data[0].lmi_total,
        lmi_saldo: data[0].lmi_saldo,
        valor_parcela: data[0].valor_parcela,
      };
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const GarantiasPorMes = async (
  dataini = '',
  datafim = ''
): Promise<IProposalCountPorMes[] | Error> => {
  try {
    const urlRelativa = `/garantiasPorMes?dataini=${dataini}&datafim=${datafim}`;
    const { data, headers } = await Api().get(urlRelativa);

    if (data) {
      return data;
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const GarantiasTKMedio = async (
  dataini = '',
  datafim = ''
): Promise<ITKMedio | Error> => {
  try {
    const urlRelativa = `/garantiasticketmedio?imobiliaria=&dataini=${dataini}&datafim=${datafim}`;
    const { data, headers } = await Api().get(urlRelativa);
    if (data) {
      return data[0];
    }

    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const GarantiasDuracaoVigencia = async (
  dataini = '',
  datafim = ''
): Promise<IDuracaoVigencia[] | Error> => {
  try {
    const urlRelativa = `/garantiasduracaodevigencias?dataini=${dataini}&datafim=${datafim}`;
    const { data, headers } = await Api().get(urlRelativa);
    if (data) {
      return data.results;
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const GarantiasTempoMedio = async (
  dataini = '',
  datafim = ''
): Promise<ITempoMedio[] | Error> => {
  try {
    const urlRelativa = `/garantiasPorMes?dataini=${dataini}&datafim=${datafim}`;
    const { data, headers } = await Api().get(urlRelativa);

    if (data) {
      return data.results;
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const GarantiasCanceladasAntes = async (): Promise<ICancelAntes | Error> => {
  try {
    const { data, headers } = await Api().get(`/garantiasexpiradas`);
    if (data) {
      return {
        quantidade: data[0].quantidade,
        saldo: data[0].saldo,
        total: data[0].total,
        aluguel: data[0].aluguel,
        parcela: data[0].parcela,
      };
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const GarantiasTaxas = async (
  dataini = '',
  datafim = ''
): Promise<IGarantiaTaxas[] | Error> => {
  try {
    const urlRelativa = `/garantiasgrouptaxa?dataini=${dataini}&datafim=${datafim}`;
    const { data, headers } = await Api().get(urlRelativa);
    if (data.results) {
      return data.results;
    }
    return new Error('Erro ao listar registros.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros.'
    );
  }
};

const updateConfirmCancelId = async (
  id: string,
  dados: ICancelProposalConclude
): Promise<void | Error> => {
  try {
    await Api().post(`/proposal/cancel/finallization/${id}`, {
      canceledAt: dados.confirmCanceledAt,
      canceledConclude: new Date(),
      canceledReason: dados.confirmCanceledReason,
      canceledType: dados.confirmCanceledType,
    });
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao atualizar o registro.'
    );
  }
};

const updateCanceledId = async (
  id: string,
  dados: ICancelProposal
): Promise<void | Error> => {
  try {
    await Api().post(`/proposal/cancel/first-stage/${id}`, dados);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao atualizar o registro.'
    );
  }
};

// desistir do cancelamento da garantia e retornando para ativa
const updateConfirmCancelWaiver = async (id: string): Promise<void | Error> => {
  try {
    await Api().get(`/proposal/cancel/desistir/${id}`);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao atualizar o registro.'
    );
  }
};

const getLogs = async (proposalId: string): Promise<Logs[] | Error> => {
  try {
    const { data } = await Api().get(`/logs/${proposalId}`);

    if (!data)
      return new Promise((_, reject) =>
        reject(new Error('Erro ao consultar os registros de logs da garantia.'))
      );

    return data;
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getStatusInstallmentProposal = async (
  statusInstallmentProposal: string,
  id: string
): Promise<IInstallmentProposal[] | Error> => {
  try {
    const { data } = await Api().get(
      `/installment/proposal/${id}/${statusInstallmentProposal}`
    );

    if (!data)
      return new Promise((_, reject) =>
        reject(new Error('Erro ao consultar os registros.'))
      );

    return data;
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getTotalStatusInstallmentProposal = async (
  id: string
): Promise<ITotalStatusInstallmentProposal | Error> => {
  try {
    const { data } = await Api().get(`/installment/total-by-status/${id}`);

    if (!data)
      return new Promise((_, reject) =>
        reject(new Error('Erro ao consultar os registros.'))
      );

    return data;
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const saveCancelInstallment = async (
  dataForm: IFormInstallmentCancel,
  idInstallmentCancel: string
): Promise<string | Error> => {
  try {
    const { data } = await Api().patch(
      `/installment/cancel/${idInstallmentCancel}`,
      dataForm
    );

    if (!data)
      return new Promise((_, reject) =>
        reject(new Error('Erro ao consultar os registros.'))
      );

    return data;
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getLogUpdateProposal = async (
  proposalId: string
): Promise<UpdateProposalLogs[] | Error> => {
  try {
    const { data } = await Api().get(`/proposal/proposal-log/${proposalId}`);

    if (!data)
      return new Promise((_, reject) =>
        reject(new Error('Erro ao consultar os registros de logs da garantia.'))
      );

    return data;
  } catch (error) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

export const ProposalService = {
  getAll,
  getAllInstallmentProposal,
  getIntallmentsByClientId,
  getStatusInstallmentProposal,
  getTotalStatusInstallmentProposal,
  saveCancelInstallment,
  getById,
  getCertificate,
  create,
  createInstallment,
  updateById,
  deleteById,
  GarantiasVigentes,
  GarantiasARenovar,
  GarantiasCanceladas,
  GarantiasExpiradas,
  GarantiasPorMes,
  GarantiasTKMedio,
  GarantiasDuracaoVigencia,
  GarantiasTempoMedio,
  GarantiasCanceladasAntes,
  GarantiasTaxas,
  getTotal,
  getProposalStatus,
  updateCanceledId,
  getContract,
  updateConfirmCancelId,
  RenovateProposal,
  getRenovateById,
  updateConfirmCancelWaiver,
  getSinisterCheck,
  getLogs,
  getLogUpdateProposal,
  updateProposalSuperAdmin,
  updateProposalClient,
};
