import React, { useState } from 'react';
import {
  StyledFileBox,
  StyledFileIconBox,
  StyledFileMetadataBox,
  StyledFileOperationsBox, StyledOperationFileExclude, StyledOperationIconBox,
  StyledProposalFileItemContainer, StyledTypography
} from './ProposalFileItem.styled';
import { ProposalDocumentType } from '../../../../../../shared/services/api/proposal/types';
import { getFileExtension, getFileExtensionIcon } from '../../helpers';
import { Button, CircularProgress, Icon, Typography } from '@mui/material';
import { formatAnalysisContractTypeEnum } from '../../../../../../enums/analysis-documents';
import axios from 'axios';
import { formatDate } from '../../../../../../shared/utils/format-date';
import { BasicModal } from '../../../../../../shared/components';
import { ProposalDocumentsService } from '../../../../../../shared/services/api/proposal/ProposalDocumentsService';
import { ToastContent } from "../../../../../../shared/components";
import { useDebounce, useToast } from "../../../../../../shared/hooks";
import { returnMessageError } from '../../../../../../shared/utils/returnMessageError';

type ProposalFileItemProps = {
  item: ProposalDocumentType;
  refreshListDocuments?: (value: boolean) => void;
}
export const ProposalFileItem = ({ item, refreshListDocuments }: ProposalFileItemProps) => {

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const toastHandler = useToast();
  const { debounce } = useDebounce();

  const removeFile = async () => {
    setLoading(true);
    refreshListDocuments?.(false);
    debounce(() => {
      ProposalDocumentsService.removeDocumentByProposal(item.id)
        .then((result: void | Error) => {
          setLoading(false);
          refreshListDocuments?.(true);
          if (result instanceof Error) {
            const titleMessage = "Alerta!!";
            const contentMessage = "Erro: " + result?.message;

            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            });
          } else {
            toastHandler.present({
              type: 'success',
              position: 'top-right',
              durationMs: 3000,
              messageOrContent: <ToastContent title={"SUCESSO!"} content={"Documento excluido com sucesso."} />
            })

            setTimeout(() => {
              refreshListDocuments?.(false);
            }, 500);
          }
        }).catch((err: any) => {
          setLoading(false);
          const { titleMessage, contentMessage } = returnMessageError(err);

          toastHandler.present({
            type: 'error',
            position: 'top-right',
            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
          })
        })
    });
  }

  return (
    <div style={{ flexDirection: "row", display: "flex" }}>
      <StyledProposalFileItemContainer>
        <StyledFileBox>
          <StyledFileIconBox style={{
            height: '4rem',
            width: '4rem'
          }}>
            {getFileExtensionIcon(item.mimetype)}
          </StyledFileIconBox>
        </StyledFileBox>

        <StyledFileMetadataBox>
          <StyledTypography style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{item.originalFilename}</StyledTypography>
          <StyledTypography style={{ fontSize: '0.8rem' }}>{formatAnalysisContractTypeEnum(item.docType)}</StyledTypography>
          <StyledTypography style={{ fontSize: '0.8rem', color: 'grey', opacity: 0.9 }}>{formatDate(new Date(item.uploadedDate))}</StyledTypography>
        </StyledFileMetadataBox>

        <StyledFileOperationsBox>
          <a href={item.url} style={{
            textDecoration: 'none',
            color: 'inherit'
          }}>
            <StyledOperationIconBox>
              <Icon style={{
                fontSize: "2.5rem"
              }}>download</Icon>
            </StyledOperationIconBox>
          </a>
        </StyledFileOperationsBox>
      </StyledProposalFileItemContainer>
      <div style={{ alignSelf: "center", cursor: "pointer", marginRight: "8px", marginTop: "25px" }}>
        {loading ? <CircularProgress />
          : (<>
            <StyledOperationFileExclude />
            <Button
              color="error"
              disableElevation
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "inherit",
                },
                padding: 0,
                minWidth: 0,
                fontSize: "11px"
              }}
              onClick={() => setOpenModal(true)}
            >
              {'Excluir'}
            </Button>

            {openModal && (
              <BasicModal
                isCloseModal={() => setOpenModal(false)}
                isOpenModal={openModal}
                messageType={"alert"}
                showButtonCancel={true}
                textButtonConfirm={"Confirmar"}
                textButtonCancel='Cancelar'
                onclickConfirm={() => {
                  setOpenModal(false);
                  removeFile();
                }}
                onclickCancel={() => setOpenModal(false)}
                IMessage={"Você deseja excluir mesmo o arquivo?"}
              />
            )}
          </>)}
      </div>
    </div>
  )
}