export * from './decode-jwt';
export * from './changeBackgroundColor';
export * from './translate';
export * from './changeColorIcon';
export * from './changeTextColor';
export * from './mask-phone';
export * from './sanitize-phone-string';
export * from './global-translate';
export * from './global-changeColors';
export * from './globalMask-document';
export * from './global-itens-enum';
export * from './copyFunction';
export * from './sleep';
export * from './statusClient';

export * from './formatCompareText';
export * from './global-utils-proposal';

export * from './globalSearchCPF';
export * from './searchCEP';
export * from './verifyCellPhoneAndCPF';
export * from './formatStatusSignature';


