import { useEffect, useState } from "react";
import {
    Grid,
    Paper,
    Typography,
    useTheme,
    useMediaQuery
} from "@mui/material";
import { clientService, IDetalheCliente } from "../../../shared/services/api/client/clientService";
import { VTextField, VForm, useVForm, IVFormErros } from "../../../shared/forms";
import { FerramentaDetalhe } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useNavigate, useParams } from "react-router-dom";
import { translate } from "../../../shared/utils/translate";
import { useDrawerContext } from "../../../context";
import { useProfile } from '../../../context/ProfileContext';



export const ClientDetailPresentation: React.FC = () => {
    const { id = 'novo' } = useParams<'id'>();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
    const [isLoading, setIsLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [documento, setDocumento] = useState('');
    const [row, setRow] = useState<IDetalheCliente>();
    const { setDrawerOptions } = useDrawerContext();
    const { permissionLevel } = useProfile();
    const [isSendClickSetup, SetIsSendClickSetup] = useState(true);         

    useEffect(() => {
        if (id !== 'novo') {
            setIsLoading(true);
            clientService.getByID(id)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/clientes');
                    } else {
                        setNome(result.name);
                        setDocumento(result.document);
                        setRow(result);
                    }
                });
        }
    }, [id]);

    const maskPhone = (value: string) => {
        let cell = value.length <= 14 ?
            value
                .replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '($1) $2')
                .replace(/(\d{4})(\d)/, '$1-$2')
                .replace(/(-\d{4})\d+?$/, '$1')
            :
            value
                .replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '($1) $2')
                .replace(/(\d{5})(\d)/, '$1-$2')
                .replace(/(-\d{4})\d+?$/, '$1')

        if (cell.length < 1) {
            cell = " ";
        }
        return cell
    };


    return (
        <LayoutBaseDePagina
            titulo={permissionLevel === 1 ? "Clientes" : "Perfil de cadastro"}
            subtitulo={permissionLevel === 1 ? "Listagem de clientes" : "Meu Cadastro"}
            barraDeFerramentas={
                <FerramentaDetalhe
                    textoButtonNew="novo"
                    showButtonNew={false}
                    showBotaoApagar={false}
                    showBotaoSalvar={false}
                    showBotaoSalvarFechar={false}
                    clicarVoltar={() => navigate('/clientes')}
                />
            }
        >


            < Grid container spacing={3} >
                <Grid item xs={12} md={8} >
                    <Grid container sm={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        ml={smDown ? 0 : 2}
                    >
                        <Grid container sm={12}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            component={Paper}
                            padding={2}
                            spacing={1}
                        >
                            <Grid container sm={12} md={12}
                                direction="row"
                                spacing={1} >
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'}>
                                        Informações Gerais
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nome/ Razão Social
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.name}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        CPF/CNPJ
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {documento.length > 11 ?
                                            `${row?.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                                            :
                                            `${row?.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nome Fantasia
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.trade_name}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Tipo de Cliente
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {translate(`${row?.type_person}`)}
                                        {row?.type_person}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Responsável
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.responsible_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Documento do responsável
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.responsible_document}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        CRECI
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.creci}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} mb="1rem">
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Celular
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskPhone(`${row?.phone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Telefone
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskPhone(`${row?.mobilePhone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Email
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Endereço
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {`${row?.address_street != null && row?.address_street != '' ?
                                            `${row?.address_complement != null && row?.address_complement != '' ?
                                                `${row?.address_street},${row?.address_number} - ${row?.address_complement} -
                                            ${row?.address_district} - ${row?.address_city} - ${row?.address_state} - ${row?.address_zipcode}`
                                                : `${row?.address_street},${row?.address_number} - 
                                            ${row?.address_district} - ${row?.address_city} - ${row?.address_state} - ${row?.address_zipcode}`
                                            } ` : ''
                                            }`
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container sm={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        mt={2} ml={smDown ? 0 : 2}
                    >
                        <Grid container sm={12}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            component={Paper}
                            padding={2}
                            spacing={1}
                        >
                            <Grid container sm={12} md={12}
                                direction="row"
                                spacing={1} >
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'}>
                                        Dados Bancários
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Banco
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.bank_code}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Agencia
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.agency_number}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'} sx={{ textAlign: "left" }}>
                                        Digito
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
                                        {row?.agency_digit}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Conta
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.account_number}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Digito
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.account_digit}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} mb="1rem">
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Tipo de Pix
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.pix_key_type}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Chave Pix
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.pix_key}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Responsavel
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.bank_manager}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Vencimento da Fatura
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {row?.invoice_due_date}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >

        </LayoutBaseDePagina >
    );
};