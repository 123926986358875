export const formatClientContractStatus = (status: string = "") => {
  switch (status) {
    case "pending_signature":
      return "Pendente de assinatura";
    case "signed_signature":
      return "Assinatura assinado";
    case "canceled_signature":
      return "Assinatura cancelada";
    case "not_signed_signature":
      return "Assinatura não assinada";
    default:
      return "Outro";
  }
};
