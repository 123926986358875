import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useAuthContext} from './AuthContext';
import jwtDecode from 'jwt-decode';
import { JwtPayloadType } from '../shared/services/api/auth/types/jwt-payload.type';
import { PermissionRolesType } from '../shared/types/profiles/permission-roles.type';

interface IProfileData{
    registerClient: IActionsData;
    permissions: PermissionRolesType | null;
    permissionLevel: number | null
    permissionId: string | null
    isPermissionInitialized: boolean
    isSuperAdmin: boolean
    userClientId: string;
    permissionDescription: string | null;
}

interface IActionsData{
    view?: boolean;
    add?: boolean;
    update?: boolean;
    delete?: boolean;
    remove?: boolean;
    filter?: boolean;
    filterLevel?: boolean;
    conclude?: boolean;
    serasa?: boolean;
    clickSign?: boolean;
    tipping?: boolean;
    upProposalPeriod?: boolean;
    upProposalValues?: boolean;
    upProposalAddress?: boolean;
    upProposalCancel?: boolean;
    upProposalLocator?:boolean;
    upProposalConcludeCancel:boolean;
    reportFinancial?: boolean;
    reportProposals?:boolean;
    reportAnalysis?:boolean;
    reportSinister?:boolean;
    reportAdmin?:boolean;
    reportClient?:boolean;
    reportLogAndAudit?:boolean;
    blockCancel?:boolean;
    walletTransfer?:boolean;
    contracts?:boolean;

}

const ProfileContext = createContext({} as IProfileData);

interface IProfileProviderProps{
    children: React.ReactNode;
}

export const ProfileProvider: React.FC<IProfileProviderProps> = ({ children }) => {
    const { accessToken, appIsInitialized } = useAuthContext()
    const [permissions, setPermissions] = useState<PermissionRolesType | null>(null);
    const [permissionLevel, setPermissionLevel] = useState<number | null>(null);
    const [permissionId, setPermissionId] = useState<string | null>(null);
    const [registerClient, setRegisterClient] = useState({} as IActionsData);
    const [permissionIsInitialized, setPermissionIsInitialized] = useState<boolean>(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
    const [userClientId, setUserClientId] = useState<string>("");
    const [permissionDescription, setPermissionDescription] = useState<string | null>(null);

    const memoizedIsPermissionInitialized = useMemo(() => {
      return permissionIsInitialized
    }, [permissionIsInitialized])

    useEffect(() => {
      if (accessToken) {
        const decoded = jwtDecode<JwtPayloadType>(accessToken)

        if (decoded.permissions) {
          const { permissions, permission_level, permission_id, user_clientId, permission_description } = decoded;

          setPermissions(permissions);
          setPermissionLevel(permission_level)
          setPermissionId(permission_id)
          setIsSuperAdmin(permission_level === 1);
          setUserClientId(user_clientId);
          setRegisterClient(permissions.clients_options);
          setPermissionDescription(permission_description);
        }
      }
    }, [accessToken]);

  useEffect(() => {
    if (appIsInitialized) {
      setPermissionIsInitialized(true)
    }
  }, [appIsInitialized]);

      return (
          <ProfileContext.Provider value={{
              registerClient,
              permissions,
              permissionLevel,
              permissionId,
              isPermissionInitialized: memoizedIsPermissionInitialized,
              isSuperAdmin,
              userClientId,
              permissionDescription
          }}>
              {children}
          </ProfileContext.Provider>
      )
  }

export const useProfile = () => useContext(ProfileContext);