export const translate: (value: string) => string = (value: string) => {
  switch (value) {
    case "active":
      return "Ativo";
    case "inactive":
      return "Inativo";
    case "blocked":
      return "Bloqueado";
    case "canceled":
      return "Cancelado";
    case "sent":
      return "Enviado";
    default:
      return "Ativo";
  }
};
