import { useEffect } from "react";
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import {
    AnalysisDetailPage,
    AnalysisHiringPage,
    AnalysisListPage,
    AnalysisRequestPage,
    ClientDetailPage,
    DashboardPage,
    DetalheClientesPage,
    DetalheUsuariosPage,
    FinancialListPage,
    ForgotPasswordPage,
    GraficosPage,
    ListagemClientesPage,
    NfseListPage,
    ListagemGeraisPage,
    ListagemRelatoriosPage,
    ListagemUsuariosPage,
    ListPermissionsPage,
    LoginPage,
    PermissionsDetailsPage,
    ProposalDetailPage,
    ProposalListPage,
    ProposalRenovatePage,
    ResetPasswordPage,
    SinisterDetailsPage,
    SinisterListPage,
    SinisterValuesPage,
    FinancialHealthListPage,
    ListagemContratosPage,
} from "../pages";

import { useDrawerContext } from "../context";
import { FinancialInvoiceListPage } from "../pages/financial/FinancialInvoiceListPage";
import { SicoobBoletoPage, SummaryPage } from "../pages/financial";
import { FinancialBorderoPage } from "../pages/financial";
import {
    PermissionActionsEnum,
    PermissionRolesEnum,
} from "../shared/enums/profiles";
import { useProfile } from "../context/ProfileContext";
import { checkByProfileGuard } from "../shared/guards/ProfileGuard";
import { PerfilUserDetailPresentation } from "../pages/user/components";
import { DashboardGraphic } from "../pages/dashboard";
import {
    FinancialSummaryPage,
    ReportAnalysisPage,
    ReportUpdateProposalPage,
    ReportsPage,
} from "../pages/relatorios";
import { ProposalEditPage } from "../pages/proposal/ProposalEditPage";
import { ProccessCancelBlockClientPage } from "../pages/financial/ProccessCancelBlockClientPage";
import { ReportProposalPage } from "../pages/relatorios/ReportProposalPage";
import { TransferClientsPage } from "../pages/client";
import { BudgetPage } from "../pages/fire/budget/BudgetPage";
import { ReportLifecycleAnalysisPage } from "../pages/relatorios/ReportLifecycleAnalysisPage";
import { ReportLifecycleProposalPage } from "../pages/relatorios/ReportLifecycleProposalPage";
import { DetalheContratosPage } from "../pages/contract";
import { OnboardPaymentPage } from "../pages/payments";
import { FinancialSetupPage } from "../pages/financial/FinancialSetupPage";

type DrawerOptionsType = {
    label: string;
    icon: string;
    path: string;
    requiredRoleType?: PermissionRolesEnum;
};

export const AppRoutes = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.includes("//")) {
            const sanitizedPath = pathname.replace("//", "/");

            console.log(sanitizedPath);
            navigate(sanitizedPath, { replace: true });
        }
    }, [pathname]);

    const { setDrawerOptions } = useDrawerContext();
    const { permissions, permissionLevel } = useProfile();

    const DrawerOptionsGarantti: DrawerOptionsType[] = [
        {
            label: "DashBoard",
            icon: "dashboard_outlined",
            path: `/dashboard`,
        },
        {
            label: "Clientes",
            icon: "people_outline",
            path: "/clientes",
            requiredRoleType: PermissionRolesEnum.ClientsDashboard,
        },
        {
            label: "Usuários",
            icon: "person_outlinedicon",
            path: "/usuarios",
            requiredRoleType: PermissionRolesEnum.UsersDashboard,
        },
        {
            label: "Análises",
            icon: "insert_chart_outlinedicon",
            path: "/analises",
            requiredRoleType: PermissionRolesEnum.AnalysisDashboard,
        },
        {
            label: "Garantias",
            icon: "security_outline",
            path: "/garantias",
            requiredRoleType: PermissionRolesEnum.WarrantiesMenu,
        },
        {
            label: "Sinistro",
            icon: "notifications_noneoutlinedicon",
            path: "/sinistros",
            requiredRoleType: PermissionRolesEnum.SinisterMenu,
        },
        {
            label: "Relatórios",
            icon: "assignment_outlinedicon",
            path: "/relatorios",
            requiredRoleType: PermissionRolesEnum.ReportsMenu,
        },
        {
            label: "Financeiro",
            icon: "monetization_onicon",
            path: "/financeiro",
            requiredRoleType: PermissionRolesEnum.FinancialMenu,
        },
        {
            label: "Gestao Financeira",
            icon: "local_atm_outlinedicon",
            path: "/saudefinanceira",
            requiredRoleType: PermissionRolesEnum.FinancialMenu,
        },
        {
            label: "Bordero",
            icon: "local_atm_outlinedicon",
            path: "/bordero",
            requiredRoleType: PermissionRolesEnum.FinancialMenu,
        },

        {
            label: "Documentos para Download",
            icon: "info_outlinedicon",
            path: "/gerais",
            requiredRoleType: PermissionRolesEnum.GeneralConditionsMenu,
        },

        {
            label: "Gráficos",
            icon: "equalizer_outlinedicon",
            path: "/graficos",
            requiredRoleType: PermissionRolesEnum.ChartsMenu,
        },
        {
            label: "Permissões",
            icon: "admin_panel_settings_outlined",
            path: "/permissoes",
            requiredRoleType: PermissionRolesEnum.PermissionsMenu,
        },
        {
            label: "Incêndio",
            icon: "local_fire_department_outlinedIcon",
            path: "/incendio/orcamento",
            requiredRoleType: PermissionRolesEnum.PermissionsMenu,
        },
    ];

    const DrawerOptionsImob: DrawerOptionsType[] = [
        {
            label: "DashBoard",
            icon: "dashboard_outlined",
            path: `/dashboard`,
        },
        {
            label: "Perfil",
            icon: "people_outline",
            path: "/clientes",
            requiredRoleType: PermissionRolesEnum.ClientsDashboard,
        },

        {
            label: "Usuários",
            icon: "person_outlinedicon",
            path: "/usuarios",
            requiredRoleType: PermissionRolesEnum.UsersDashboard,
        },
        {
            label: "Análises",
            icon: "insert_chart_outlinedicon",
            path: "/analises",
            requiredRoleType: PermissionRolesEnum.AnalysisDashboard,
        },
        {
            label: "Garantias",
            icon: "security_outline",
            path: "/garantias",
            requiredRoleType: PermissionRolesEnum.WarrantiesMenu,
        },
        {
            label: "Sinistro",
            icon: "notifications_noneoutlinedicon",
            path: "/sinistros",
            requiredRoleType: PermissionRolesEnum.SinisterMenu,
        },
        {
            label: "Documentos para Download",
            icon: "info_outlinedicon",
            path: "/gerais",
            requiredRoleType: PermissionRolesEnum.GeneralConditionsMenu,
        },
    ];

    useEffect(() => {
        if (permissionLevel) {
            const consideredOptions: DrawerOptionsType[] =
                permissionLevel === 1 || permissionLevel === 2
                    ? DrawerOptionsGarantti
                    : DrawerOptionsImob;

            const appliedRoleOptions = consideredOptions.filter((option) => {
                if (option.requiredRoleType === undefined) return true;

                if (!permissions) return false;

                const { requiredRoleType } = option;
                const foundPermission = permissions[requiredRoleType];

                return foundPermission?.view === true;
            });

            setDrawerOptions(appliedRoleOptions);
        }
    }, [permissionLevel, permissions]);

    return <Routes>{getRoutes()}</Routes>;
};

const getRoutes = () => {
    return (
        <>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            {checkByProfileGuard(
                PermissionRolesEnum.ChartsOptions,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route
                        path="/dashboard/graficos"
                        element={<DashboardGraphic />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.UsersDashboard,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route
                        path="/usuarios"
                        element={<ListagemUsuariosPage />}
                    />
                    <Route
                        path="/usuarios/detalhe/:id"
                        element={<DetalheUsuariosPage />}
                    />
                    <Route
                        path="/usuarios/perfil/:id"
                        element={<PerfilUserDetailPresentation />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.ClientsDashboard,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route
                        path="/clientes"
                        element={<ListagemClientesPage />}
                    />

                    <Route
                        path="/clientes/detalheunico/:id"
                        element={<ClientDetailPage />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.ClientsOptions,
                PermissionActionsEnum.Update
            ) && (
                <>
                    <Route
                        path="/clientes/detalhe/:id"
                        element={<DetalheClientesPage />}
                    />
                    <Route
                        path="/clientes/detalheunico/:id"
                        element={<ClientDetailPage />}
                    />
                    <Route
                        path="/clientes/transferencia"
                        element={<TransferClientsPage />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.ClientsOptions,
                PermissionActionsEnum.Contracts
            ) && (
                <>
                    <Route
                        path="/clientes/contratos"
                        element={<ListagemContratosPage />}
                    />
                    <Route
                        path="/clientes/contratos/detalhe/:id"
                        element={<DetalheContratosPage />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.AnalysisDashboard,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route path="/analises" element={<AnalysisListPage />} />
                    <Route
                        path="/analises/pedido"
                        element={<AnalysisRequestPage />}
                    />
                    <Route
                        path="/analises/pedido/:id"
                        element={<AnalysisRequestPage />}
                    />
                    <Route
                        path="/analises/contratacao"
                        element={<AnalysisHiringPage />}
                    />
                    <Route
                        path="/analises/visualizar/:id"
                        element={<AnalysisDetailPage />}
                    />
                    <Route
                        path="/analises/detalhe/:id"
                        element={<AnalysisRequestPage />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.WarrantiesMenu,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route path="/garantias" element={<ProposalListPage />} />
                    <Route
                        path="/garantias/detalhe/:id"
                        element={<ProposalDetailPage />}
                    />
                    <Route
                        path="/garantias/visualizar/:id"
                        element={<ProposalDetailPage />}
                    />
                    <Route
                        path="/garantias/renovar/:id"
                        element={<ProposalRenovatePage />}
                    />
                    <Route
                        path="/garantias/alterar/:id"
                        element={<ProposalEditPage />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.SinisterMenu,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route path="/sinistros" element={<SinisterListPage />} />
                    <Route
                        path="/sinistros/detalhe/:id"
                        element={<SinisterDetailsPage />}
                    />
                    <Route
                        path="/sinistros/valores/:id"
                        element={<SinisterValuesPage />}
                    />
                </>
            )}
            //####### R E P O R T S #######################
            {checkByProfileGuard(
                PermissionRolesEnum.ReportsMenu,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route
                        path="/relatorios"
                        element={<ListagemRelatoriosPage />}
                    />
                    <Route
                        path="/summary/financial"
                        element={<FinancialSummaryPage />}
                    />
                    <Route
                        path="/report/update-proposal"
                        element={<ReportUpdateProposalPage />}
                    />
                    <Route
                        path="/report/analysis"
                        element={<ReportAnalysisPage />}
                    />
                    <Route
                        path="/report/proposal"
                        element={<ReportProposalPage />}
                    />
                    <Route path="/report" element={<ReportsPage />} />
                    <Route
                        path="/report/analysis/analytics"
                        element={<ReportLifecycleAnalysisPage />}
                    />
                    <Route
                        path="/report/proposal/analytics"
                        element={<ReportLifecycleProposalPage />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.ReportsMenu,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route path="/bordero" element={<FinancialBorderoPage />} />
                    <Route
                        path="/saudefinanceira"
                        element={<FinancialHealthListPage />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.FinancialMenu,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route path="/financeiro" element={<FinancialListPage />} />
                    <Route
                        path="/boletos"
                        element={<FinancialInvoiceListPage />}
                    />
                    <Route path="/notas" element={<NfseListPage />} />
                    <Route
                        path="/incendio/orcamento"
                        element={<BudgetPage />}
                    />
                    <Route path="/setup" element={<FinancialSetupPage />} />
                    <Route
                        path="/bloqueio/cliente/:id"
                        element={<ProccessCancelBlockClientPage />}
                    />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.GeneralConditionsMenu,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route path="/gerais" element={<ListagemGeraisPage />} />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.ChartsMenu,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route path="/graficos" element={<GraficosPage />} />
                </>
            )}
            {checkByProfileGuard(
                PermissionRolesEnum.PermissionsMenu,
                PermissionActionsEnum.View
            ) && (
                <>
                    <Route
                        path="/permissoes"
                        element={<ListPermissionsPage />}
                    />
                    <Route
                        path="/permissoes/detalhe/:id"
                        element={<PermissionsDetailsPage />}
                    />
                </>
            )}
            <Route path="/installment/summary/:id" element={<SummaryPage />} />
            <Route path="/sicoob/boleto/:id" element={<SicoobBoletoPage />} />
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            <Route path="*" element={<Navigate replace to="/dashboard" />} />
            <Route
                path="/onboard/payment/:id"
                element={<OnboardPaymentPage />}
            />
        </>
    );
};
