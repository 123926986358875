import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Typography,
} from "@mui/material";
import { VMaskTextField } from "../../../../shared/forms/VMaskTextField";

interface IParamsClientProps {
    isLoading: boolean;
    setBaseRateCalc: (e: any) => void;
    baseRateCalc: string;
    isSuperAdmin: any;
    setActiveSignature: (e: any) => void;
    activeSignature: boolean;
    setSignContract: (e: any) => void;
    signContract: boolean;
    activeSetup: boolean;
    permissionLevel: any;
    setActiveSetup: (e: any) => void;
}

export const ParamsClient: React.FC<IParamsClientProps> = ({
    isLoading,
    setBaseRateCalc,
    baseRateCalc,
    isSuperAdmin,
    setActiveSignature,
    activeSignature,
    setSignContract,
    signContract,
    permissionLevel,
    setActiveSetup,
    activeSetup,
}) => {
    return (
        <Box
            margin={1}
            display="flex"
            flexDirection="column"
            component={Paper}
            variant="outlined"
        >
            <Grid container direction="column" padding={2} spacing={2}>
                <Grid container item direction="row" spacing={2}>
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Typography variant="h6" color={"primary"}>
                            Parâmetros
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                margin={2}
                borderRadius={2}
                border={"1px solid #e2e2e6"}
            >
                <Grid container direction="column" padding={2} spacing={2}>
                    <Grid container item direction="row" spacing={2}>
                        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Typography
                                variant="body1"
                                color={"primary"}
                                fontWeight={600}
                            >
                                Tipo de Cobrança
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {isSuperAdmin && (
                    <Grid
                        container
                        item
                        direction="row"
                        paddingLeft={4}
                        spacing={2}
                        mb={2}
                    >
                        {/* <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="id_baseRateCalc">
                                    Calcular Taxa
                                </InputLabel>
                                <Select
                                    labelId="label_baseRateCalc"
                                    id="id_baseRateCalc"
                                    fullWidth
                                    disabled={isLoading}
                                    value={baseRateCalc}
                                    style={{ height: "40px" }}
                                    name="baseRateCalc"
                                    label="Calcular Taxa"
                                    onChange={(e: any) => {
                                        setBaseRateCalc(e.target.value);
                                    }}
                                >
                                    <MenuItem value={"aluguel"}>
                                        Aluguel
                                    </MenuItem>
                                    <MenuItem value={"encargos"}>
                                        Alguel+Encargos(condomínio+IPTU)
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            display={"flex"}
                            alignItems={"center"}
                        >
                            <Typography>
                                Ativar Assinatura Eletrônica
                            </Typography>
                            <Switch
                                color="secondary"
                                onChange={(e: any) => {
                                    setActiveSignature(e.target.checked);
                                }}
                                disabled={isLoading}
                                name="activeSignature"
                                checked={activeSignature}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            display={"flex"}
                            alignItems={"center"}
                        >
                            <Typography>
                                Assinar Contrato Digitalmente
                            </Typography>
                            <Switch
                                color="secondary"
                                onChange={(e: any) => {
                                    setSignContract(e.target.checked);
                                }}
                                disabled={isLoading}
                                name="signContract"
                                checked={signContract}
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>

            {(isSuperAdmin || permissionLevel == 2) && (
                <>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        margin={2}
                        borderRadius={2}
                        border={"1px solid #e2e2e6"}
                    >
                        <Grid
                            container
                            direction="column"
                            padding={2}
                            spacing={2}
                        >
                            <Grid container item direction="row" spacing={2}>
                                <Grid item md={11}>
                                    <Typography
                                        variant="body1"
                                        color={"primary"}
                                        fontWeight={600}
                                    >
                                        Setup e Bonificações
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            item
                            direction="row"
                            spacing={2}
                            marginBottom={2}
                        >
                            <Box
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                marginLeft={2}
                                paddingX={4}
                            >
                                <Typography>Cobrar taxa Setup</Typography>
                                <Switch
                                    color="secondary"
                                    onChange={(e: any) => {
                                        setActiveSetup(e.target.checked);
                                    }}
                                    disabled={isLoading}
                                    name="tax_setup_enable"
                                    checked={activeSetup}
                                />
                            </Box>

                            <Grid item xs={12} sm={12} md={2}>
                                <VMaskTextField
                                    fullWidth
                                    label="Valor Minimo"
                                    name="tax_setup_limit_min"
                                    disabled={isLoading && activeSetup == false}
                                    mask="real"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <VMaskTextField
                                    fullWidth
                                    label="Valor Maximo"
                                    name="tax_setup_limit_max"
                                    disabled={isLoading}
                                    mask="real"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2}>
                                <VMaskTextField
                                    fullWidth
                                    label="Valor Padrão"
                                    name="tax_setup_limit_default"
                                    disabled={isLoading}
                                    mask="real"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Box>
    );
};
