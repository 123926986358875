import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDebounce } from '../../../shared/hooks';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { analysisService, IAllAnalysisList } from '../../../shared/services/api/analysis/analysisService';
import { IproposalTotalStatus, ProposalService } from '../../../shared/services/api/proposal/ProposalService';
import { clientService, IGroupCliente } from "../../../shared/services/api/client/clientService";
import { SinisterService } from '../../../shared/services/api/sinister/SinisterService';

import publicidade from "../../../assets/publicidade.jpeg";

import { Chart } from "react-google-charts";
import _ from 'lodash';
import { useProfile } from '../../../context/ProfileContext';
import { checkByProfileGuard, ProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { Loading, ToastContent } from '../../../shared/components';
import { useToast } from '../../../shared/hooks';
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { formatScaleValueBR } from '../../../shared/utils/formatScaleValue';


function translate(traduzir: String) {
  if (traduzir === 'estate') { return 'Imobiliária' }
  if (traduzir === 'broker') { return 'Corretor' }
  return traduzir;
};

const maskReal = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{1,2})$/, ',$1')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
};


export const DashboardPresentation = () => {
  const bonusPlan = [
    {
      'id': 'custom',
      'bonus': 0.0,
      'description': 'Personal I',
      'isExist': false,
    },
    {
      'id': 'gold',
      'bonus': 0.0,
      'description': 'Gold',
      'isExist': false,
    },
    {
      'id': 'silver',
      'bonus': 0.0,
      'description': 'Platinum',
      'isExist': false,
    }
  ];
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const { isSuperAdmin, permissionLevel, userClientId } = useProfile();

  const [isloadingClientes, setIsLoadingClientes] = useState(true);
  const [dataClientes, setDataClientes] = useState<(string | number)[][]>();

  const [isloadingGarantias, setIsLoadingGarantias] = useState(true);
  const [countProposal, setCountProposal] = useState<number>(0);

  const [isloadingAnalises, setIsLoadingAnalises] = useState(true);
  const [isloadingAnalisesAproved, setIsLoadingAnalisesAproved] = useState(true);

  const [countAnalisys, setCountAnalisys] = useState<number>(0);
  const [countAnalisysAproved, setCountAnalisysAproved] = useState<number>(0);

  const [isloadingSinistros, setIsLoadingSinistros] = useState(true);
  const [countSinister, setCountSinister] = useState<number>(0);

  const [garantiasVigentes, setGarantiasVigentes] = useState<Number>(0);
  const [garantiasaRenovar, setGarantiasARenovar] = useState<Number>(0);
  const [garantiasCanceladas, setGarantiasCanceladas] = useState<Number>(0);
  const [garantiasExpiradas, setGarantiasExpiradas] = useState<Number>(0);
  const [garantiasEmSinistro, setGarantiasEmSinistro] = useState<Number>(0);

  const [rows, setRows] = useState<IGroupCliente[]>([]);
  const [proposalRows, setProposalRows] = useState<IproposalTotalStatus[]>([]);

  const [analysis, setAnalysis] = useState<IAllAnalysisList[]>([]);
  const [bonusPlans, setBonusPlans] = useState<any[]>(bonusPlan);
  const toastHandler = useToast();

  // const databar = [
  //     ["Periodo", "Analises", "Aprovadas", "Garantias"],
  //     ["Julho", 1000, 600, 500],
  //     ["Agosto", 980, 650, 450],
  //     ["Setembro", 1350, 850, 600],
  //     ["Outubro", 1170, 560, 550],
  //     ["Novembro", 1030, 755, 530],
  //     ["Dezembro", 900, 540, 350],
  // ];

  const options = {
    //title: "Tipos de Clientes",
    titleTextStyle: { color: '#E9B801' },
    pieSliceText: "value",
    colors: ['#1FDFEF', '#7924c7', '#97D3F0', '#82DC80'],
    pieHole: 0.6,
    legend: { position: 'bottom', textStyle: { fontSize: 14 } },
    showColorCode: true,
    backgroundColor: "#FFF",
  };


  const loadDataProposal = (data: any) => {
    const values = proposalRows;

    const actives = values.find(opcao => opcao.status === "active");
    actives != undefined ? setGarantiasVigentes(actives.total) : setGarantiasVigentes(0);

    const cancels = values.find(opcao => opcao.status === "canceled");
    cancels != undefined ? setGarantiasCanceladas(cancels.total) : setGarantiasCanceladas(0);

    const expireds = values.find(opcao => opcao.status === "expired");
    expireds != undefined ? setGarantiasExpiradas(expireds.total) : setGarantiasExpiradas(0);

    const expiriesAt = values.find(opcao => opcao.status === "A Expirar");
    expiriesAt != undefined ? setGarantiasARenovar(expiriesAt.total) : setGarantiasARenovar(0);

    const sinisters = values.find(opcao => opcao.status === "Sinistro");
    sinisters != undefined ? setGarantiasEmSinistro(sinisters.total) : setGarantiasEmSinistro(0);
  };


  const loadDataCli = (data: any) => {
    const values = rows;
    const str: keyof IGroupCliente = "description";
    const stq: keyof IGroupCliente = "total";
    const result = _.map(values, (value, key) => [
      values[key][str],
      parseFloat(values[key][stq])
    ]);
    return [
      ["Tipo", "Quantidade"], ...result]
  };


  useEffect(() => {

    if (!isSuperAdmin) {
      debounce(() => {
        analysisService.findAllAnalysis(userClientId)
          .then((result: IAllAnalysisList[] | Error) => {
            if (result instanceof Error) {
              const titleMessage = "Alerta!!";
              const contentMessage = "Erro: " + result?.message;

              toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
              })
            } else setAnalysis(result);

          }).catch((err: any) => {

            const { titleMessage, contentMessage } = returnMessageError(err);
            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            })
          })
      });
    }
  }, []);


  useEffect(() => {
    if (!isSuperAdmin && analysis?.length) {

      analysis?.forEach((analy: IAllAnalysisList) => {
        let planList: any[] = JSON.parse(analy?.planlist);
        planList = planList?.filter((pl: any) => pl?.status == "active");

        planList?.forEach((pl: any) => {
          const index = bonusPlan.findIndex(b => b.id == pl?.planId);

          if (index != -1) {

            if (!pl.bonusAmount || isNaN(pl.bonusAmount)) pl.bonusAmount = 0;

            bonusPlan[index].bonus += Number(pl.bonusAmount);
            bonusPlan[index].isExist = true;
          }
        });

      });

      setBonusPlans(bonusPlan);
    }
  }, [analysis]);


  useEffect(() => {
    const data = [
      clientService.getAllGrafico()
        .then((result) => {
          setIsLoadingClientes(false);
          if (result instanceof Error) {
            /*alert(result.message);*/
          } else {
            setRows(result.data);
          }
        })
    ];
    setDataClientes(loadDataCli(data));
  }, [isloadingClientes]);


  useEffect(() => {
    debounce(() => {
      ProposalService.getTotal()
        .then((result) => {
          //setIsLoadingGarantias(false);
          if (result instanceof Error) {
            /*alert(result.message);*/
          } else {
            setCountProposal(result.total);
          }
        });

      const datap = [
        ProposalService.getProposalStatus()
          .then((result) => {
            setIsLoadingGarantias(false);
            if (result instanceof Error) {
              /*alert(result.message);*/
            } else {
              setProposalRows(result);
            }
          })
      ];
      loadDataProposal(datap);
    });
  }, [isloadingGarantias]);


  useEffect(() => {
    analysisService.getTotalAproved()
      .then((result) => {
        setIsLoadingAnalisesAproved(false);
        if (result instanceof Error) {
          /*alert(result.message);*/
        } else {
          setCountAnalisysAproved(result.total);
        }
      });
  }, [isloadingAnalisesAproved]);


  useEffect(() => {
    analysisService.getTotal()
      .then((result) => {
        setIsLoadingAnalises(false);
        if (result instanceof Error) {
          /*alert(result.message);*/
        } else {
          setCountAnalisys(result.total);
        }
      });
  }, [isloadingAnalises]);


  useEffect(() => {
    SinisterService.getTotal()
      .then((result) => {
        setIsLoadingSinistros(false);
        if (result instanceof Error) {
          /*alert(result.message);*/
        } else {
          setCountSinister(result.total);
        }
      })
  }, [isloadingSinistros]);

  return (
    <LayoutBaseDePagina titulo="Dashboard" subtitulo='Informações Gerais' >
      {(isloadingAnalises || isloadingAnalisesAproved
        || isloadingClientes || isloadingGarantias || isloadingSinistros) && <LinearProgress variant="indeterminate" />}

      <Box display={"flex"} flexDirection={smDown ? 'column' : 'row'} padding={1} paddingY={2} alignItems={"flex-start"} gap={2}>
        <Grid container item sm={12} md={8}
          display={"flex"} flexDirection={smDown ? 'column' : 'row'}
          gap={2}
        >
          <Grid container item sm={12}
            sx={{ borderRadius: "6px" }}
            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
            component={Paper}
            padding={2}
          >
            <Grid container item padding={1} sx={{ borderRadius: "6px" }} >
              <Typography variant="subtitle1" fontWeight={"bold"} color={"primary"}>
                Resumo
              </Typography>
            </Grid>

            <Grid container item sm={12} md={4}>
              <Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
                <CardContent sx={{ textAlign: "center" }}>
                  {!isloadingGarantias && (
                    <Typography variant="h3" fontWeight={"300"} color={"secondary"}>
                      {countProposal}
                    </Typography>
                  )}
                  {isloadingGarantias && (
                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                      Loading
                    </Typography>
                  )}
                  <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                    Garantias
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid container item sm={12} md={4}>
              <Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
                <CardContent sx={{ textAlign: "center" }}>
                  {!isloadingAnalises && (
                    <Typography variant="h3" fontWeight={"300"} color={"#8514D9"}>
                      {countAnalisys}
                    </Typography>
                  )}
                  {isloadingAnalises && (
                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                      Loading
                    </Typography>
                  )}
                  <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                    Análises
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid container item sm={12} md={4}>
              <Card sx={{ padding: "2px", boxShadow: "none", width: "100%" }}>
                <CardContent sx={{ textAlign: "center" }}>
                  {!isloadingSinistros && (
                    <Typography variant="h3" fontWeight={"300"} color={"red"}>
                      {countSinister}
                    </Typography>
                  )}
                  {isloadingSinistros && (
                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                      Loading
                    </Typography>
                  )}
                  <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                    Sinistros
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <ProfileGuard requiredRole={PermissionRolesEnum.AnalysisOptions} requiredAction={PermissionActionsEnum.View}>
            <Grid container item sm={12}
              sx={{ borderRadius: "6px" }}
              display={"flex"} flexDirection={smDown ? 'column' : 'row'}
              component={Paper}
              padding={2}
            >
              <Grid container item padding={1}
                display={'flex'} flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Grid item >
                  <Typography variant="subtitle1" fontWeight={"bold"} color={"primary"}>
                    Análises
                  </Typography>
                </Grid>
                <Grid item display={"flex"} flexDirection={'row'} gap={2} >

                  <ProfileGuard requiredRole={PermissionRolesEnum.ChartsOptions} requiredAction={PermissionActionsEnum.View}>
                    <Button
                      color="primary"
                      disableElevation
                      variant="text"
                      onClick={() => navigate('/dashboard/graficos')}
                      sx={{ textDecoration: "underline" }}
                    >Ver gráfico</Button>
                  </ProfileGuard>

                  <ProfileGuard requiredRole={PermissionRolesEnum.AnalysisOptions} requiredAction={PermissionActionsEnum.Add}>
                    <Button
                      color="primary"
                      disableElevation
                      variant="contained"
                      onClick={() => navigate('/analises/pedido')}
                      startIcon={<Icon>add</Icon>}
                    >Nova Análise</Button>
                  </ProfileGuard>
                </Grid>
              </Grid>
            </Grid>
          </ProfileGuard>

          <ProfileGuard requiredRole={PermissionRolesEnum.WarrantiesOptions} requiredAction={PermissionActionsEnum.View}>
            <Grid container item sm={12}
              sx={{ borderRadius: "6px" }}
              display={"flex"} flexDirection={smDown ? 'column' : 'row'}
              component={Paper}
              padding={2}
              justifyContent={"center"}
              columnGap={3}
            >

              <Grid container item padding={1}
                display={'flex'} flexDirection={'row'}
                justifyContent={'space-between'}
              // sx={{ backgroundColor: "violet" }}
              >
                <Grid item >
                  <Typography variant="subtitle1" fontWeight={"bold"} color={"primary"}>
                    Garantias
                  </Typography>
                </Grid>


                <Grid item display={"flex"} flexDirection={'row'} gap={2} >
                  <Button
                    color="secondary"
                    disableElevation
                    variant="text"
                    onClick={() => navigate('/garantias/')}
                    sx={{ textDecoration: "underline" }}
                  >Ver tudo</Button>
                </Grid>
              </Grid>

              <Grid container item sm={12} md={2}>

                <Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h4" fontWeight={"300"} color={"secondary"}>
                      {/* {row.status === "active" ? row.total.toString() : "0"} */}
                      {garantiasVigentes.toString()}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                      Vigentes
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid container item sm={12} md={2}>
                <Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h4" fontWeight={"300"}>
                      {garantiasaRenovar.toString()}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                      A renovar
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container item sm={12} md={2}>
                <Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h4" fontWeight={"300"} color={"red"}>
                      {garantiasCanceladas.toString()}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                      Canceladas
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container item sm={12} md={2}>
                <Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRight: "1px solid #c1c1c1", borderRadius: "0" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h4" fontWeight={"300"}>
                      {garantiasExpiradas.toString()}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                      Expiradas
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container item sm={12} md={2}>
                <Card sx={{ padding: "2px", boxShadow: "none", width: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h4" fontWeight={"300"} color={"primary"}>
                      {garantiasEmSinistro.toString()}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                      Sinistros
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </ProfileGuard>
        </Grid>


        <Grid container item sm={12} md={4}
          display={"flex"} flexDirection={smDown ? 'column' : 'row'}
          gap={2}
        >

          {(permissionLevel && permissionLevel != 1 && !isSuperAdmin) &&
            (countAnalisysAproved > 0) &&
            (checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.View)) &&
            (
              <Grid container sm={12}
                sx={{ borderRadius: "6px" }}
                display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                component={Paper}
                padding={2}
              >
                <Grid container item padding={1}
                  display={'flex'} flexDirection={'row'}
                  justifyContent={'center'}
                >
                  <Grid item >
                    {/* <IconButton size="small" sx={{ borderRadius: "18px", background: "linear-gradient(45deg, #d4b60d, #a4fd71)", padding: "1rem" }}> */}
                    <IconButton size="small" sx={{ borderRadius: "18px", background: "linear-gradient(45deg, #2b0175, #9580b6)", padding: "1rem" }}>
                      <Typography variant="h5" marginLeft={1} sx={{ color: "#f7fbfd", fontWeight: "normal" }}>
                        OPORTUNIDADE
                      </Typography>
                    </IconButton>
                  </Grid>
                </Grid>
                <Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRadius: "0", textAlign: "center" }}>
                  <Typography variant="h6" sx={{ color: "#555558" }}>
                    Você tem ainda
                  </Typography>
                  <Typography variant="h4" sx={{ color: "#11e457" }}>
                    {countAnalisysAproved}
                    {countAnalisysAproved > 1 ? ' Análises' : ' Análise'}
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#555558" }}>
                    Aguardando Contratação
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#555558" }}>
                    Contratando essas garantias você pode ganhar
                  </Typography>
                  {bonusPlans?.map((bp, index) => {

                    if (bp.isExist) {
                      return (<Typography key={index} variant="h6" fontWeight={"300"} color={"#8514D9"}>
                        Plano {bp.description} : {formatScaleValueBR(String(bp.bonus))}
                      </Typography>)
                    }

                  })
                  }
                  <ProfileGuard requiredRole={PermissionRolesEnum.AnalysisOptions} requiredAction={PermissionActionsEnum.Update}>
                    <Button
                      color="secondary"
                      disableElevation
                      variant="contained"
                      onClick={() => navigate('/analises?busca=&pagina=1&tipo=issued')}
                      startIcon={<Icon>add</Icon>}
                      sx={{ marginTop: "1rem" }}
                    >Contratar Agora</Button>
                  </ProfileGuard>
                </Card>
              </Grid>
            )}

          {(permissionLevel === 1) && (
            <ProfileGuard requiredRole={PermissionRolesEnum.ClientsOptions} requiredAction={PermissionActionsEnum.View}>
              <Grid container item sm={12}
                sx={{ borderRadius: "6px" }}
                display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                component={Paper}
                padding={2}
              >
                <Grid container item padding={1}
                  display={'flex'} flexDirection={'row'}
                  justifyContent={'space-between'}
                >
                  <Grid item >
                    <Typography variant="subtitle1" fontWeight={"bold"} color={"primary"}>
                      Clientes
                    </Typography>
                  </Grid>
                  <Grid item display={"flex"} flexDirection={'row'} gap={2} >
                    <Button
                      color="secondary"
                      disableElevation
                      variant="text"
                      onClick={() => navigate('/clientes/')}
                      sx={{ textDecoration: "underline" }}
                    >Ver tudo</Button>

                    <ProfileGuard requiredRole={PermissionRolesEnum.ClientsOptions} requiredAction={PermissionActionsEnum.Add}>
                      <Button
                        color="primary"
                        disableElevation
                        variant="contained"
                        onClick={() => navigate('/clientes/detalhe/novo')}
                        startIcon={<Icon>add</Icon>}
                      >Novo</Button>
                    </ProfileGuard>
                  </Grid>
                </Grid>
                <ProfileGuard requiredRole={PermissionRolesEnum.ChartsOptions} requiredAction={PermissionActionsEnum.View}>
                  <Card sx={{ padding: "2px", boxShadow: "none", width: "100%", borderRadius: "0" }}>
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="350px"
                      data={dataClientes}
                      options={options}
                    />
                  </Card>
                </ProfileGuard>
              </Grid>
            </ProfileGuard>
          )}
          <Grid container item sm={12}
            borderRadius={6}
            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
            component={Paper}
            height={"auto"}
          >
            <Card sx={{ boxShadow: "none" }}>
              <CardMedia
                component="img"
                height="auto"
                image={publicidade}
                alt="publicidade Garantti"
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </LayoutBaseDePagina >
  );
};