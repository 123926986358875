import { useEffect, useMemo, useState } from "react";
import {
	Box,
	Icon,
	IconButton,
	LinearProgress,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "../../../shared/hooks";
import { Loading, ModalContext, TableReports, ToastContent } from "../../../shared/components";

import {
  analysisService,
  IanalysisList,
} from "../../../shared/services/api/analysis/analysisService";
import { Toolbar, ToolbarGridAnalises } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useDebounce } from "../../../shared/hooks";
import { Environment } from "../../../shared/environment";
import moment from "moment";
import "moment/locale/pt-br";
import { checkByProfileGuard, ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { ListSearchParams } from "../types/list-search-params.type";
import { useProfile } from "../../../context/ProfileContext";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { TFilterFields } from "../../../shared/types/filterfields.type";
import { globalBackground, globalColorText, globalMaskReal, globalTranslateStatusAnalysis } from "../../../shared/utils";
import { addDays, format } from "date-fns";
import { CreditCardOutlined } from "@mui/icons-material";

export const AnalysisListPresentation = () => {
	const filterAnalysis: TFilterFields[] = [
		{ name: "documento", displayName: "Documento" },
		{ name: "nome", displayName: "Nome" },
		{ name: "logradouro", displayName: "Logradouro" },
		{ name: "bairro", displayName: "Bairro" },
		{ name: "cidade", displayName: "Cidade" },
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const navigate = useNavigate();
	const [rows, setRows] = useState<IanalysisList[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const hasViewPermission = useMemo(() => {
		return checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.View);
	}, []);
	const [isReport, setIsReport] = useState(false);
	const toastHandler = useToast();
	const { isSuperAdmin, permissionLevel } = useProfile();
	const [pesquisar, setPesquisar] = useState(true);

	const filterInitialParams: ListSearchParams = useMemo(() => {
		return {
			tipo: "documento", //filterAnalysis[0].name,
			status: "Todos",
			origem: "Todos",
			dataIni: moment().startOf("month").subtract(1, "months").format("YYYY-MM-DD"),
			dataFim: moment().endOf("month").format("YYYY-MM-DD"),
		};
	}, []);

	const busca = useMemo(() => {
		return searchParams.get("busca") || "";
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const tipoAnalise = useMemo(() => {
		return searchParams.get("tipo") || "";
	}, [searchParams]);

	const statusAnalise = useMemo(() => {
		return searchParams.get("status") || "";
	}, [searchParams]);

	const origemAnalise = useMemo(() => {
		return searchParams.get("origem") || "";
	}, [searchParams]);

	const dataIniAnalise = useMemo(() => {
		return searchParams.get("dataini") || "";
	}, [searchParams]);

	const dataFimAnalise = useMemo(() => {
		return searchParams.get("datafim") || ""; //moment().endOf('month').format("YYYY-MM-DD"));
	}, [searchParams]);

	const clientFilter = useMemo(() => {
		return searchParams.get("clientFilter") || "";
	}, [searchParams]);

	const managerId = useMemo(() => {
		return searchParams.get("managerId") || "";
	}, [searchParams]);

	const handleBusca = () => {
		setSearchParams(
			{
				busca,
				pagina: String(1),
				tipo: tipoAnalise,
				status: statusAnalise,
				origem: origemAnalise,
				dataini: dataIniAnalise,
				datafim: dataFimAnalise,
				clientFilter: clientFilter,
				managerId,
			},
			{ replace: true }
		);
		setPesquisar(!pesquisar);
	};

	const handleReport = () => {
		setIsReport(true);
	};

	useEffect(() => {
		setSearchParams(
			{
				...searchParams,
				dataini: filterInitialParams.dataIni,
				datafim: filterInitialParams.dataFim,
				tipo: filterInitialParams.tipo,
				status: filterInitialParams.status,
				origemAnalise: filterInitialParams.origem,
			},
			{ replace: true }
		);
	}, []);

	useEffect(() => {
		if (hasViewPermission) {
			setIsLoading(true);
			debounce(() => {
				analysisService
					.getAll(pagina, busca, tipoAnalise, origemAnalise, statusAnalise, dataIniAnalise, dataFimAnalise, clientFilter, managerId)
					.then((result) => {
						setIsLoading(false);
						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							setTotalCount(result.totalCount);
							setRows(result.data);
						}
					})
					.catch((err: any) => {
						setIsLoading(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			});
		}
	}, [pagina, statusAnalise, pesquisar]);

	const URL = window.location.protocol + "//" + window.location.host;

	return (
		<LayoutBaseDePagina
			titulo="Análises"
			subtitulo="Listagem de análises"
			barraDeFerramentas={
				<Toolbar
					filterInitialParams={filterInitialParams}
					filterFieldJson={filterAnalysis}
					textoButtonNew="Nova Análise"
					showInputBusca={true}
					showButtonSearch={false}
					onClickButtonSearch={handleBusca}
					showFilterField={true}
					showButtonReport={checkByProfileGuard(PermissionRolesEnum.ReportsOptions, PermissionActionsEnum.Filter)}
					onClickButtonReport={() => handleReport()}
					onChangeFilterField={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: retorno,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId,
							},
							{ replace: true }
						)
					}
					onChangeTextBuscaGrid={(texto) => {
						setSearchParams(
							{
								busca: texto?.replace("(Inativo)", "")?.trim(),
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId,
							},
							{ replace: true }
						);
					}}
					onClickNovo={() => navigate("/analises/pedido")}
					showInputClientFilter={checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Filter)}
					showInputOrigem={checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Filter)}
					showPeriodo={checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Filter)}
					showButtonNew={checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Add)}
					showInputStatus={false}
					onChangeStatus={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: retorno,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId,
							},
							{ replace: true }
						)
					}
					onChangeOrigem={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: retorno,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise ? dataFimAnalise : moment().endOf("month").format("YYYY-MM-DD"),
								clientFilter: clientFilter,
								managerId,
							},
							{ replace: true }
						)
					}
					onChangeDataIni={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: retorno,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId,
							},
							{ replace: true }
						)
					}
					onChangeDataFim={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise ? dataIniAnalise : moment().startOf("month").format("YYYY-MM-DD"),
								datafim: retorno,
								clientFilter: clientFilter,
								managerId,
							},
							{ replace: true }
						)
					}
					onChangeClientFilter={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: retorno,
								managerId,
							},
							{ replace: true }
						)
					}
					showInputManagerFilter={isSuperAdmin || permissionLevel == 2}
					onChangeManagerFilter={(retorno: string) => {
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoAnalise,
								status: statusAnalise,
								origem: origemAnalise,
								dataini: dataIniAnalise,
								datafim: dataFimAnalise,
								clientFilter: clientFilter,
								managerId: retorno,
							},
							{ replace: true }
						);
					}}
				/>
			}
		>
			<ProfileGuard requiredRole={PermissionRolesEnum.AnalysisOptions} requiredAction={PermissionActionsEnum.View}>
				<TableContainer
					component={Paper}
					variant="outlined"
					sx={{
						marginLeft: 1,
						marginTop: 1,
						marginRight: 2,
						width: "auto",
						padding: 4,
					}}
				>
					<Table aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell colSpan={7}>
									<ToolbarGridAnalises
										textSearchGrid={busca}
										onChangeTextBuscaGrid={(texto) => setSearchParams({ busca: texto, pagina: "1" }, { replace: true })}
										showInputBuscaGrid={false}
										onClickTodos={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoAnalise,
													status: "Todos",
													origem: origemAnalise,
													dataini: dataIniAnalise,
													datafim: dataFimAnalise,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickPreAprovadas={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoAnalise,
													status: "pre_approved",
													origem: origemAnalise,
													dataini: dataIniAnalise,
													datafim: dataFimAnalise,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickAprovadas={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoAnalise,
													status: "issued",
													origem: origemAnalise,
													dataini: dataIniAnalise,
													datafim: dataFimAnalise,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickExpiradas={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoAnalise,
													status: "expired",
													origem: origemAnalise,
													dataini: dataIniAnalise,
													datafim: dataFimAnalise,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickRecusadas={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoAnalise,
													status: "refused",
													origem: origemAnalise,
													dataini: dataIniAnalise,
													datafim: dataFimAnalise,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickPendentes={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoAnalise,
													status: "awaiting_signature",
													origem: origemAnalise,
													dataini: dataIniAnalise,
													datafim: dataFimAnalise,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickBiometria={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoAnalise,
													status: "awaiting_biometria",
													origem: origemAnalise,
													dataini: dataIniAnalise,
													datafim: dataFimAnalise,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										onClickCancel={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoAnalise,
													status: "canceled",
													origem: origemAnalise,
													dataini: dataIniAnalise,
													datafim: dataFimAnalise,
													clientFilter: clientFilter,
													managerId,
												},
												{ replace: true }
											)
										}
										selectTodos={statusAnalise === "Todos"}
										selectPreAprovada={statusAnalise === "pre_approved"}
										selectAprovada={statusAnalise === "issued"}
										selectExpirada={statusAnalise === "expired"}
										selectRecusada={statusAnalise === "refused"}
										selectPendente={statusAnalise === "awaiting_signature"}
										selectBiometria={statusAnalise === "awaiting_biometria"}
										selectCancel={statusAnalise === "canceled"}
									/>
								</TableCell>
							</TableRow>

							<TableRow sx={{ background: "#f5fbfd" }}>
								<TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Parceiro</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Endereço</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Status </TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Cadastro</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Origem</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!isLoading &&
								rows.map((row) => (
									<TableRow key={row.id} hover>
										<TableCell sx={{ fontWeight: "normal" }}>
											{row.name}
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.document.length > 11
													? row.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
													: row.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
												{row.client?.name}
												{row.client?.status == "inactive" && " (Inativo)"}
											</Typography>
										</TableCell>

										<TableCell
											sx={{
												color: "#959595",
												fontWeight: "normal",
											}}
										>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.addressStreet != null && row.addressStreet != ""
													? `${
															row.addressComplement != null && row.addressComplement != ""
																? `${row.addressStreet} , ${row.addressNumber} - ${row.addressComplement} - ${row.addressDistrict}`
																: `${row.addressStreet} , ${row.addressNumber} - ${row.addressDistrict}`
													  }`
													: "Endereço não cadastrado"}
											</Typography>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.addressCity != null && row.addressCity != "" ? `${row.addressCity} - ${row.addressState}` : ""}
											</Typography>
										</TableCell>
										<TableCell sx={{ textAlign: "start" }}>
											<IconButton
												size="small"
												sx={{
													background: globalBackground(row.status),
													borderRadius: "18px",
													paddingX: "15px",
													paddingY: "1px",
													marginBottom: "5px",
												}}
											>
												<Icon
													sx={{
														color: globalColorText(row.status),
														fontWeight: "normal",
													}}
												>
													{" "}
													noise_control_off_sharp_icon
												</Icon>
												<Typography
													variant="subtitle2"
													marginLeft={1}
													sx={{
														color: globalColorText(row.status),
														fontWeight: "normal",
														display: "flex",
														flexGrow: "initial",
													}}
												>
													{globalTranslateStatusAnalysis(row.status)}
												</Typography>
											</IconButton>

											<Typography variant="subtitle2" color={globalColorText(row.status)} fontWeight={"normal"}>
												{row.status == "issued" || row.status == "awaiting_signature" || row.status == "awaiting_biometria"
													? `expira
                                         ${
												row.expiresSignature
													? format(new Date(row.expiresSignature), "dd/MM/yyyy HH:mm:ss")
													: format(addDays(new Date(row.createdAt), 30), "dd/MM/yyyy HH:mm:ss")
											} `
													: ""}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												variant="subtitle2"
												color={"#959595"}
												fontWeight={"normal"}
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
												}}
											>
												{row.setup_amount != null && (
													<Tooltip title={`Taxa de setup R$  ${globalMaskReal(row?.setup_amount)}`}>
														<CreditCardOutlined />
													</Tooltip>
												)}

												{row?.createdAt
													? //? moment(row.createdAt).format("DD/MM/yyyy HH:mm:ss")
													  format(new Date(row.createdAt), "dd/MM/yyyy HH:mm:ss")
													: "Não informado"}
											</Typography>
											<Typography variant="subtitle2" color={globalColorText(row.status)} fontWeight={"normal"}>
												{/* {moment.(row.createdAt).startOf('minute').fromNow()} */}
											</Typography>
										</TableCell>
										<TableCell sx={{ textAlign: "start" }}>
											<IconButton
												size="small"
												sx={{
													borderRadius: "18px",
													paddingX: "15px",
													paddingY: "1px",
													marginBottom: "5px",
												}}
											>
												{row.createdFrom == "web_app" ? (
													<Icon
														sx={{
															color: "#959595",
															fontWeight: "normal",
														}}
													>
														{" "}
														computer
													</Icon>
												) : (
													<Icon
														sx={{
															color: "#959595",
															fontWeight: "normal",
														}}
													>
														{" "}
														cloud
													</Icon>
												)}
											</IconButton>
										</TableCell>
										<TableCell sx={{ textAlign: "center" }}>
											<IconButton
												size="small"
												onClick={() => window.open(`${URL}/analises/visualizar/${row.id}`)}
												sx={{ borderRadius: "18px" }}
											>
												<Icon
													sx={{
														color: "#0170B3",
														fontWeight: "normal",
													}}
												>
													{" "}
													search
												</Icon>
												<Typography
													variant="subtitle2"
													marginLeft={1}
													sx={{
														color: "#0170B3",
														fontWeight: "normal",
													}}
												>
													Visualizar
												</Typography>
											</IconButton>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
						{totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}
						<TableFooter>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={3}>
										<LinearProgress variant="indeterminate" />
									</TableCell>
								</TableRow>
							)}

							{totalCount > 0 && (
								<TableRow>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Pagination
											page={pagina}
											count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
											onChange={(_, newPage) =>
												setSearchParams(
													{
														busca,
														pagina: newPage.toString(),
														tipo: tipoAnalise,
														status: statusAnalise,
														origem: origemAnalise,
														dataini: dataIniAnalise,
														datafim: dataFimAnalise,
														clientFilter: clientFilter,
														managerId,
													},
													{ replace: true }
												)
											}
										/>
									</TableCell>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Typography variant="body1" sx={{ fontWeight: "normal" }}>
											Exibindo {pagina * Environment.LIMITE_LINHAS - (Environment.LIMITE_LINHAS - 1)}
											{" - "}
											{Environment.LIMITE_LINHAS * (pagina - 1) + Environment.LIMITE_LINHAS * pagina < totalCount
												? Environment.LIMITE_LINHAS * pagina
												: totalCount}{" "}
											de
											<Typography
												variant="overline"
												sx={{
													fontWeight: "bold",
													color: "#0170B3",
													fontSize: "16px",
												}}
											>
												{" "}
												{totalCount}{" "}
											</Typography>
											registro(s)
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Table>
				</TableContainer>

				{isReport && (
					<ModalContext
						isCloseModal={() => setIsReport(false)}
						isOpenModal={isReport}
						messageType={"alert"}
						showButtonCancel={true}
						textButtonCancel={"Voltar"}
						width="75%"
						showTitleIcon={false}
						ITitle={"Relatórios Solicitados"}
						IMessage={[
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
								}}
							>
								<TableReports actionDownload={() => {}} actionView={() => {}} isloading={false} showDownload={true} showView={false} />
							</Box>,
						]}
					/>
				)}
			</ProfileGuard>
		</LayoutBaseDePagina>
	);
};
