// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes loadingImg {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.animatingImg {
    animation: loadingImg 1s linear infinite;
}

@keyframes backGround-color {
    0% {
        background-color: lightgray;
    }

    50.0% {
        background-color: gray;
    }

    100.0% {
        background-color: darkgray;
    }
}

.backGround-color-change {
    animation: backGround-color 1s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/loading2/Loading.css"],"names":[],"mappings":"AAAA;IACI;QACI,oBAAoB;IACxB;;IAEA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI;QACI,2BAA2B;IAC/B;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI,8CAA8C;AAClD","sourcesContent":["@keyframes loadingImg {\n    from {\n        transform: rotate(0);\n    }\n\n    to {\n        transform: rotate(360deg);\n    }\n}\n\n.animatingImg {\n    animation: loadingImg 1s linear infinite;\n}\n\n@keyframes backGround-color {\n    0% {\n        background-color: lightgray;\n    }\n\n    50.0% {\n        background-color: gray;\n    }\n\n    100.0% {\n        background-color: darkgray;\n    }\n}\n\n.backGround-color-change {\n    animation: backGround-color 1s linear infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
