import { useEffect, useState } from "react";
import {
    Typography, Box, useTheme, useMediaQuery, Button, Grid, Paper, TextField, Icon, Tooltip, IconButton
} from "@mui/material";
import { FinancialService, IListInvoice, ISummary } from "../../../shared/services/api/financial/FinancialService";
import { useDebounce } from "../../../shared/hooks";
import moment from "moment";
import 'moment/locale/pt-br';
import { useToast } from '../../../shared/hooks';
import { VTextField } from "../../../shared/forms";
import { useParams } from "react-router-dom";
import logo from "../../../assets/logo_extrato.png";
import { useAuthContext, useDrawerContext } from "../../../context";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import InputMask from "react-input-mask";
import { borderColor, fontSize } from "@mui/system";
import { Loading, ToastContent } from "../../../shared/components";
import { Environment } from "../../../shared/environment";
import { endOfMonth, format } from "date-fns";



function colorLine(parcela: number) {
    if (parcela % 2 == 0) {
        return '#e8ebee'
    }
    return '#ffffff';
}



const maskCNPJ = (value: string) => {
    let cell = value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    if (cell.length < 1) {
        cell = " ";
    }
    return cell
};

export const SicoobBoletoPresentation = () => {
    const { debounce } = useDebounce();
    const [rows, setRows] = useState<IListInvoice>();
    const [dueDate, setDueDate] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const [client, setClient] = useState('');
    const [document, setDocument] = useState('');
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const toastHandler = useToast();
    const { _, id } = useParams();
    const [valid, setValid] = useState(false);
    const [validador, setValidador] = useState('');



    useEffect(() => {
        setIsLoading(true);
        debounce(() => {
            if (valid) {
                FinancialService.getSummary(`${id}`)
                    .then((result) => {
                        
                        setIsLoading(false);
                        if (result instanceof Error) {
                            alert(result.message);
                        } else {
                            console.log(result);
                            // setTotalCount(parseInt(result.totalCount));
                            // setRows(result.data);
                            if(result.data.length > 0){
                                setClient(result.data[0].client_name);
                                setDocument(result?.data[0].client_document);
                            }
                        }
                    });

                FinancialService.getInvoiceById(`${id}`)
                    .then((result) => {
                        
                        setIsLoading(false);
                        if (result instanceof Error) {
                            alert(result.message);
                        } else {
                            // setTotalCount(parseInt(result.totalCount));
                            setRows(result);
                            setDueDate(result.dueDate);
                            // setClient(result.data[0].client_name);
                            // setDocument(result.data[0].client_document);
                        }
                    });
            }
        });
    }, [valid]);


    // const resultado = rows.reduce(function (a: number, b: any) {
    //     return a + (b.Installment_type_amount != 'discount' ? parseFloat(b.Installment_amount) : 0);
    // }, 0);

    // const discount = rows.reduce(function (a: number, b: any) {
    //     return a + (b.Installment_type_amount == 'discount' ? parseFloat(b.Installment_amount) : 0);
    // }, 0);

    // const saldo = resultado - discount;


    const Input = (props: any) => (
        <InputMask mask="99.999.999/9999-99" value={props.value} onChange={props.onChange} />
    );

    const handleValidarDocument = (value: string) => {
        const documento = value.replace(/[^0-9]/g, "")
        //const ur= 'https://app.garantti.com.br/invoice/valid/'
        const uri = Environment.URL_BASE;
        const ur = `${uri}/invoice/valid/`
        fetch(`${ur}${id}/${documento}`)
            .then(res => res.json())
            .then(data => {
                
                if (data.status == "active") {
                    setValid(true);
                } else {
                    const messageTitleNao = 'Erro na pesquisa'
                    const messageContentNao = 'Documento inválido para abrir o extrato.'
                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={messageTitleNao} content={messageContentNao} />
                    })
                }
            })
            .catch(err => {
                
                const messageTitle = 'Erro na pesquisa'
                const messageContent = 'Erro no processo de busca.'

                toastHandler.present({
                    type: 'error',
                    position: 'top-right',
                    messageOrContent: <ToastContent title={messageTitle} content={messageContent} />
                })
            })
            ;
    }

    const handleExtractPDF = (IID: string) => {
        setIsLoading(true);
        debounce(() => {  //Rogerio vai mexer aqui
            FinancialService.getInvoicesBoletoPDF(IID)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                    } else {
                        //setModalDetail(true)
                    }
                });
        });
    }


    return (
            valid ?

                <>
                    <Box margin={1}
                        display="flex"
                        flexDirection="column"
                        component={Paper}
                        variant="outlined"
                        marginX={smDown ? 2 : '20%'}

                    >
                        <Grid container item direction="column" padding={2} spacing={2}>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} md={2} >
                                    <Box position={"absolute"}>
                                        <img src={smDown ? '' : logo} alt="logo" width={'50%'} />
                                    </Box>
                                </Grid>
                                <Grid item xs={7}  >
                                    <Typography variant="h6" color={'secondary'} textAlign={"center"}>
                                        Download de Boletos Sicoob
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'} textAlign={"center"}>
                                        {client}
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} >
                                    <Typography variant="body1" color={'primary'} textAlign={"center"}>

                                        {document.length > 11 ?
                                            `${document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                                            :
                                            `${document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} >
                                    <Typography variant="body1" color={'#7924c7'} textAlign={"center"}>
                                        {

                                            `${rows ?

                                                rows?.status == 'paid' ?
                                                    'Boleto Pago - Data :' + format(new Date(rows?.paymentDate + ' 03:00:00'), 'dd/MM/yyyy')
                                                    :
                                                    ' Boleto em Aberto - Vencimento : ' + format(new Date(dueDate), 'dd/MM/yyyy')
                                                : ''}`
                                        }
                                    </Typography>
                                    <Typography variant="body1" color={'#7924c7'} textAlign={"center"}>
                                        {

                                            `${rows ?
                                                rows?.status == 'paid' ? ' '
                                                    :
                                                    'Data Limite    para Pagamento  : ' + format(endOfMonth(new Date(dueDate)), 'dd/MM/yyyy')
                                                : ''}`
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} textAlign={"center"} >
                                    <Tooltip title="Boleto Sicoob Pdf" placement="top-start">
                                        <IconButton size="medium"
                                            onClick={() => handleExtractPDF(`${id}`)}
                                            sx={{ borderRadius: "18px", color: "#7924c7" }}
                                        >
                                            <Icon sx={{ color: "#7924c7", fontWeight: "normal", mr: '10px' }}> picture_as_pdf_outlined_Icon</Icon>
                                            Baixar Boleto
                                        </IconButton>
                                    </Tooltip>
                                </Grid>


                            </Grid>
                        </Grid>
                    </Box>

                </> :
                <>
                    <Box margin={1}
                        display="flex"
                        flexDirection="column"
                        component={Paper}
                        variant="outlined"
                        marginX={smDown ? 2 : '20%'}

                    >
                        <Grid container item direction="column" padding={2} spacing={2}>
                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} md={2} >
                                    <Box position={"absolute"}>
                                        <img src={smDown ? '' : logo} alt="logo" width={'50%'} />
                                    </Box>
                                </Grid>
                                <Grid item xs={7}  >
                                    <Typography variant="h6" color={'secondary'} textAlign={"center"}>
                                        Validação de Boleto Sicoob
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'} textAlign={"center"}>
                                        Informe o documento de validação
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={2} justifyContent={"center"} marginTop={2}>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                    <InputMask
                                        mask={"99.999.999/9999-99"}
                                        style={{
                                            fontSize: '18px',
                                            border: '1px solid #c1c2c1',
                                            color: "#808486",
                                            padding: 6,
                                            paddingLeft: '16px',
                                            borderRadius: '4px',
                                            fontWeight: "100",
                                            fontFamily: 'roboto'
                                        }}
                                        placeholder='CNPJ'
                                        onChange={(e) => setValidador(e.target.value)}
                                    >
                                    </InputMask>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={2} justifyContent={"center"} marginTop={1}>
                                <Button
                                    color="secondary"
                                    disableElevation
                                    variant="outlined"
                                    onClick={() => handleValidarDocument(validador)

                                    }
                                    startIcon={<Icon>check</Icon>}
                                >
                                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                                        Confirmar
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </>
    );
};