import { Autocomplete, CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useField } from "@unform/core";
import { useEffect, useMemo, useState } from "react";
import { string } from "yup";
import { useDebounce } from "../../hooks";
import { clientService } from "../../services/api/client/clientService";

type TAutoCompleteOption = {
    id: string;
    label: string;
}
interface IAutoCompleteClientProps {
    isExternalLoading?: boolean;
    labelSelect?: string;
    namecampo?: string;
    disabled?: boolean;
    idSelected?: (text: string) => void;
    nameDefault?: string;
    clientDefault?:string;

}

export const AutoCompleteClient: React.FC<IAutoCompleteClientProps> = ({
    isExternalLoading = false,
    labelSelect = '',
    idSelected,
    disabled = false,
    nameDefault='',
    clientDefault=''

}) => {

    const { fieldName, registerField, defaultValue, error, clearError } = useField('clientId');
    const { debounce } = useDebounce();
    const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

    const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [busca, setBusca] = useState('');

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedId,
            setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
        });
        setIsEdit(true)
    }, [registerField, fieldName, selectedId]);


    useEffect(() => {
        setIsLoading(true);
        debounce(() => {
  //          console.log('busca', !isEdit,nameDefault,busca)
            clientService.getAll(1, isEdit == true? nameDefault: busca, "", "", "active")
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                    } else {
                        setOpcoes(result.data.map(client => ({ id: client.id, label: client.name })));
                      
                    }
                });
        });
    }, [busca,nameDefault]);

    const autoCompleteSelectedOption = useMemo(() => {
        if (!selectedId) return null;
        const selectedOption = opcoes.find(opcao => opcao.id === selectedId);
        if (!selectedOption) return null;
        return selectedOption
    }, [selectedId, opcoes]);

    return (
        <Autocomplete
            openText="Abrir"
            closeText="Fechar"
            noOptionsText="Nenhuma opção"
            loadingText="Loading ..."
            disablePortal
            value={autoCompleteSelectedOption}
            options={opcoes}
            loading={isLoading}
            size={'small'}
            fullWidth

            disabled={isExternalLoading || disabled}
            onInputChange={(_, newValue) => {setIsEdit(false);setBusca(newValue);}}
            onChange={(_, newValue) => { setSelectedId(newValue?.id); clearError(); }}
            popupIcon={isExternalLoading || isLoading ? <CircularProgress size={28} /> : undefined}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={labelSelect}
                    error={!!error}
                    helperText={error}
                    disabled={disabled}
                    fullWidth
                />
            )}
        />
    )
};