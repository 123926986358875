export const globalTranslate = (
  traduzir: string,
  personalKey?: string
): string => {
  if (traduzir === 'in_progress' && personalKey == 'sinister') {
    return 'Em análise';
  } else if (traduzir === 'in_progress') {
    return 'Em Andamento';
  }

  if (personalKey == 'update-proposal') {
    if (traduzir === 'period') {
      return 'Vigência';
    }
    if (traduzir === 'remt_amount') {
      return 'Valores';
    }
    if (traduzir === 'locate') {
      return 'Endereço';
    }
    if (traduzir === 'lessor') {
      return 'proprietário';
    }
    if (traduzir === 'other') {
      return 'outros';
    }
  }

  if (traduzir === 'created') {
    return 'Aberto';
  }
  if (traduzir === 'finalized') {
    return 'Finalizado';
  }
  if (traduzir === 'pending') {
    return 'Pendente';
  }
  if (traduzir === 'canceled') {
    return 'Cancelado';
  }
  if (traduzir === 'approved') {
    return 'Aprovado';
  }
  if (traduzir === 'according') {
    return 'Em Acordo';
  }
  if (traduzir === 'contested') {
    return 'Contestado';
  }
  if (traduzir === 'inactive') {
    return 'Inativo';
  }
  if (traduzir === 'active') {
    return 'Ativo';
  }
  if (traduzir === 'blocked') {
    return 'Bloquedo';
  }

  if (traduzir === 'web_app') {
    return 'Aplicação Garantti';
  }
  if (traduzir === 'web_service') {
    return 'Integração';
  }

  if (traduzir === 'single') {
    return 'Solteiro';
  }
  if (traduzir === 'married') {
    return 'Casado';
  }
  if (traduzir === 'widower') {
    return 'Viúvo';
  }
  if (traduzir === 'divorced') {
    return 'Divorciado';
  }
  if (traduzir === 'stable_union') {
    return 'União estável';
  }

  if (traduzir === 'retired') {
    return 'Aposentado / Pensionista';
  }
  if (traduzir === 'freelance') {
    return 'Autônomo';
  }
  if (traduzir === 'privateServant') {
    return 'Funcionário CLT';
  }
  if (traduzir === 'private_Servant') {
    return 'Funcionário CLT';
  }
  if (traduzir === 'private_servant') {
    return 'Funcionário CLT';
  }
  if (traduzir === 'liberal') {
    return 'Empresário / Liberal';
  }
  if (traduzir === 'student') {
    return 'Estudante';
  }
  if (traduzir === 'civilServant') {
    return 'Funcionário Público';
  }
  if (traduzir === 'civil_servant') {
    return 'Funcionário Público';
  }
  if (traduzir === 'civil_Servant') {
    return 'Funcionário Público';
  }
  if (traduzir === 'rentIncome') {
    return 'Proveniente de Aluguel';
  }
  if (traduzir === 'entrepreneur') {
    return 'Empreendedor';
  }

  if (traduzir === 'commercial') {
    return 'Comercial';
  }
  if (traduzir === 'residential') {
    return 'Residencial';
  }

  if (traduzir === 'billet') {
    return 'Boleto';
  }
  if (traduzir === 'billet_rent') {
    return 'Boleto de Aluguel';
  }
  if (traduzir === 'credit_card') {
    return 'Cartão de crédito';
  }

  if (traduzir === 'busy') {
    return 'Ocupado';
  }
  if (traduzir === 'not_busy') {
    return 'Desocupado';
  }

  if (traduzir === 'open') {
    return 'Aberto';
  }
  if (traduzir === 'analysis') {
    return 'Em análise';
  }
  if (traduzir === 'accepted') {
    return 'Aceito';
  }
  if (traduzir === 'refused') {
    return 'Recusado';
  }
  if (traduzir === 'provision') {
    return 'Provisionado';
  }
  if (traduzir === 'provisioned') {
    return 'Provisionado';
  }
  if (traduzir === 'paid') {
    return 'Pago';
  }

  if (traduzir === 'debts_confession') {
    return 'Confissão de débitos';
  }
  if (traduzir === 'monthly_compensation') {
    return 'Indenizado Mensalmente';
  }
  if (traduzir === 'lmi_exhaustion') {
    return 'Esgotamento de LMI';
  }
  if (traduzir === 'sketch') {
    return 'Rascunho';
  }

  if (traduzir === 'rent_values') {
    return 'Aluguel';
  }
  if (traduzir === 'condominium_values') {
    return 'Condomínio';
  }
  if (traduzir === 'water_values') {
    return 'Água';
  }
  if (traduzir === 'energy_values') {
    return 'Energia';
  }
  if (traduzir === 'gas_values') {
    return 'Gás';
  }
  if (traduzir === 'damage') {
    return 'Danos ao imóvel - Reparos';
  }
  if (traduzir === 'painting') {
    return 'Danos ao imóvel - Pintura';
  }
  if (traduzir === 'fine_on_rent') {
    return 'Multa sobre Aluguel';
  }
  if (traduzir === 'fine_on_condominium') {
    return 'Multa sobre valor de condomínio';
  }
  if (traduzir === 'garantti_tax') {
    return 'Taxa mensal Garantti';
  }
  if (traduzir === 'other') {
    return 'Outros';
  }
  if (traduzir === 'fire_insurance') {
    return 'Apólice seguro incêndio';
  }

  if (traduzir === 'damage') {
    return 'Danos ao imóvel - Reparos';
  }
  if (traduzir === 'painting') {
    return 'Danos ao imóvel - Pintura';
  }
  if (traduzir === 'fine_on_rent') {
    return 'Multa sobre Aluguel';
  }
  if (traduzir === 'fine_on_condominium') {
    return 'Multa sobre valor de condomínio';
  }
  if (traduzir === 'garantti_tax') {
    return 'Taxa mensal Garantti';
  }
  if (traduzir === 'other') {
    return 'Outros';
  }
  if (traduzir === 'fire_insurance') {
    return 'Apólice seguro incêndio';
  }

  if (traduzir === 'awaiting_signature') {
    return 'Assinatura Pendente';
  }
  if (traduzir === 'awaiting_biometria') {
    return 'Autenticação Pendente';
  }
  if (traduzir === 'in_progress') {
    return 'Em andamento';
  }
  if (traduzir === 'expired') {
    return 'Expirada';
  }
  if (traduzir === 'issued') {
    return 'Aprovada';
  }
  if (traduzir === 'pending') {
    return 'Pendente';
  }
  if (traduzir === 'refused') {
    return 'Recusada';
  }
  if (traduzir === 'canceled') {
    return 'Cancelada';
  }
  if (traduzir === 'residential') {
    return 'Residencial';
  }
  if (traduzir === 'commercial') {
    return 'Comercial';
  }
  if (traduzir === 'web_app') {
    return 'Aplicação Garantti';
  }
  if (traduzir === 'web_service') {
    return 'Integração';
  }
  if (traduzir === 'single') {
    return 'Solteiro';
  }
  if (traduzir === 'married') {
    return 'Casado';
  }
  if (traduzir === 'widower') {
    return 'Viúvo';
  }
  if (traduzir === 'divorced') {
    return 'Divorciado';
  }
  if (traduzir === 'stable_union') {
    return 'União estável';
  }
  if (traduzir === 'retired') {
    return 'Aposentado / Pensionista';
  }
  if (traduzir === 'freelance') {
    return 'Autônomo';
  }
  if (traduzir === 'privateServant') {
    return 'CLT';
  }
  if (traduzir === 'private_servant') {
    return 'CLT';
  }
  if (traduzir === 'liberal') {
    return 'Empresário / Liberal';
  }
  if (traduzir === 'student') {
    return 'Estudante';
  }
  if (traduzir === 'civilServant') {
    return 'Funcionário Público';
  }
  if (traduzir === 'civil_servant') {
    return 'Funcionário Público';
  }
  if (traduzir === 'rentIncome') {
    return 'Proveniente de Aluguel';
  }
  if (traduzir === 'rent_income') {
    return 'Proveniente de Aluguel';
  }
  if (traduzir === 'entrepreneur') {
    return 'Empreendedor';
  }
  if (traduzir === 'hired') {
    return 'Contratada';
  }

  if (traduzir === 'period') {
    return 'Contratada';
  }
  if (traduzir === 'hired') {
    return 'Contratada';
  }
  if (traduzir === 'hired') {
    return 'Contratada';
  }

  return traduzir;
};

export const globalTranslateStatus = (
  traduzir: string,
  personalKey?: string
): string => {
  if (traduzir === 'expired') {
    return 'Expirada';
  }
  if (traduzir === 'active') {
    return 'Vigente';
  }
  if (traduzir === 'suspended') {
    return 'Suspensa';
  }
  if (traduzir === 'renovated') {
    return 'Renovada';
  }
  if (traduzir === 'canceled') {
    return 'Cancelada';
  }
  if (traduzir === 'pending') {
    return 'Pendente';
  }
  if (traduzir === 'paid') {
    return 'Pago';
  }
  if (traduzir === 'not_paid') {
    return 'Aberta';
  }
  if (traduzir === 'billed') {
    return 'Faturada';
  }
  if (traduzir === 'overdue') {
    return 'Vencido';
  }
  if (traduzir === 'open') {
    return 'Aberto';
  }

  if (traduzir === 'awaiting_signature') {
    return 'Assinatura Pendente';
  }
  if (traduzir === 'awaiting_biometria') {
    return 'Autenticação Pendente';
  }
  if (traduzir === 'in_progress') {
    return 'Em andamento';
  }
  if (traduzir === 'issued') {
    return 'Aprovada';
  }
  if (traduzir === 'refused') {
    return 'Recusada';
  }
  if (traduzir === 'hired') {
    return 'Contratada';
  }
  if (traduzir === 'pre_approved') {
    return 'Pré Aprovada';
  }
  if (traduzir === 'debts_confession') {
    return 'Confissão de dividas';
  }
  if (traduzir === 'finalized') {
    return 'Finalizado';
  }
  if (traduzir === 'monthly_compensation') {
    return 'Indenização Mensal';
  }
  if (traduzir === 'lmi_exhaustion') {
    return 'Esgotamento de LMI';
  }
  if (traduzir === 'sketch') {
    return 'Rascunho';
  }

  return traduzir;
};

export const globalTranslateStatusInvoices = (
  traduzir: string = '',
  personalKey?: string
): string => {
  if (traduzir === 'canceled') {
    return 'Cancelado';
  }
  if (traduzir === 'pending') {
    return 'Faturado';
  }
  if (traduzir === 'paid') {
    return 'Pago';
  }
  //if (traduzir === "not_paid") { return "Aberto"; } //
  if (traduzir === 'open') {
    return 'Aberto';
  }
  if (traduzir === 'billed') {
    return 'Faturado';
  }
  if (traduzir === 'overdue') {
    return 'Vencido';
  }
  return traduzir;
};

export const globalTranslateMaritalStatus = (
  traduzir: string,
  personalKey?: string
): string => {
  if (traduzir === 'single') {
    return 'Solteiro';
  }
  if (traduzir === 'married') {
    return 'Casado';
  }
  if (traduzir === 'widower') {
    return 'Viúvo';
  }
  if (traduzir === 'divorced') {
    return 'Divorciado';
  }
  if (traduzir === 'stable_union') {
    return 'União estável';
  }
  return traduzir;
};

export const globalTranslateSex = (
  traduzir: string,
  personalKey?: string
): string => {
  if (traduzir === 'Female') {
    return 'Mulher';
  }

  if (traduzir === 'Male') {
    return 'Homem';
  }

  if (traduzir === 'Other') {
    return 'Outro';
  }

  return traduzir;
};

export const globalTranslateProfession = (
  traduzir: string,
  personalKey?: string
): string => {
  if (traduzir === 'retired') {
    return 'Aposentado / Pensionista';
  }
  if (traduzir === 'freelance') {
    return 'Autônomo';
  }
  if (traduzir === 'privateServant') {
    return 'Funcionário CLT';
  }
  if (traduzir === 'private_Servant') {
    return 'Funcionário CLT';
  }
  if (traduzir === 'private_servant') {
    return 'Funcionário CLT';
  }
  if (traduzir === 'liberal') {
    return 'Empresário / Liberal';
  }
  if (traduzir === 'student') {
    return 'Estudante';
  }
  if (traduzir === 'civilServant') {
    return 'Funcionário Público';
  }
  if (traduzir === 'civil_servant') {
    return 'Funcionário Público';
  }
  if (traduzir === 'civil_Servant') {
    return 'Funcionário Público';
  }
  if (traduzir === 'rentIncome') {
    return 'Proveniente de Aluguel';
  }
  if (traduzir === 'entrepreneur') {
    return 'Empreendedor';
  }
  return traduzir;
};

export function globalTranslateStatusReports(traduzir: string) {
  if (traduzir === 'in_process') {
    return 'Em andamento';
  }
  if (traduzir === 'available') {
    return 'Disponível';
  }
  if (traduzir === 'expired') {
    return 'Expirado';
  }
  return traduzir;
}

export function globalTranslateStatusTaxSetup(traduzir: string) {
  if (traduzir === 'pending') {
    return 'Aberto';
  }
  if (traduzir === 'paid') {
    return 'Pago';
  }
  if (traduzir === 'not_paid') {
    return 'Aberto';
  }
  if (traduzir === 'canceled') {
    return 'Cancelado';
  }
  if (traduzir === 'billed') {
    return 'Boleto';
  }
  if (traduzir === 'processing') {
    return 'Processando';
  }

  return traduzir;
}

export const globalTranslatePayment = (
  traduzir: string,
  personalKey?: string
): string => {
  if (traduzir === 'billet') {
    return 'Fatura Imobiliária';
  }
  if (traduzir === 'credit_card') {
    return 'Cartão de crédito';
  }
  if (traduzir === 'parcel') {
    return 'Parcela';
  }
  if (traduzir === 'add') {
    return 'Acrescimo';
  }
  if (traduzir === 'discount') {
    return 'Desconto';
  }
  if (traduzir === 'approved') {
    return 'Aprovada';
  }

  return traduzir;
};

export function globalTranslateStatusAnalysis(traduzir: String) {
  if (traduzir === 'awaiting_signature') {
    return 'Assinatura Pendente';
  }
  if (traduzir === 'awaiting_biometria') {
    return 'Pend Biometria';
  }
  if (traduzir === 'in_progress') {
    return 'Em andamento';
  }
  if (traduzir === 'expired') {
    return 'Expirada';
  }
  if (traduzir === 'issued') {
    return 'Aprovada';
  }
  if (traduzir === 'pending') {
    return 'Pendente';
  }
  if (traduzir === 'refused') {
    return 'Recusada';
  }
  if (traduzir === 'canceled') {
    return 'Cancelada';
  }
  if (traduzir === 'pre_approved') {
    return 'Pré-Aprovada';
  }
  if (traduzir === 'hired') {
    return 'Contratada';
  }

  return traduzir;
}
