export const globalMaskDocument = (value: string) => {
  if (!value) return "";

  const formattedValue =
    value.length > 11
      ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
      : value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  return formattedValue;
};

export const globalRemoveMask = (value: string) => {
  if (!value) return "";

  return value
    .replace(/\D/g, "")
    .replace(/[^0-9]/g, "")
    .replace(/(\d{1,2})$/, ".$1");
};


export const globalRemoveSpecialCharacters = (value: string) => {
  if (!value) return "";

  return value.replace(/[^\w\s]/gi, "");
};

export function maskCpf(value: any) {
  if (!value) return "";

  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2");
}
export function maskCnpj(value: any) {
  if (!value) return "";

  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{4})/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})/, "$1-$2");
}
export function unmaskCpfOrCnpj(value: any) {
  if (!value) return "";

  return value.replace(/\D/g, "");
}

export function maskCpfOrCnpj(value: any, lgpd?: any) {
  if (!value) return "";

  if (unmaskCpfOrCnpj(value).length <= 11) {
    if (lgpd)
      return `***${value
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
        .substring(3, 12)}**`;

    return maskCpf(value);
  }
  return maskCnpj(value);
}


export const globalMaskReal = (value: string) => {
  return value
      .replace(/\D/g, '')
      .replace(/(\d{1,2})$/, ',$1')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
};

export const globalRemoveMaskReal = (value: string) => {
  return value
      .replace(/\D/g, '')
      .replace(/[^0-9]/g, "")
      .replace(/(\d{1,2})$/, '.$1')
};