import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, LinearProgress, MenuItem, Modal, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { VForm, VTextField, useVForm } from "../../../shared/forms";
import { FinancialService, IPaymentInvoice } from "../../../shared/services/api/financial/FinancialService";
import moment from "moment";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import { globalRemoveMask } from "../../../shared/utils";

interface IModalPaymentInvocieProps {
  id: string;
  clientName: string;
  dueDate: string;
  valueInvoice: string;
  isOpenModal: boolean;
  isCloseModal?: () => void;
  widthModfy?: string;
  heightModfy?: string;
  enableBackdropClose?: boolean;
  isExecute?: boolean;
  onclickConfirm?: () => void;

}

export const ModalPaymentInvoice: React.FC<IModalPaymentInvocieProps> = ({
  id, clientName, dueDate, valueInvoice, isCloseModal, onclickConfirm,
  isOpenModal, widthModfy, heightModfy, isExecute,
}) => {
  // if(!isOpenModal) return null;

  const [isLoading, setIsLoading] = useState(false);
  const { formRef } = useVForm();
  const toastHandler = useToast();
  const [reasonPayment, setreasonPayment] = useState('');
  const [typePayment, settypePayment] = useState('');
  const [isView, setisView] = useState(false)

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: widthModfy?.trim() ? widthModfy : 'auto',
    height: heightModfy?.trim() ? heightModfy : 'auto',
    minWidth: "650px",
    bgcolor: 'background.paper',
    border: '1px solid #605e5e',
    boxShadow: 24,
    p: 4,
    borderRadius: '6px'
  };

  // useEffect(() => {
  //   if (isExecute) {
  //     console.log('efect execute ', isExecute)
  //     //setisView(true);
  //     //setisView(false);
  //   }
  //   console.log('efect')
  //   //setisView(false);
  // }, [isView]);




  const handlePaymentInvoice = (dados: IPaymentInvoice) => {
    dados.creditAmount = globalRemoveMask(dados.creditAmount);
    console.log(id, 'xx ', dados);
   // isCloseModal

    // window.location.reload()

    //console.log(id,' ',formRef.current?.submitForm())
    //handleclose(dados);

    // FinancialService
    //   .PaymentInvoice(id, { ...dados })
    //   .then((result) => {
    //     setIsLoading(false);
    //     if (result instanceof Error) {
    //       toastHandler.present({
    //         type: 'error',
    //         position: 'top-right',
    //         messageOrContent: <ToastContent title={'Erro'} content={'Erro ao baixar o boleto'} />
    //       })
    //       //setRefreshList(true);
    //     } else {
    //       toastHandler.present({
    //         type: 'success',
    //         position: 'top-right',
    //         messageOrContent: <ToastContent title={'Sucesso'} content={'Boleto baixado com sucesso'} />
    //       })
    //       isCloseModal
    //     }
    //   })
  };


  return (
    <div>
      {isOpenModal &&
        <Modal
          open={isOpenModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} >
            <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CheckCircleOutlineOutlinedIcon style={{ color: '#7924c7', marginBottom: "1rem", fontSize: '3rem' }} />
              <Typography
                id="modal-modal-title"
                variant="h6"
                color={"primary"}
              >
                Baixa manual de Boleto
              </Typography>
            </DialogTitle>
            <DialogContent style={{ display: 'flex', justifyContent: 'center', padding: '2px', margin: 0 }}>
              <Box sx={{ width: '100%' }}>
                <Typography>
                  <VForm 
                   id="formIdconfirm"
                   placeholder="Your placeholder"
                   onPointerEnterCapture={() => console.log("Pointer entered")}
                   onPointerLeaveCapture={() => console.log("Pointer left")}
                  ref={formRef} onSubmit={()=>onclickConfirm}>
                    <Box className="teste" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} >
                      {isLoading && (
                        <Grid item>
                          <LinearProgress variant="indeterminate" />
                        </Grid>
                      )}
                      <Grid container direction="column" padding={1} spacing={2} >
                        <Grid container item direction="row" spacing={1} sx={{ mb: 0 }}>
                          <Grid item xs={2} sx={{ textAlign: "left" }}>
                            Cliente :
                          </Grid>
                          <Grid item xs={8} sx={{ alignItems: "left" }}>
                            <Typography variant="inherit" sx={{ fontWeight: "bold" }} >
                              {clientName}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container item direction="row" spacing={1} sx={{ mb: 2 }}>
                          <Grid item xs={2} sx={{ textAlign: "left" }}>
                            Vencimento :
                          </Grid>
                          <Grid item xs={2} sx={{ alignItems: "left" }}>
                            <Typography variant="inherit" sx={{ fontWeight: "bold" }} >
                              {moment(dueDate).format("L")}
                            </Typography>
                          </Grid>

                          <Grid item xs={2} sx={{ textAlign: "right" }}>
                            Valor :
                          </Grid>
                          <Grid item xs={3} sx={{ alignItems: "left" }}>
                            <Typography variant="inherit" sx={{ fontWeight: "bold" }} >
                              {parseFloat(valueInvoice).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Divider />

                        <Grid container item direction="row" spacing={2} mt={1}>
                          <Grid item xs={4} sx={{ alignItems: "left" }}>
                            <VTextField
                              fullWidth
                              label='Data Pagamento'
                              name="paymentDate"
                              disabled={false}
                              type="date"
                            >
                            </VTextField>
                          </Grid>
                          <Grid item xs={4} sx={{ alignItems: "left" }}>
                            <VMaskTextField
                              fullWidth
                              label='Valor Pago'
                              name="creditAmount"
                              disabled={false}
                              value={parseFloat(valueInvoice).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              mask="real"
                            />
                          </Grid>
                        </Grid>

                        <Grid container item direction="row" spacing={2} mt={1}>
                          <Grid item xs={12} md={8} lg={12} xl={12}>
                            <VTextField
                              fullWidth
                              label='Motivo da Baixa manual'
                              name="canceledType"
                              disabled={isLoading}
                              defaultValue='Outros'
                              select
                              onChange={(e) => settypePayment(e.target.value)}
                            >
                              <MenuItem value={'Erro no sistema - manually'}>Erro no sistema</MenuItem>
                              <MenuItem value={'Pagamento direto - manually'}>Pagamento Direto Garantti</MenuItem>
                              <MenuItem value={'Boleto descontado/ajustado - manually'}>Boleto descontado/ajustado</MenuItem>
                              <MenuItem value={'Outros - manually'}>Outros</MenuItem>
                            </VTextField>
                          </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={2}>
                          <Grid item xs={12}>
                            <VTextField
                              fullWidth
                              label='Descreva o pagamento'
                              name="canceledReason"
                              disabled={isLoading}
                              multiline
                              rows={4}
                              onChange={(e) => setreasonPayment(e.target.value)}
                            >
                            </VTextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      {typePayment != '' ?
                        <Button
                          color="error"
                          disableElevation
                          variant={"contained"}
                          startIcon={< Icon > checkIcon</Icon >}
                          type="submit"
                          //onClick={isView ? onclickConfirm : ()=>{}}
                          onClick={onclickConfirm}
                        >
                          Confirmar
                        </Button> : ''}
                    </Box>
                  </VForm>
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{ display: 'flex', justifyContent: 'center' }}
            >

              <Button
                onClick={isCloseModal}
                color="error"
                disableElevation
                variant={"outlined"}
                startIcon={< Icon > arrow_back_icon</Icon >}
              >
                Voltar
              </Button>
            </DialogActions>
          </Box>

        </Modal>}
    </div>
  );
}