const Weeks = []
const size = 60
for (let i = 1; i <= size; i++) {
  Weeks.push(i)
}

export const globalWeeks = Weeks;

interface IRangeState {
  value: string;
  label: string;
}

export const RangeStates: IRangeState[] = [
  {
    value: "AC",
    label: "Acre"
  },
  {
    value: "AL",
    label: "Alagoas"
  },
  {
    value: "AP",
    label: "Amapá"
  },
  {
    value: "AM",
    label: "Amazonas"
  },
  {
    value: "BA",
    label: "Bahia"
  },
  {
    value: "CE",
    label: "Ceará"
  },
  {
    value: "DF",
    label: "Distrito Federal"
  },
  {
    value: "ES",
    label: "Espírito Santo"
  },
  {
    value: "GO",
    label: "Goiás"
  },
  {
    value: "MA",
    label: "Maranhão"
  },
  {
    value: "MT",
    label: "Mato Grosso"
  },
  {
    value: "MS",
    label: "Mato Grosso do Sul"
  },
  {
    value: "MG",
    label: "Minas Gerais"
  },
  {
    value: "PA",
    label: "Pará"
  },
  {
    value: "PB",
    label: "Paraíba"
  },
  {
    value: "PR",
    label: "Paraná"
  },
  {
    value: "PE",
    label: "Pernambuco"
  },
  {
    value: "PI",
    label: "Piauí"
  },
  {
    value: "RJ",
    label: "Rio de Janeiro"
  },
  {
    value: "RN",
    label: "Rio Grande do Norte"
  },
  {
    value: "RS",
    label: "Rio Grande do Sul"
  },
  {
    value: "RO",
    label: "Rondônia"
  },
  {
    value: "RR",
    label: "Roraima"
  },
  {
    value: "SC",
    label: "Santa Catarina"
  },
  {
    value: "SP",
    label: "São Paulo"
  },
  {
    value: "SE",
    label: "Sergipe"
  },
  {
    value: "TO",
    label: "Tocantins"
  },]


  export const typeProperty: any[] = [
    {
        description: "Apartamento Padrao",
        type: "residential"
    },
    {
        description: "Casa Padrão",
        type: "residential"
    },
    {
        description: "Cobertura",
        type: "residential"
    },
    {
        description: "Kitnete",
        type: "residential"
    },
    {
        description: "Loft",
        type: "residential"
    },
    {
        description: "Casa de Condominio",
        type: "residential"
    },
    {
        description: "Casa de Vila",
        type: "residential"
    },
    {
        description: "Loteamento/Condominio",
        type: "residential"
    },
    {
        description: "Terreno Padrao",
        type: "commercial"
    },
    {
        description: "Chacara",
        type: "commercial"
    },
    {
        description: "Fazenda",
        type: "commercial"
    },
    {
        description: "Sitio",
        type: "commercial"
    },
    {
        description: "Haras",
        type: "commercial"
    },
    {
        description: "Flat/Residence Service",
        type: "commercial"
    },
    {
        description: "Casa Comercial",
        type: "commercial"
    },
    {
        description: "Conjunto Salas",
        type: "commercial"
    },
    {
        description: "Galpao/Deposito/Armazem",
        type: "commercial"
    },
    {
        description: "Industria",
        type: "commercial"
    },
    {
        description: "Hotel/Pousada",
        type: "commercial"
    },
    {
        description: "Loja Shopping",
        type: "commercial"
    },
    {
        description: "Loja",
        type: "commercial"
    },
    {
        description: "Sala Comercial",
        type: "commercial"
    },
    {
        description: "Motel",
        type: "commercial"
    },
    {
        description: "Predio Inteiro",
        type: "commercial"
    },
    {
        description: "Studio",
        type: "commercial"
    },
    {
        description: "Atelie",
        type: "commercial"
    },
    {
        description: "Andar Inteiro",
        type: "commercial"
    },
    {
        description: "Loja Rua",
        type: "commercial"
    }
]