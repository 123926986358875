import { analysisService } from "../services/api/analysis/analysisService";
import { useToast } from '../hooks';
import { ToastContent } from '../../shared/components/';
import { returnMessageError } from "../../shared/utils/returnMessageError";


export const verifyCellPhoneAndCPF = async (document: string, cellphone: string) => {
    const toastHandler = useToast()
    return new Promise<boolean>((resolve) => {
        analysisService.verifyCellPhoneAndCPF(document, cellphone)
            .then((result: any) => {
                console.log('verifyCellPhoneAndCPF:',result )
                if (result instanceof Error) {
                    const titleMessage = "Alerta!!";
                    const contentMessage = "Erro: " + result?.message;

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    });
                    return resolve(true);
                }
                return resolve(false);
            }).catch((err: any) => {
                const { titleMessage, contentMessage } = returnMessageError(err);

                toastHandler.present({
                    type: 'error',
                    position: 'top-right',
                    messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                })

                return resolve(true);
            });
    });
}