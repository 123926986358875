import { Autocomplete, CircularProgress, InputAdornment, Select, TextField } from "@mui/material";
import { useField } from "@unform/core";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "../../hooks";
import { PlanServices } from "../../services/api/plan/PlanServices";

type TAutoCompletePlanOption = {
    id: string;
    label: string;
    lmi?: string;
    residentialFee?: string;
    commercialFee?: string;
    personalized?:string;
}

interface IAutoCompletePlanProps {
    isExternalLoading?: boolean;
    labelSelect?: string;
    namecampo: string;
    clientId?: string;
    onClick:(value:any) => any;
}

export const AutoCompletePlan: React.FC<IAutoCompletePlanProps> = ({
    isExternalLoading = false,
    labelSelect = '',
    clientId,
    onClick,
}) => {

    const { fieldName, registerField, defaultValue, error, clearError } = useField('planId');
    const { debounce } = useDebounce();
    const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);
    const [opcoes, setOpcoes] = useState<TAutoCompletePlanOption[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [busca, setBusca] = useState('');
    // const [selectedPlan, setSelectedPlan] = useState<TAutoCompletePlanOption>();


    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedId,
            setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
        });
    }, [registerField, fieldName, selectedId]);



    useEffect(() => {
        setIsLoading(true);
        debounce(() => {
            PlanServices.getAllNotExists(clientId, busca)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        //alert(result.message);
                    } else {
                        setOpcoes(result.data.map(
                            plano => ({
                                id: plano.id,
                                label: plano.description,
                                lmi: plano.lmi,
                                residentialFee: plano.residentialFee,
                                commercialFee: plano.commercialFee,
                                personalized: plano.personalized,
                                bonusAmount: plano.bonusAmount,
                            })
                        ));
                    }
                });
        });
    }, [busca]);

    const autoCompleteSelectedOption = useMemo(() => {

        if (!selectedId) return null;
        const selectedOption = opcoes.find(opcao => opcao.id === selectedId);

        if (!selectedOption) return null;
        return selectedOption
    }, [selectedId]);



    return (
        <Autocomplete
            openText="Abrir"
            closeText="Fechar"
            noOptionsText="Nenhuma opção"
            loadingText="Loading ..."
            disablePortal
            value={autoCompleteSelectedOption}
            options={opcoes}
            loading={isLoading}
            disabled={isExternalLoading}
            onInputChange={(_, newValue) => setBusca(newValue)}
            onChange={(_, newValue) => { setSelectedId(newValue?.id); setBusca(''); clearError();onClick(newValue); }}
            popupIcon={isExternalLoading || isLoading ? <CircularProgress size={28} /> : undefined}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={labelSelect}
                    error={!!error}
                    helperText={error}
                    size={'small'}
                />
            )}
        />
    )
};