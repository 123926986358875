
import * as React from 'react';
import Modal from '@mui/material/Modal';
import { useEffect, useMemo, useState } from "react";
import {
  Box, Icon, Typography, DialogContent, DialogTitle, useMediaQuery, useTheme, DialogActions,
  TypographyPropsVariantOverrides
} from "@mui/material";
import { OverridableStringUnion } from '@mui/types';
import Button from '@mui/material/Button';
import { Variant } from '@mui/material/styles/createTypography';


const typeMessage: ITypeMessage[] = [
  {
    type: 'error',
    title: 'Erro',
    icon: 'cancel_Outlined_Icon',
    color: 'primary'
  },
  {
    type: 'alert',
    title: 'Atenção',
    icon: 'help_outline_outlined_Icon',
    color: '#7924C7'
  },
  {
    type: 'success',
    title: 'Sucesso',
    icon: 'check_circle_Outline_Icon',
    color: '#39c463'
  },
  {
    type: 'info',
    title: 'Informação',
    icon: 'error_outline_Outlined_Icon',
    color: '#7924C7'
  },
  {
    type: 'quastion',
    title: 'Confirmação',
    icon: 'help_outline_outlined_Icon',
    color: '#7924C7'
  },
  {
    type: 'pre_success',
    title: 'Sucesso',
    icon: 'check_circle_Outline_Icon',
    color: '#2b94e2'
  },

];

interface ITypeMessage {
  title: string;
  icon: string;
  color: string;
  type: string;
}

interface IContextProps {
  ITitle?: string;
  ISubTitle?: string;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  textButtonConfirm?: string;
  showButtonCancel?: boolean;
  showButtonConfirm?: boolean;
  textButtonCancel?: string;
  isOpenModal: boolean;
  messageType: string;
  IMessage: React.ReactNode[];
  onclickConfirm?: () => void;
  isCloseModal?: () => void;
  contextMessage?: string;
  zwidth?: string;
  showTitleIcon?: boolean;
  enableBackdropClose?: boolean;
  overrideWidthValue?: string;
  overrideHeightValue?: string;
  stylePropert?: React.CSSProperties;
  maxWidth?: string,
  maxHeight?: string,
  width?: string,
}

export const ModalContext: React.FC<IContextProps> = ({ ITitle, ISubTitle, IMessage, textButtonConfirm, showButtonConfirm,
  showButtonCancel, isOpenModal, messageType, isCloseModal, onclickConfirm, textButtonCancel, showTitleIcon, enableBackdropClose, overrideWidthValue, overrideHeightValue, stylePropert,
  maxWidth, maxHeight, width, variant = "h4" }) => {

  const styleDialogContent: React.CSSProperties = stylePropert ? stylePropert : { display: 'flex', justifyContent: 'center' };

  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width ? width : '50%',
    height: 'auto',
    maxWidth: maxWidth ? maxWidth : '70%',
    maxHeight: maxHeight ? maxHeight : '70%',
    bgcolor: 'background.paper',
    border: '1px solid #605e5e',
    boxShadow: 24,
    overflow: 'auto',
    p: 2,
    borderRadius: '6px',
    display: "flex",
    flexDirection: 'column',
    backgroundColor: '#FFF'

  };

  const setTypeMessage = () => {
    switch (messageType) {
      case 'error':
        return typeMessage[0];
      case 'alert':
        return typeMessage[1];
      case 'success':
        return typeMessage[2];
      case 'info':
        return typeMessage[3];
      case 'question':
        return typeMessage[4];
      case 'pre_success':
        return typeMessage[5];
      default: return typeMessage[0];
    }
  }

  const handleClose = (event: object, reason: string) => {
    if (reason === 'backdropClick' && isCloseModal) {
      isCloseModal()
    }
  }

  const messageStyle: ITypeMessage = setTypeMessage();

  const presentTitleIcon = useMemo(() => {
    return showTitleIcon === undefined || showTitleIcon === null ? true : showTitleIcon
  }, [showTitleIcon])

  return (
    <div>
      <Modal
        open={isOpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={enableBackdropClose ? handleClose : () => { }}
      >
        <Box sx={{ ...style, width: overrideWidthValue ? overrideWidthValue : style.width, height: overrideHeightValue ? overrideHeightValue : style.height }} >
          <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {presentTitleIcon && (
              <Icon style={{ color: `${messageStyle.color}`, fontSize: "3rem", fontWeight: 100 }}>
                {messageStyle.icon}
              </Icon>
            )}
            <Typography
              id="modal-modal-title"
              variant={variant}
              sx={{ fontWeight: 300 }}
              color={`${messageStyle.color}`}
            >
              {ITitle}
            </Typography>
            {ISubTitle && 
              <Typography
                id="modal-modal-sub-title"
                variant="subtitle2"
                color={`${messageStyle.color}`}
              >
                {ISubTitle}
              </Typography>
            }
          </DialogTitle>
          <DialogContent style={styleDialogContent}>
            {IMessage}
          </DialogContent>
          <DialogActions
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {showButtonConfirm && (
              <Button
                color="primary"
                disableElevation
                variant={"contained"}
                startIcon={< Icon > checkIcon</Icon >}
                onClick={onclickConfirm}
              >
                {textButtonConfirm}
              </Button>
            )}
            {showButtonCancel && (
              <Button
                onClick={isCloseModal}
                color="primary"
                disableElevation
                variant={"outlined"}
                startIcon={< Icon > closeIcon</Icon >}
              >
                {textButtonCancel}
              </Button>
            )}
          </DialogActions>
        </Box>
      </Modal>
    </div>
  );
}