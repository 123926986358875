import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Icon, LinearProgress, MenuItem, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { VForm, VTextField, useVForm } from "../../../shared/forms";
import { FinancialService, ICancelInvoice } from "../../../shared/services/api/financial/FinancialService";
import moment from "moment";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface IModalCancelInvocieProps {
  id: string;
  clientName: string;
  dueDate: string;
  valueInvoice: string;
  isOpenModal: boolean;
  isCloseModal?: () => void;
  widthModfy?: string;
  heightModfy?: string;
  onclickConfirm?: () => void;
  onCloseModal?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

export const ModalCancelInvoice: React.FC<IModalCancelInvocieProps> = ({
  id, clientName, dueDate, valueInvoice, isCloseModal,
  isOpenModal, onclickConfirm, onCloseModal, widthModfy, heightModfy,
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const { formRef } = useVForm();
  const toastHandler = useToast();
  const [reasonCancel, setReasonCancel] = useState('');
  const [typeCancel, setTypeCancel] = useState('');

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: widthModfy?.trim() ? widthModfy : 'auto',
    height: heightModfy?.trim() ? heightModfy : 'auto',
    minWidth: "650px",
    bgcolor: 'background.paper',
    border: '1px solid #605e5e',
    boxShadow: 24,
    p: 4,
    borderRadius: '6px'
  };

  const handleCancelInvoice = (dados: ICancelInvoice) => {
    dados.canceledReason = reasonCancel
    dados.canceledType = typeCancel
    console.log('id:', id, '', dados)
    FinancialService
      .CancelInvoice(id, { ...dados })
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          toastHandler.present({
            type: 'error',
            position: 'top-right',
            messageOrContent: <ToastContent title={'Erro'} content={'Erro ao cancelar o boleto'} />
          })
        } else {
          toastHandler.present({
            type: 'success',
            position: 'top-right',
            messageOrContent: <ToastContent title={'Sucesso'} content={'Boleto cancelado com sucesso'} />
          })
        }
      })
  };

  return (
    <div>
      <Modal
        open={isOpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={onCloseModal}
      >
        <Box sx={style} >
          <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <InfoOutlinedIcon style={{ color: '#7924c7', marginBottom: "1rem", fontSize: '3rem' }} />
            <Typography
              id="modal-modal-title"
              variant="h6"
              color={"primary"}
            >
              Cancelamento de Boleto
            </Typography>
          </DialogTitle>
          <DialogContent style={{ display: 'flex', justifyContent: 'center', padding: '2px', margin: 0 }}>
            <Box sx={{ width: '100%' }}>
              <Typography>
                <VForm 
                 id="formIdCancelInvoice"
                 placeholder="Your placeholder"
                 onPointerEnterCapture={() => console.log("Pointer entered")}
                 onPointerLeaveCapture={() => console.log("Pointer left")}
                ref={formRef} onSubmit={handleCancelInvoice}>
                  <Box className="teste" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} >
                    {isLoading && (
                      <Grid item>
                        <LinearProgress variant="indeterminate" />
                      </Grid>
                    )}

                    <Grid container item direction="row" spacing={1} sx={{ mb: 0 }}>
                      <Grid item xs={2} sx={{ textAlign: "left" }}>
                        Cliente :
                      </Grid>
                      <Grid item xs={8} sx={{ alignItems: "left" }}>
                        <Typography variant="inherit" sx={{ fontWeight: "bold" }} >
                          {clientName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={1} sx={{ mb: 2 }}>
                      <Grid item xs={2} sx={{ textAlign: "left" }}>
                        Vencimento :
                      </Grid>
                      <Grid item xs={2} sx={{ alignItems: "left" }}>
                        <Typography variant="inherit" sx={{ fontWeight: "bold" }} >
                          {moment(dueDate).format("L")}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} sx={{ textAlign: "right" }}>
                        Valor :
                      </Grid>
                      <Grid item xs={3} sx={{ alignItems: "left" }}>
                        <Typography variant="inherit" sx={{ fontWeight: "bold" }} >
                          {parseFloat(valueInvoice).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2} mt={1}>
                      <Grid item xs={12} md={8} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          label='Motivo do cancelamento'
                          name="canceledType"
                          disabled={isLoading}
                          defaultValue='Outros'
                          select
                          onChange={(e) => setTypeCancel(e.target.value)}
                        >
                          <MenuItem value={'Valor incorreto'}>Valor Incorreto</MenuItem>
                          <MenuItem value={'Duplicidade'}>Duplicidade</MenuItem>
                          <MenuItem value={'Isenção de encargos'}>Isenção de encargos</MenuItem>
                          <MenuItem value={'Outros'}>Outros</MenuItem>
                        </VTextField>
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <VTextField
                          fullWidth
                          label='Descreva o motivo'
                          name="canceledReason"
                          disabled={isLoading}
                          multiline
                          rows={4}
                          onChange={(e) => setReasonCancel(e.target.value)}
                        >
                        </VTextField>
                      </Grid>
                    </Grid>
                  </Box>
                </VForm>
              </Typography>
            </Box>

          </DialogContent>
          <DialogActions
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {typeCancel != '' ?
              <Button
                color="error"
                disableElevation
                variant={"contained"}
                startIcon={< Icon > checkIcon</Icon >}
                onClick={() => { formRef.current?.submitForm(), isCloseModal }}
              >
                Confirmar
              </Button> : ''}
            <Button
              onClick={isCloseModal}
              color="error"
              disableElevation
              variant={"outlined"}
              startIcon={< Icon > arrow_back_icon</Icon >}
            >
              Voltar
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </div>
  );
}