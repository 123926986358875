import { AccountCircleOutlined, Clear, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Icon, IconButton, InputAdornment, Paper, TextField, useTheme } from "@mui/material";
import BallotIcon from '@mui/icons-material/Ballot';
import { Environment } from "../../environment";

interface IToolbarGridSinisterProps {
    textSearchGrid?: string;
    showInputBuscaGrid?: boolean;
    onChangeTextBuscaGrid?: (newText: string) => void;

    textButtonAbertos?: string;
    showBotaoAbertos?: boolean;
    onClickAbertos?: () => void;
    selectAberto?: boolean;

    textButtonFinalizados?: string;
    showBotaoFinalizados?: boolean;
    onClickFinalizados?: () => void;
    selectFinalizado?: boolean;

    textButtonRascunho?: string;
    showBotaoRascunho?: boolean;
    onClickRascunho?: () => void;
    selectRascunho?: boolean;

    textButtonPendenciados?: string;
    showBotaoPendenciados?: boolean;
    onClickPendenciados?: () => void;
    selectPendenciado?: boolean;

    textButtonEmAndamento?: string;
    showBotaoEmAndamento?: boolean;
    onClickEmAndamento?: () => void;
    selectEmAndamento?: boolean;

    textButtonConfissaoDebitos?: string;
    showBotaoConfissaoDebitos?: boolean;
    onClickConfissaoDebitos?: () => void;
    selectConfissaoDebitos?: boolean;

    textButtonIndenizado?: string;
    showBotaoIndenizado?: boolean;
    onClickIndenizado?: () => void;
    selectIndenizado?: boolean;

    textButtonLmiExausto?: string;
    showBotaoLmiExausto?: boolean;
    onClickLmiExausto?: () => void;
    selectLmiExausto?: boolean;

    textButtonCancelado?: string;
    showBotaoCancelado?: boolean;
    onClickCancelado?: () => void;
    selectCancelado?: boolean;

    textButtonTodos?: string;
    showBotaoTodos?: boolean;
    onClickTodos?: () => void;
    selectTodos?: boolean;

}

export const ToolbarGridSinister: React.FC<IToolbarGridSinisterProps> = ({
    textSearchGrid: textSearch = '',
    showInputBuscaGrid: showInputBusca = true,
    onChangeTextBuscaGrid: onChangeTextBusca,

    textButtonAbertos = 'Abertos',
    showBotaoAbertos = true,
    onClickAbertos,
    selectAberto = false,

    textButtonEmAndamento = 'Em Andamento',
    showBotaoEmAndamento = true,
    onClickEmAndamento,
    selectEmAndamento = false,

    textButtonFinalizados = 'Finalizados',
    showBotaoFinalizados = true,
    onClickFinalizados,
    selectFinalizado = false,

    textButtonPendenciados = 'Pendenciados',
    showBotaoPendenciados = true,
    onClickPendenciados,
    selectPendenciado = false,

    textButtonRascunho = 'Rascunho',
    showBotaoRascunho = true,
    onClickRascunho,
    selectRascunho = false,

    textButtonCancelado = 'Cancelados',
    showBotaoCancelado = true,
    onClickCancelado,
    selectCancelado = false,

    textButtonConfissaoDebitos = 'Confissão de Débitos',
    showBotaoConfissaoDebitos = true,
    onClickConfissaoDebitos,
    selectConfissaoDebitos = false,


    textButtonIndenizado = 'Indenizado Mens.',
    showBotaoIndenizado = true,
    onClickIndenizado,
    selectIndenizado = false,

    textButtonLmiExausto = 'Lmi sem Saldo',
    showBotaoLmiExausto = false,
    onClickLmiExausto,
    selectLmiExausto = false,

    textButtonTodos = 'Todos',
    showBotaoTodos = true,
    onClickTodos,
    selectTodos = true,

}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            alignItems="start"
            height={theme.spacing(6)}
        >
            <ButtonGroup aria-label="outlined primary button group">
                {showBotaoTodos && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectTodos ? "contained" : "outlined"}
                        onClick={onClickTodos}
                        startIcon={<Icon>done_all_icon</Icon>}
                    >
                        {textButtonTodos}
                    </Button>
                )}

                {showBotaoAbertos && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectAberto ? "contained" : "outlined"}
                        onClick={onClickAbertos}
                        startIcon={<Icon>portrait_outlined_icon</Icon>}
                    >
                        {textButtonAbertos}
                    </Button>
                )}

                {showBotaoEmAndamento && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectEmAndamento ? "contained" : "outlined"}
                        onClick={onClickEmAndamento}
                        startIcon={<Icon>arrow_forward_outlineOutlinedIcon</Icon>}
                    >
                        {textButtonEmAndamento}
                    </Button>
                )}

                {showBotaoFinalizados && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectFinalizado ? "contained" : "outlined"}
                        onClick={onClickFinalizados}
                        startIcon={<Icon>pending_actionsOutlinedIcon</Icon>}
                    >
                        {textButtonFinalizados}
                    </Button>
                )}

                {showBotaoPendenciados && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectPendenciado ? "contained" : "outlined"}
                        onClick={onClickPendenciados}
                        startIcon={<Icon>pause_circle_outlineOutlinedIcon</Icon>}
                    >
                        {textButtonPendenciados}
                    </Button>
                )}

                {showBotaoConfissaoDebitos && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectConfissaoDebitos ? "contained" : "outlined"}
                        onClick={onClickConfissaoDebitos}
                        startIcon={<BallotIcon />}
                    >
                        {textButtonConfissaoDebitos}
                    </Button>
                )}


                {showBotaoIndenizado && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectIndenizado ? "contained" : "outlined"}
                        onClick={onClickIndenizado}
                        startIcon={<Icon>calendar_month_outlinedIcon</Icon>}

                    >
                        {textButtonIndenizado}
                    </Button>
                )}

                {showBotaoCancelado && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectCancelado ? "contained" : "outlined"}
                        onClick={onClickCancelado}
                        startIcon={<Icon>cancel_outlinedIcon</Icon>}

                    >
                        {textButtonCancelado}
                    </Button>
                )}

                {showBotaoRascunho && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectRascunho ? "contained" : "outlined"}
                        onClick={onClickRascunho}
                        startIcon={<Icon>edit_note_outlinedIcon</Icon>}

                    >
                        {textButtonRascunho}
                    </Button>
                )}

                {showBotaoLmiExausto && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectLmiExausto ? "contained" : "outlined"}
                        onClick={onClickLmiExausto}
                        startIcon={<Icon>calendar_month</Icon>}
                    >
                        {textButtonLmiExausto}
                    </Button>
                )}

            </ButtonGroup>

            <Box flex={1} display="flex" justifyContent="end">
                {showInputBusca && (
                    <TextField
                        size="small"
                        value={textSearch}
                        placeholder={Environment.INPUT_BUSCA}
                        onChange={(e) => onChangeTextBusca?.(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        // onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="start"
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        // onClick={handleClickShowPassword}
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        sx={{ color: "#dbd5d5" }}
                                    >
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </Box>
        </Box >
    )
} 