
import * as React from 'react';
import Modal from '@mui/material/Modal';
import { VTextField, VForm, useVForm, IVFormErros } from "../../forms";
import { useEffect, useState } from "react";
import {
    Box, Grid, Icon, InputAdornment, LinearProgress, MenuItem, Paper, Table, TableContainer,
    Typography, Button, DialogActions, DialogContent, DialogTitle,
} from "@mui/material";
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AutoCompletePlan } from '../autoCompletePlan/AutoCompletePlan';
import { VMaskTextField } from '../../forms/VMaskTextField';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    bgcolor: 'background.paper',
    border: '1px solid #605e5e',
    boxShadow: 24,
    p: 2,
    borderRadius: '6px'
};

const typeMessage: ITypeMessage[] = [
    {
        type: 'error',
        title: 'Erro',
        icon: 'cancel_Outlined_Icon',
        color: '#e87b9e'
    },
    {
        type: 'alert',
        title: 'Atenção',
        icon: 'info_Outlined_Icon',
        color: '#ffc107'
    },
    {
        type: 'success',
        title: 'Sucesso',
        icon: 'check_circle_Outline_Icon',
        color: '#39c463'
    },
    {
        type: 'info',
        title: 'Informação',
        icon: 'error_outline_Outlined_Icon',
        color: 'red'
    },
];

interface ITypeMessage {
    title: string;
    icon: string;
    color: string;
    type: string;
}

interface IModalValueSinisterProps {
    textButtonConfirm?: string;
    showButtonCancel?: boolean;
    isOpenModal: boolean;
    messageType: string;
    onclickConfirm?: () => void;
    isCloseModal?: () => void;
}


interface IFormData {
    // id      :string,
    document: string;
    status: string;
    email: string;
    creci: string;
    name: string;
    trade_name: string;
}


export const ModalValueSinister: React.FC<IModalValueSinisterProps> = ({ textButtonConfirm,
    showButtonCancel, isOpenModal, messageType, isCloseModal, onclickConfirm }) => {

    showButtonCancel = true;
    textButtonConfirm = 'Adicionar';

    const setTypeMessage = () => {
        switch (messageType) {
            case 'error':
                return typeMessage[0];
            case 'alert':
                return typeMessage[1];
            case 'success':
                return typeMessage[2];
            case 'info':
                return typeMessage[3];
            default: return typeMessage[0];
        }
    }

    const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
    const messageStyle: ITypeMessage = setTypeMessage();
    const { id = 'novo' } = useParams<'id'>();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [value, setValue] = useState(0);


    const handleSave = (dados: IFormData) => {
        // setValue(dados[id]);
    }


    return (
        <div>
            <Modal
                open={isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Icon style={{ color: `${messageStyle.color}`, fontSize: "3rem", fontWeight: 100 }}>
                            {messageStyle.icon}
                        </Icon>
                        <Typography
                            variant='caption'
                            color={`${messageStyle.color}`}
                            marginBottom="1rem"
                        >
                            {messageStyle.title}
                        </Typography>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            color={`${messageStyle.color}`}
                        >
                            Adicionar um débito
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
                        >
                            <VForm 
                             id="formIdSave"
                             placeholder="Your placeholder"
                             onPointerEnterCapture={() => console.log("Pointer entered")}
                             onPointerLeaveCapture={() => console.log("Pointer left")}
                            ref={formRef} onSubmit={handleSave}>
                                {/* <Box margin={1} display="flex" border={"none"} flexDirection="column" component={Paper} variant="outlined" > */}
                                <Grid container direction="column" padding={1} spacing={2} >
                                    {isLoading && (
                                        <Grid item>
                                            <LinearProgress variant="indeterminate" />
                                        </Grid>
                                    )}
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <VTextField
                                                fullWidth
                                                label='Tipo'
                                                name="Tipo"
                                                disabled={isLoading}
                                                select
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            >
                                                <MenuItem value={'rent_values'}>Aluguel</MenuItem>
                                                <MenuItem value={'condominium_values'}>Condomínio</MenuItem>
                                                <MenuItem value={'water_values'}>Agua</MenuItem>
                                                <MenuItem value={'energy_values'}>Energia Elétrica</MenuItem>
                                                <MenuItem value={'gas_values'}>Gás</MenuItem>
                                                <MenuItem value={'damage'}>Danos Imóvel</MenuItem>
                                                <MenuItem value={'painting'}>Pintura</MenuItem>

                                            </VTextField>
                                        </Grid>
                                    </Grid>

                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Referência'
                                                name="reference"
                                                disabled={isLoading}
                                                mask="mesano"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Valor'
                                                name="Value"
                                                disabled={isLoading}
                                                mask="real"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <VTextField
                                                fullWidth
                                                label='Vencimento'
                                                name="due_date"
                                                disabled={isLoading}
                                                type="date"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* </Box> */}
                            </VForm>
                        </Typography>
                    </DialogContent>
                    <DialogActions
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Button
                            color="primary"
                            disableElevation
                            variant={"contained"}
                            startIcon={< Icon > checkIcon</Icon >}
                            onClick={onclickConfirm}
                        >
                            {textButtonConfirm}
                        </Button>
                        {showButtonCancel && (
                            <Button
                                onClick={isCloseModal}
                                color="primary"
                                disableElevation
                                variant={"outlined"}
                                startIcon={< Icon > closeIcon</Icon >}
                            >
                                Cancelar
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </Modal>
        </div>
    )
}
