import { SettingsOverscanOutlined } from "@mui/icons-material";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useField } from '@unform/core';
import { useEffect, useState } from "react";
import { maskCnpj, maskCpf, maskCpfOrCnpj } from "../utils";

// componente pode receber as props do material ui e tambem props name do Vtext 
type TVMaskTextFieldProps = TextFieldProps & {
    name: string;
    mask: string;
    trimEmptySpaces?: boolean;
}

//000.000.000-00
const maskCPFT = (value: string) => {
    let cell = value
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
    if (cell.length < 1) {
        cell = "";
    }
    return cell?.trim();
};

//00.000.000/0000-00
const maskCNPJT = (value: string) => {
    let cell = value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    if (cell.length < 1) {
        cell = "";
    }
    return cell?.trim();
};

const maskInteiro = (value: string) => {
    if (typeof value === 'number') { return value; }
    console.log('value', value);
    let cell = value
        .replace(/\D/g, "")
        .replace(/(\b[0-9]\b)/g, '$1');
    if (cell.length < 1) {
        cell.replace(/\D/g, "");
        cell = "";
    }
    return cell?.trim();
};


//00000-000
const maskCEP = (value: string) => {
    let cell = value
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')
    if (cell.length < 1) {
        cell = "";
    }
    return cell?.trim();
};

// (00) 00000-0000
const maskPhone = (value: string) => {
    let cell = value.length <= 14 ?
        value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
        :
        value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')

    if (cell.length < 1) {
        cell = "";
    }
    return cell?.trim();
};

const maskCell = (value: string) => {
    let cell = value.length <= 14 ?
        value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
        :
        value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
    if (cell.length < 1) {
        cell = "";
    }
    return cell?.trim();
};

const maskReal = (value: string) => {
    if (value === '') return value;
    if (value === '0') return '0,00';

    let cell = value
        .replace(/\D/g, '')

    if (cell.length > 0 && cell.length < 3) {
        while (cell.length < 3) {
            cell = '0' + cell;
        }
    }

    if (cell.length > 3) {
        cell = cell.replace(/^(0)/, '')
    }

    cell = cell.replace(/(\d{0,2})$/, ',$1')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

    if (cell.length <= 1) {
        cell = "";
    }
    return cell?.trim();
};


const maskMMYYYY = (value: string) => {
    let cell = value
        .replace(/\D/g, "")
        .replace(/^(\d{2})(\d)/g, "$1/$2")
    if (cell.length < 1) {
        cell = "";
    }
    if (cell.length > 7) {
        cell = cell.substring(0, 7);
    }
    return cell?.trim();
}

const maskCellPhone = (value: string) => {
    value = value.length <= 14 ?
        value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(\d{4})/, '$1')
        :
        value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');

    return value;
}

export const VMaskTextField: React.FC<TVMaskTextFieldProps> = ({ name, mask, trimEmptySpaces, ...rest }) => {
    const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
    const [value, setValue] = useState(defaultValue || '');
    const [maskdefault, setMaskdefault] = useState('');

    //console.log('.....rest', rest);
    const setMask = (value2: string) => {
        switch (mask) {
            case 'cpf':
                return maskCPFT(value2);
            case 'cep':
                return maskCEP(value2);
            case 'phone':
                return maskPhone(value2);
            case 'cell':
                return maskCell(value2);
            case 'cnpj':
                return maskCNPJT(value2);
            case 'real':
                return maskReal(value2);
            case 'inteiro':
                return maskInteiro(value2);
            case 'mesano':
                return maskMMYYYY(value2);
            case 'cellphone':
                return maskCellPhone(value2);
            case 'cpfOrCnpj':
                return maskCpfOrCnpj(value2);

            default: return trimEmptySpaces ? value2.replace(/( )/g, '') : value2;
        }
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => value,
            setValue: (_, newValue) => setValue(newValue),
        });
        if (value) {
            return setMaskdefault(setMask(value));
        }

        setMaskdefault('')
    }, [registerField, fieldName, value]);

    return (
        <TextField{
            ...rest}
            error={!!error}
            helperText={error}
            defaultValue={defaultValue}
            value={maskdefault}
            onChange={e => {
                setValue(e.target.value);
                rest.onChange?.(e);
            }}
            onKeyDown={(e) => { error && clearError(); rest.onKeyDown?.(e); }}
            size='small'
            InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
        />
    );
};