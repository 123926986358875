import moment from "moment";

export const formatDate = (input: Date): string => {
  const stringDate = input.toLocaleDateString("pt-BR", {
    hour12: false,
    formatMatcher: "basic",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const splitDateTime = stringDate.split(" ");
  const datePart = splitDateTime[0];
  const timePart = splitDateTime[1];

  const splitTime = timePart.split(":");

  return `${datePart} ${splitTime[0]}h${splitTime[1]}m`.replace(",", " ");
};

export const formatDateForInput = (input: Date): string => {
  const yearAsNumber = input.getFullYear();
  const dayAsNumber = input.getDate();
  const monthAsNumber = input.getMonth() + 1;

  const monthAsString =
    monthAsNumber >= 10
      ? monthAsNumber.toString()
      : "0" + monthAsNumber.toString();
  const dayAsString =
    dayAsNumber >= 10 ? dayAsNumber.toString() : "0" + dayAsNumber.toString();

  return `${yearAsNumber.toString()}-${monthAsString}-${dayAsString}`;
};

export const formatDateUTC = (data: any, hasHour: boolean = true): string =>
  moment(data).format(`YYYY-MM-DD${hasHour ? " HH:mm" : ""}`);

export const formatDateBR = (data: any, hasHour: boolean = true): string =>
  moment(data).format(`DD/MM/YYYY${hasHour ? " HH:mm" : ""}`);

export const formatDateBRHasMinutes = (data: any, hasHour: boolean = true): string =>
  moment(data).format(`DD/MM/YYYY${hasHour ? " HH:mm:ss" : ""}`);

export const formatToDate = (data: any): Date => {
  if (!data) return new Date();
  else if (typeof data === 'string') return moment(new Date(data)).toDate();
  else return moment(data).toDate();
};

export const formatToDateToString = (
  data: any,
  hasHour: boolean = true,
): string => {
  if (!data) return '';
  else if (typeof data === 'string') {
    const dateConvert = new Date(data);
    return moment(dateConvert).format(
      `YYYY-MM-DD${hasHour ? ' HH:mm:ss' : ''}`,
    );
  } else {
    return moment(data).format(`YYYY-MM-DD${hasHour ? ' HH:mm:ss' : ''}`);
  }
};
