import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useToast } from '../../../../shared/hooks';
import { clientService } from '../../../../shared/services/api/client/clientService';
import { ContextsContact } from './components/ContextContacs';
import { format } from 'path';
import { globalTranslate, maskPhone } from '../../../../shared/utils';
import { ContactsModal } from '../../../../shared/components';

interface IContactsClientProps {
  isLoading: boolean;
  id: string;
}

export interface IContactsGrid {
  id: string;
  status: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contactType: string;
  createdAt: string;
  updatedAt: string;
  clientId: string;
}

export interface IClient {
  id: string;
  name: string;
  email: string;
  document: string;
}

export const ContactsClient: React.FC<IContactsClientProps> = ({ id }) => {
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [contactsList, setContactsList] = useState<IContactsGrid[]>([]);
  //const [selectContact, setSelectContact] = useState<IContactsGrid>({} as IContactsGrid);
  const [client, setClient] = useState<IClient>({
    id: '',
    name: '',
    email: '',
    document: '',
  });
  const [selectContacts, setselectContacts] = useState<IContactsGrid>(
    {} as IContactsGrid
  );
  const toastHandler = useToast();
  const [isRefresh, setIsRefresh] = useState(true);
  const [openModalContact, setOpenModalContact] = useState(false);
  const [mycontext, setMyContext] = useState<string[]>([]);

  const handleOpenModalContact = () => {
    setselectContacts({} as IContactsGrid);
    setOpenModalContact(true);
  };

  const handlemyContext = (value: string[]) => {
    setMyContext(value);
    console.log(value);
  };

  const options = [
    'comercial',
    'financeiro',
    'administrativo',
    'sinistro',
    'juridico',
  ];

  const getContactById = () => {
    setContactsList([]);
    if (id !== 'novo') {
      setIsRefresh(true);
      clientService.getContactsById(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          const contacts = result as unknown as IContactsGrid[];
          setContactsList(contacts);
          console.log(contacts);
          setIsRefresh(false);
        }
      });

      clientService.getByID(id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          const client = result as unknown as IClient;
          setClient(client);
          console.log(client);
          setIsRefresh(false);
        }
      });
      setIsRefresh(false);
    }
  };

  useEffect(() => {
    if (isRefresh && !openModalConfirm && !openModalContact) {
      getContactById();
    }
  }, [isRefresh, openModalConfirm, openModalContact]);

  return (
    <>
      <Grid container item direction="row" spacing={2}>
        <Grid item md={12}>
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{
              marginLeft: 1,
              marginTop: 1,
              marginRight: 2,
              width: 'auto',
              padding: 4,
            }}
            aria-label="customized table"
          >
            {isRefresh ? (
              <TableRow>
                {' '}
                <TableCell sx={{ textAlign: 'center' }} colSpan={4}>
                  <CircularProgress />{' '}
                </TableCell>{' '}
              </TableRow>
            ) : (
              <Table aria-label="a dense table">
                <TableRow>
                  <TableCell sx={{ color: '#7e7777b5' }}> Nome</TableCell>
                  <TableCell sx={{ color: '#7e7777b5' }}> Email</TableCell>
                  <TableCell sx={{ color: '#7e7777b5' }}> Telefone</TableCell>
                  <TableCell sx={{ color: '#7e7777b5' }}> Status</TableCell>
                  <TableCell sx={{ color: '#7e7777b5' }}> Contexto</TableCell>
                  <TableCell sx={{ color: '#7e7777b5' }}>
                    {id !== 'novo' && (
                      <Button
                        color="primary"
                        disableElevation
                        variant="contained"
                        onClick={handleOpenModalContact}
                      >
                        Adicionar
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
                <TableBody>
                  {/* {contactsList?.map(row => ( */}
                  {contactsList?.map((row) => (
                    <TableRow key={row.id} hover>
                      <TableCell sx={{ fontWeight: 'normal' }}>
                        <Typography
                          variant="subtitle2"
                          color={'#959595'}
                          fontWeight={'normal'}
                        >
                          {row.contactName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'normal' }}>
                        <Typography variant="subtitle2" fontWeight={'normal'}>
                          {row.contactEmail}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'normal' }}>
                        <Typography
                          variant="subtitle2"
                          fontWeight={'normal'}
                          textAlign={'center'}
                        >
                          {row.contactPhone ? maskPhone(row.contactPhone) : ''}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ fontWeight: 'normal' }}>
                        <Typography
                          variant="subtitle2"
                          fontWeight={'normal'}
                          textAlign={'center'}
                        >
                          {row.status ? globalTranslate(row.status) : ''}
                        </Typography>
                        {/* <Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"} >
                                                    {row.contactType}
                                                </Typography> */}
                      </TableCell>

                      <TableCell>
                        <ContextsContact
                          names={
                            row.contactType ? row.contactType.split(',') : []
                          }
                          disabled={true}
                          options={options}
                          inputLabel="Contexto"
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setselectContacts(row);
                            setOpenModalContact(true);
                            //     setPlan([row]);
                            //     setPlanId(row.id);
                            //     setOpenModalConfirm(true);
                          }}
                          sx={{ borderRadius: '18px' }}
                        >
                          <Icon sx={{ color: '#0170B3', fontWeight: 'normal' }}>
                            {' '}
                            app_registrationicon
                          </Icon>
                          <Typography
                            variant="subtitle2"
                            marginLeft={1}
                            sx={{ color: '#0170B3', fontWeight: 'normal' }}
                          >
                            Editar
                          </Typography>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Grid>
      </Grid>

      <ContactsModal
        isCloseModal={() => setOpenModalContact(false)}
        isOpenModal={openModalContact}
        textButtonConfirm={selectContacts.id ? 'Editar' : 'Adicionar'}
        dataClient={client}
        data={selectContacts}
        messageType={'phone'}
        reload={getContactById}
        clientContact={id}
        onclickCancel={() => setOpenModalContact(false)}
      />
    </>
  );
};
