import {Grid, styled} from '@mui/material';

export const ClickableGrid = styled(Grid)`
  & {
    transition: opacity 0.25s linear, filter 0.25s linear;
  }

  &:hover {
    cursor: pointer;
    filter: contrast(90%);
  }
`;