import { Logout, Settings } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, useState } from 'react';
import { useAuthContext, useDrawerContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import { Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { isMobile } from 'react-device-detect';
import { ModalHelp } from '../components/ModalHelp/ModalHelp';
import { useProfile } from '../../context/ProfileContext';
import { ModalReport } from '../components/ModalReport/ModalReport';
import { Environment } from '../environment';

interface ILayoutBaseDePaginaProps {
  titulo: string;
  subtitulo?: string;
  barraDeFerramentas?: ReactNode;
  children?: React.ReactNode;
  marginLeft?: any;
}

export const LayoutBaseDePagina: React.FC<ILayoutBaseDePaginaProps> = ({
  children,
  marginLeft,
  titulo,
  subtitulo,
  barraDeFerramentas,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { toggleDrawerOpen } = useDrawerContext();
  const { logout, loggedUser } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [openModal, setOpenModal] = useState(false);
  const whats = isMobile
    ? 'https://wa.me/+5531972212479'
    : 'https://web.whatsapp.com/send/?phone=%2B5531972212479&text&type=phone_number&app_absent=0';

  const [modalReport, setModalReport] = useState(false);
  const { permissionLevel } = useProfile();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlecloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleCloseReport = () => {
    setModalReport(!modalReport);
  };

  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gap={'3px'}
        marginLeft={marginLeft}
      >
        <Box
          display="flex"
          padding={1}
          alignItems="center"
          height={theme.spacing(smDown ? 10 : mdDown ? 10 : 10)}
        >
          <Box
            padding={2}
            paddingX={2}
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            marginTop={2}
            component={Paper}
            overflow="auto"
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box
                display={'flex'}
                justifyContent={'start'}
                sx={{ alignItems: 'center' }}
              >
                <IconButton onClick={toggleDrawerOpen}>
                  <Icon>menu</Icon>
                </IconButton>
                <Typography
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  variant={smDown ? 'h6' : mdDown ? 'h5' : 'h5'}
                >
                  {titulo}
                </Typography>
              </Box>

              <Box display={'flex'} alignItems={'center'}>
                {permissionLevel === 1 && (
                  <Tooltip title="Relátorios">
                    <IconButton
                      onClick={handleCloseReport}
                      sx={{ mr: 3, p: 0 }}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <Icon fontSize="medium">print_Outlined</Icon>
                    </IconButton>
                  </Tooltip>
                )}

                {/* habiliar botão de ajuda apos termino do desenvolvimento********************************************** */}
                {Environment.AMBIENTE === 'DEV' && (
                  <Tooltip title="Ajuda">
                    <IconButton
                      onClick={handlecloseModal}
                      sx={{ mr: 3, color: 'primary.main', p: 0 }}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <Icon fontSize="large">contact_support</Icon>
                    </IconButton>
                  </Tooltip>
                )}

                <Box display={'flex'} flexDirection={'column'} marginRight={2}>
                  <Typography
                    variant={'subtitle1'}
                    sx={{ alignSelf: 'self-end' }}
                  >
                    {' '}
                    {loggedUser?.name}{' '}
                  </Typography>
                  <Typography
                    variant={'caption'}
                    sx={{
                      color: 'text.secondary',
                      fontWeight: '500',
                      alignSelf: 'self-end',
                    }}
                  >
                    {' '}
                    {loggedUser?.permission_description}{' '}
                  </Typography>
                </Box>

                <Tooltip title="Configurações de conta">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>
                      {loggedUser?.name?.charAt(0)?.toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              variant={'subtitle1'}
            >
              {subtitulo}
            </Typography>
          </Box>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={() => navigate(`/usuarios/perfil/${loggedUser?.id}`)}
          >
            <Avatar /> Perfil
          </MenuItem>
          <Divider />
          {/* <MenuItem>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Adicionar outra conta
                </MenuItem> */}
          <MenuItem style={{ display: 'none' }}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Configurações
          </MenuItem>
          <MenuItem
            onClick={() => {
              logout();
              navigate('/login', { replace: true });
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Sair
          </MenuItem>
        </Menu>

        {barraDeFerramentas && <Box mt={2}>{barraDeFerramentas}</Box>}

        <Box flex={1} overflow="auto">
          {children}
        </Box>

        <Fab
          variant="circular"
          LinkComponent={'a'}
          href={whats}
          target="_blank"
          color="success"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
        >
          <WhatsAppIcon />
        </Fab>
      </Box>

      {openModal && (
        <ModalHelp
          isOpenModal={openModal}
          iscloseModal={handlecloseModal}
          enableBackdropClose={true}
          overrideWidthValue={'80%'}
          overrideHeightValue={'80%'}
          showButtonConfirm={true}
          onclickConfirm={handlecloseModal}
        />
      )}

      {modalReport && (
        <ModalReport
          isOpenModal={modalReport}
          onClose={handleCloseReport}
          onclickConfirm={handleCloseReport}
        />
      )}
    </>
  );
};
