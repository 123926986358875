import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { clientService, IListagemCliente } from "../../../shared/services/api/client/clientService";
import { Loading, Toolbar, ToolbarGridClientes } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useDebounce } from "../../../shared/hooks";
import { Environment } from "../../../shared/environment";
import moment from "moment";
import 'moment/locale/pt-br';
import { BasicModal } from "../../../shared/components/VModal/VModal";
import { useProfile } from "../../../context/ProfileContext";
import { checkByProfileGuard, ProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { returnMessageError } from "../../../shared/utils/returnMessageError";


function colorBackGround(status: string) {
  if (status === 'pending') { return '#f8e8d2' }
  if (status === 'active') { return '#d9f8e5' }
  if (status === 'inactive') { return '#fce4e4' }
  return '#959595';
}
function colorIcon(status: string) {
  if (status === 'pending') { return '#f5ba5c' }
  if (status === 'active') { return '#34D399' }
  if (status === 'inactive') { return '#F87171' }
  return '#202020';
}
function colorText(status: string) {
  if (status === 'pending') { return '#e48509' }
  if (status === 'active') { return '#0f8a0d' }
  if (status === 'inactive') { return '#e40909' }
  return '#202020';
}

function translate(traduzir: String) {
  if (traduzir === 'pending') { return 'Pendente' }
  if (traduzir === 'active') { return 'Ativo' }
  if (traduzir === 'inactive') { return 'Inativo' }
  if (traduzir === 'blocked') { return 'Bloqueado' }
  return traduzir;
};


export const ListagemClientesPresentation: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IListagemCliente[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const toastHandler = useToast();
  const permissionUpdate = checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Update)
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const tipocliente = useMemo(() => {
    return (searchParams.get('tipo') || '');
  }, [searchParams]);

  const statuscliente = useMemo(() => {
    return (searchParams.get('status') || '');
  }, [searchParams]);

  const ufcliente = useMemo(() => {
    return (searchParams.get('uf') || '');
  }, [searchParams]);

  const origemcliente = useMemo(() => {
    return (searchParams.get('origem') || '');
  }, [searchParams]);

  const clienteFilter = useMemo(() => {
    return (searchParams.get('clienteFilter') || '');
  }, [searchParams]);

  const dataIni = useMemo(() => {
    //return (searchParams.get('dataini') || moment().startOf('month').format("YYYY-MM-DD"));
    return (searchParams.get('dataini') || '');//moment().startOf('month').subtract(1,'months').format("YYYY-MM-DD"));
  }, [searchParams]);

  const dataFim = useMemo(() => {
    return (searchParams.get('datafim') || ''); //moment().endOf('month').format("YYYY-MM-DD"));
  }, [searchParams]);

  const managerId = useMemo(() => {
    return (searchParams.get('managerId') || '');
  }, [searchParams]);

  const [openModal, setOpenModal] = useState(false);

  const hasViewPermission = useMemo(() => {
    return checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.View)
  }, [])

  const { registerClient, permissionLevel, isSuperAdmin } = useProfile();

  const handleOpenModal = () => {
    setOpenModal(true)
    console.log('chamando funcao')
  };

  useEffect(() => {
    if (hasViewPermission) {
      setIsLoading(true);
      debounce(() => {
        clientService.getAll(pagina, busca, tipocliente, origemcliente, statuscliente, clienteFilter, dataIni, dataFim, ufcliente, managerId)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              const titleMessage = "Alerta!!";
              const contentMessage = "Erro: " + result?.message;

              toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
              })
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          }).catch((err: any) => {
            setIsLoading(false);
            const { titleMessage, contentMessage } = returnMessageError(err);

            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            })
          })
      });
    }
  }, [tipocliente, busca, pagina, origemcliente, statuscliente, clienteFilter, dataIni, dataFim, ufcliente, hasViewPermission, managerId]);

  const clickEdit = (id: string) => {
    console.log("permi", permissionLevel);
    if (permissionLevel) {
      if ((permissionLevel === 1) || (permissionUpdate)) {
        navigate(`/clientes/detalhe/${id}`)
      } else {
        navigate(`/clientes/detalheunico/${id}`)
      }
    }
  }

  const textBuscaGridHandler = (texto: string) => {
    if (texto) {
      return setSearchParams({
        busca: texto,
        pagina: '1',
        tipo: tipocliente,
        status: statuscliente,
        origem: origemcliente,
        dataini: '',
        datafim: ''
      }, { replace: true }
      )
    }

    return setSearchParams({
      busca: texto,
      pagina: '1',
      tipo: tipocliente,
      status: statuscliente,
      origem: origemcliente,
      dataini: dataIni,
      datafim: dataFim
    }, { replace: true }
    )
  }


  return (
    <LayoutBaseDePagina
      titulo={permissionLevel === 1 ? "Clientes" : "Perfil de cadastro"}
      subtitulo={permissionLevel === 1 ? "Listagem de clientes" : "Meus Cadastros"}
      barraDeFerramentas={
        isSuperAdmin || permissionLevel == 2 ?
          <Box display="flex" margin={0} padding={0} flexDirection={"row"} flexWrap={"nowrap"} 
          >
            <Toolbar
              sxPaper={{width: "100%"}}
              textoButtonNew="novo"
              onClickNovo={() => navigate('/clientes/detalhe/novo')}
              showPeriodo={true}
              showInputUF={true}
              showInputBusca={isSuperAdmin || permissionLevel == 2 ? false : false}
              showInputStatus={checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Filter)}
              showInputOrigem={checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Filter)}
              showInputClientFilter={false}
              showButtonNew={checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Add)}
              onChangeStatus={(retorno: string) => setSearchParams({ busca, pagina: String(pagina), tipo: tipocliente, status: retorno, origem: origemcliente, dataini: dataIni, datafim: dataFim, clienteFilter: clienteFilter, uf: ufcliente, managerId }, { replace: true })}
              onChangeOrigem={(retorno: string) => setSearchParams({ busca, pagina: String(pagina), tipo: tipocliente, status: statuscliente, origem: retorno, dataini: dataIni, datafim: dataFim, clienteFilter: clienteFilter, uf: ufcliente, managerId }, { replace: true })}
              onChangeUF={(retorno: string) => setSearchParams({ busca, pagina: String(pagina), tipo: tipocliente, status: statuscliente, origem: origemcliente, dataini: dataIni, datafim: dataFim, clienteFilter: clienteFilter, uf: retorno, managerId }, { replace: true })}
              onChangeDataIni={(retorno: string) => setSearchParams({
                busca, pagina: String(1), tipo: tipocliente, status: statuscliente, origem: origemcliente,
                dataini: retorno, datafim: dataFim ? dataFim : moment().endOf('month').format("YYYY-MM-DD"), clienteFilter: clienteFilter, uf: ufcliente,
                managerId
              }, { replace: true })}
              onChangeDataFim={(retorno: string) => setSearchParams({
                busca, pagina: String(1), tipo: tipocliente, status: statuscliente, origem: origemcliente,
                dataini: dataIni ? dataIni : moment().startOf('month').subtract(1, 'months').format("YYYY-MM-DD"), datafim: retorno, clienteFilter: clienteFilter, uf: ufcliente,
                managerId
              }, { replace: true })}

              showTransferButton={true}
              showInputManagerFilter={isSuperAdmin || permissionLevel == 2 ? true : false}
              onChangeManagerFilter={
                (retorno: string) => {
                  setSearchParams({
                    busca, pagina: String(pagina), tipo: tipocliente,
                    status: statuscliente, clienteFilter: clienteFilter,
                    managerId: retorno, dataini: dataIni, datafim: dataFim
                  }, { replace: true });
                }
              }

              onChangeClientFilter={
                (retorno: string) => {
                  setSearchParams({ busca, pagina: String(pagina), tipo: tipocliente, status: statuscliente, clienteFilter: retorno, managerId }, { replace: true });
                  //setSearchParams({ busca, pagina: String(pagina), tipo: tipocliente, status: statuscliente, origem: origemcliente, clienteFilter: retorno }, { replace: true });
                  // alert('oi')
                }}

              showButtonContract={checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Contracts)}
              onClickButtonContract={() => navigate('/clientes/contratos')}
            />
          </Box>
          : ''

      }>

      {openModal && <BasicModal
        ITitle="Modal do Fernando"
        IMessage="Minha mensagem Generics "
        isCloseModal={() => setOpenModal(false)}
        isOpenModal={openModal}
        messageType={'info'}
        onclickConfirm={() => {
          alert('Confirmar');
          setOpenModal(false)
        }}
      />}

      {/* {isLoading && !busca?.trim()?.length ? <Loading />
        : */}
      <ProfileGuard requiredRole={PermissionRolesEnum.ClientsOptions} requiredAction={PermissionActionsEnum.View}>
        < TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: 'auto', padding: 4 }} aria-label="customized table">
          <Table aria-label="a dense table" >
            <TableHead>
              <TableRow >
                <TableCell colSpan={7} >
                  <ToolbarGridClientes
                    showInputBuscaGrid={(isSuperAdmin || permissionLevel == 2) && checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Filter)}
                    textSearchGrid={busca}
                    onChangeTextBuscaGrid={textBuscaGridHandler}
                    onClickTodos={() => setSearchParams({ busca, pagina: String(pagina), tipo: 'Todos', status: statuscliente, origem: origemcliente, dataini: dataIni, datafim: dataFim, clienteFilter: clienteFilter, uf: ufcliente }, { replace: true })}
                    onClickCorretor={() => setSearchParams({ busca, pagina: String(pagina), tipo: 'broker', status: statuscliente, origem: origemcliente, dataini: dataIni, datafim: dataFim, clienteFilter: clienteFilter, uf: ufcliente }, { replace: true })}
                    onClickImobiliaria={() => setSearchParams({ busca, pagina: String(pagina), tipo: 'estate', status: statuscliente, origem: origemcliente, dataini: dataIni, datafim: dataFim, clienteFilter: clienteFilter, uf: ufcliente }, { replace: true })}
                    onClickGestorContas={() => setSearchParams({ busca, pagina: String(pagina), tipo: 'Gestor', status: statuscliente, origem: origemcliente, dataini: dataIni, datafim: dataFim, clienteFilter: clienteFilter, uf: ufcliente }, { replace: true })}
                    selectTodos={tipocliente === 'Todos'}
                    selectCorretor={tipocliente === 'broker'}
                    selectImobiliaria={tipocliente === 'estate'}
                    selectGestorContas={tipocliente === 'Gestor'}
                    showBotaoCorretor={permissionLevel === 1 ? true : false}
                    showBotaoImobiliaria={permissionLevel === 1 ? true : false}
                    showBotaoGestorContas={false}
                    showBotaoTodos={permissionLevel === 1 ? true : false}


                  />
                </TableCell>
              </TableRow>

              <TableRow sx={{ background: '#f5fbfd' }}>
                <TableCell sx={{ color: "#7e7777b5" }}> Nome/Razão Social</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> Tipo</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> Endereço</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> Status</TableCell>
                <TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Cadastro</TableCell>
                <TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Origem</TableCell>
                <TableCell sx={{ color: "#7e7777b5" }}> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && (rows.map(row => (
                <TableRow key={row.id} hover >
                  <TableCell sx={{ fontWeight: "normal" }}>
                    {row.name}
                    <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                      {row.trade_name}
                    </Typography>
                    <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                      {row.document.length > 11 ?
                        row.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")
                        :
                        row.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")
                      }
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ fontWeight: "normal" }} >
                    <Typography variant="subtitle2" fontWeight={"normal"} >
                      {translate(row.clientType.description)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ color: "#959595", fontWeight: "normal" }} >
                    <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                      {row.address_street != '' && row.address_street != null && row.address_street != undefined ?
                        `${row.address_complement != '' && row.address_complement != null && row.address_complement != undefined ?
                          `${row.address_street} , ${row.address_number} - ${row.address_complement} - ${row.address_district}`
                          :
                          `${row.address_street} , ${row.address_number} - ${row.address_district}`
                        }` :
                        'Endereço não cadastrado'
                      }
                    </Typography>
                    <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                      {row.address_city != '' && row.address_city != null && row.address_city != undefined ?
                        `${row.address_city} - ${row.address_state}`
                        :
                        ''
                      }
                    </Typography>
                  </TableCell>

                  < TableCell sx={{ textAlign: "start" }}>
                    <IconButton size="small" sx={{ background: colorBackGround(row.status), borderRadius: "18px", paddingX: "15px", paddingY: "1px", marginBottom: "5px" }}>
                      <Icon sx={{ color: colorIcon(row.status), fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
                      <Typography variant="subtitle2" marginLeft={1} sx={{ color: colorText(row.status), fontWeight: "normal" }}>
                        {translate(row.status)}
                      </Typography>
                    </IconButton>
                  </TableCell>

                  <TableCell>
                    <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
                      {moment(row.createdAt).format("L")}
                    </Typography>
                    <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
                      {moment(row.createdAt).format("hh:mm:ss")}
                    </Typography>
                  </TableCell>
                  {row.created_from === "Web" && (
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal" }}>
                        <Tooltip title="Web Aplicação">
                          <Icon sx={{ color: "#959595", fontWeight: "normal" }}> desktop_windows_icon</Icon>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                  )}
                  {row.created_from === "Integracao" && (
                    <TableCell sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal" }}>
                        <Tooltip title="Integração">
                          <Icon sx={{ color: "#959595", fontWeight: "normal" }}> lan_icon</Icon>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                  )}
                  < TableCell sx={{ textAlign: "center" }}>
                    <IconButton size="small" sx={{ borderRadius: "18px", paddingX: "15px", paddingY: "1px", marginBottom: "5px" }}>
                      {row.created_from == 'web_app' ?
                        <Icon sx={{ color: row.status, fontWeight: "normal" }}> computer</Icon>
                        :
                        <Icon sx={{ color: row.status, fontWeight: "normal" }}> cloud</Icon>
                      }
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {/* pontuar aqui sobre o perfil ********************** */}


                    <ProfileGuard requiredRole={PermissionRolesEnum.ClientsOptions} requiredAction={PermissionActionsEnum.Update}>
                      <IconButton
                        disabled={registerClient.update == false}
                        size="small"
                        // onClick={() => navigate(`/clientes/detalhe/${row.id}`)}
                        onClick={() => clickEdit(row.id)}
                        sx={{ borderRadius: "18px" }}
                      >
                        <Icon sx={{ color: "#0170B3", fontWeight: "normal" }}> edit</Icon>
                        <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#0170B3", fontWeight: "normal" }}>
                          Editar
                        </Typography>
                      </IconButton>
                    </ProfileGuard>
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>

            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}

            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant="indeterminate" />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0) && (
                <TableRow>
                  <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                    <Pagination
                      page={pagina}
                      count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
                      onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString(), tipo: tipocliente, status: statuscliente, origem: origemcliente, dataini: dataIni, datafim: dataFim, clienteFilter: clienteFilter, uf: ufcliente, managerId }, { replace: true })}
                    />
                  </TableCell>
                  <TableCell colSpan={5} sx={{ borderBottom: "none" }} >
                    <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                      Exibindo {" "}
                      {(pagina * Environment.LIMITE_LINHAS) - (Environment.LIMITE_LINHAS - 1)}{" - "}
                      {(Environment.LIMITE_LINHAS * (pagina - 1)) +
                        (Environment.LIMITE_LINHAS * pagina) < totalCount ? Environment.LIMITE_LINHAS * pagina : totalCount} de
                      <Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
                        {" "}{totalCount} {" "}
                      </Typography>
                      registro(s)
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      </ProfileGuard>
      {/* } */}
    </LayoutBaseDePagina >
  );
};
