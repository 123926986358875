import { Box, Button, Grid, Icon, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ModalContext } from "../../../shared/components";
import { VForm, useVForm } from "../../../shared/forms";
import { useState } from "react";

interface ModalConfirmBilletProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	id: string;
	clientName: string;
	dueDate?: string;
	onchangeDueDate: (dueDate: string) => void;
}

export const ModalConfirmBillet: React.FC<ModalConfirmBilletProps> = ({ isOpen, onClose, onConfirm, clientName, dueDate, onchangeDueDate }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { formRef } = useVForm();
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const [dueDateModal, setDueDateModal] = useState("");


	return (
		<ModalContext
			isCloseModal={onClose}
			isOpenModal={isOpen}
			messageType={"question"}
			showButtonCancel={false}
			showButtonConfirm={false}
			textButtonConfirm={"Gerar"}
			textButtonCancel={"Voltar"}
			onclickConfirm={() => {
				onConfirm();
			}}
			ITitle={"Confirma geração do boleto ?"}
			IMessage={[
				<Box>
					<VForm
						id="formIdGenerateInvoice"
						placeholder="Your placeholder"
						ref={formRef}
						onSubmit={() => onConfirm()}
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
					>
						<Grid display={"flex"} flexDirection={"row"} gap={1}>
							<Grid item xs={1} sx={{ textAlign: "left" }}>
								<Typography variant="body1" sx={{ fontWeight: "bold" }}>
									Cliente:
								</Typography>
							</Grid>
							<Grid item xs={5} sx={{ alignItems: "left" }}>
								<Typography variant="body1">{clientName}</Typography>
							</Grid>
						</Grid>

						<TextField
							fullWidth
							label="Vencimento"
							name="dueDate"
							disabled={isLoading}
							size="small"
							type="date"
							InputLabelProps={{ shrink: true, required: true }}
							defaultValue={dueDate}
							sx={{ marginTop: 2, marginBottom: 2 }}
							onChange={(e) => {
								onchangeDueDate(e.target.value);
								setDueDateModal(e.target.value);
							}}
						/>

						<Grid
							container
							sm={12}
							sx={{ border: "none", display: "flex", justifyContent: "center" }}
							display={"flex"}
							flexDirection={smDown ? "column" : "row"}
							padding={2}
							gap={2}
						>
							<Button
								color="primary"
								disableElevation
								variant={"contained"}
								startIcon={<Icon> checkIcon</Icon>}
								onClick={() => {
									onConfirm();
								}}
							>
								{"Confirmar"}
							</Button>
							<Button color="primary" disableElevation variant={"outlined"} startIcon={<Icon> arrow_back_Ico</Icon>} onClick={onClose}>
								{"Voltar"}
							</Button>
						</Grid>
					</VForm>
				</Box>,
			]}
		/>
	);
};
