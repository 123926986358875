export const getFileExtension = (mimetype: string): string => {
  const mapper: Record<string, string> = {
    'application/pdf': '.PDF',
    'image/jpeg': '.JPEG',
    'image/jpg': '.JPG',
    'image/png': '.PNG',
    'application/msword': '.DOC',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.DOCX',
    'application/vnd.oasis.opendocument.text': '.ODT',
  }

  return mapper[mimetype] ?? 'TS';
}