import { Clear, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Icon, IconButton, InputAdornment, Paper, TextField, useTheme } from "@mui/material";
import { Environment } from "../../environment";

interface IToolbarGridProposalProps {
    textSearchGrid?: string;
    showInputSearchGrid?: boolean;
    onChangeTextSearchGrid?: (newtext: string) => void;
    onClearTextSearchGrid?: (emptyText: string) => void;

    textButtonActive?: string;
    showButtonActive?: boolean;
    onClickActive?: () => void;
    selectAtive?: boolean;

    textButtonEmitidas?: string;
    showButtonEmitidas?: boolean;
    onClickEmitidas?: () => void;
    selectEmitidas?: boolean;

    textButtonExpires?: string;
    showButtonExpires?: boolean;
    onClickExpires?: () => void;
    selectExpires?: boolean;


    textButtonExpired?: string;
    showButtonExpired?: boolean;
    onClickExpired?: () => void;
    selectExpired?: boolean;

    textButtonCanceled?: string;
    showButtonCanceled?: boolean;
    onClickCanceled?: () => void;
    selectCanceled?: boolean;

    textButtonSuspended?: string;
    showButtonSuspended?: boolean;
    onClickSuspended?: () => void;
    selectSuspended?: boolean;

    textButtonRenovated?: string;
    showButtonRenovated?: boolean;
    onClickRenovated?: () => void;
    selectRenovated?: boolean;

    textButtonAll?: string;
    showButtonAll?: boolean;
    onClickAll?: () => void;
    selectAll?: boolean;

}

export const ToolbarGridProposal: React.FC<IToolbarGridProposalProps> = ({
    textSearchGrid: textSearch = '',
    showInputSearchGrid: showInputSearch = true,
    onChangeTextSearchGrid: onChangeTextSearch,
    onClearTextSearchGrid: onClearTextSearchGrid,

    textButtonActive = 'Vigentes',
    showButtonActive = true,
    onClickActive,
    selectAtive = false,

    textButtonEmitidas = 'Emitidas',
    showButtonEmitidas = true,
    onClickEmitidas,
    selectEmitidas = false,

    textButtonExpires = 'A Expirar',
    showButtonExpires = true,
    onClickExpires,
    selectExpires = false,

    textButtonExpired = 'Expiradas',
    showButtonExpired = true,
    onClickExpired,
    selectExpired = false,

    textButtonCanceled = 'Canceladas',
    showButtonCanceled = true,
    onClickCanceled,
    selectCanceled = false,

    textButtonSuspended = 'Suspensas',
    showButtonSuspended = true,
    onClickSuspended,
    selectSuspended = false,

    textButtonRenovated = 'Renovadas',
    showButtonRenovated = true,
    onClickRenovated,
    selectRenovated = false,

    textButtonAll = 'Todos',
    showButtonAll = true,
    onClickAll,
    selectAll = true,

}) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            alignItems="start"
            height={theme.spacing(6)}
        >

            <ButtonGroup aria-label="outlined primary button group">
                {showButtonAll && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectAll ? "contained" : "outlined"}
                        onClick={onClickAll}
                        startIcon={<Icon>done_all_icon</Icon>}
                    >
                        {textButtonAll}
                    </Button>
                )}

                {showButtonEmitidas && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectEmitidas ? "contained" : "outlined"}
                        onClick={onClickEmitidas}
                        startIcon={<Icon>edit_note_outlined_icon</Icon>}
                    >
                        {textButtonEmitidas}
                    </Button>
                )}

                {showButtonActive && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectAtive ? "contained" : "outlined"}
                        onClick={onClickActive}
                        startIcon={<Icon>portrait_outlined_icon</Icon>}
                    >
                        {textButtonActive}
                    </Button>
                )}

                {showButtonRenovated && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectRenovated ? "contained" : "outlined"}
                        onClick={onClickRenovated}
                        startIcon={<Icon>refresh_outlined_icon</Icon>}
                    >
                        {textButtonRenovated}
                    </Button>
                )}

                {showButtonExpires && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectExpires ? "contained" : "outlined"}
                        onClick={onClickExpires}
                        startIcon={<Icon>access_time</Icon>}

                    >
                        {textButtonExpires}
                    </Button>
                )}
                {showButtonExpired && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectExpired ? "contained" : "outlined"}
                        onClick={onClickExpired}
                        startIcon={<Icon>pending_actionsOutlinedIcon</Icon>}

                    >
                        {textButtonExpired}
                    </Button>
                )}

                {showButtonSuspended && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectSuspended ? "contained" : "outlined"}
                        onClick={onClickSuspended}
                        startIcon={<Icon>pause_circle_outlineOutlinedIcon</Icon>}
                    >
                        {textButtonSuspended}
                    </Button>
                )}

                {showButtonCanceled && (
                    <Button
                        color="primary"
                        disableElevation
                        variant={selectCanceled ? "contained" : "outlined"}
                        onClick={onClickCanceled}
                        startIcon={<Icon>cancel_outlinedIcon</Icon>}
                    >
                        {textButtonCanceled}
                    </Button>
                )}
            </ButtonGroup>

            <Box flex={1} display="flex" justifyContent="end" sx={{ marginRight: '-10px' }}>
                {showInputSearch && (
                    <TextField
                        size="small"
                        value={textSearch}
                        placeholder={Environment.INPUT_BUSCA}
                        onChange={(e) => onChangeTextSearch?.(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="start"
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        sx={{ color: "#dbd5d5" }}
                                        onClick={() => onChangeTextSearch?.('')}
                                    >
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            </Box>
        </Box >
    );
}; 