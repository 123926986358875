import { Box, Button, CircularProgress, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { ProposalDocumentsService } from '../../../../../shared/services/api/proposal/ProposalDocumentsService';
import { AnalysisContractTypeEnum } from '../../../../../enums/analysis-documents';
import { ProposalFilesUploadArea } from './ProposalFilesUploadArea/ProposalFileUploadArea';
import { useToast } from '../../../../../shared/hooks';
import { ProposalFilesList } from './ProposalFilesList/ProposalFilesList';
import { GetDocumentTypesResponseType } from '../../../../../shared/services/api/documents/types';
import { DocumentsService } from '../../../../../shared/services/api/documents/DocumentsService';
import { Loading } from '../../../../../shared/components';

type ProposalFilesModalContentPropsType = {
  proposalId: string
}

enum ProposalFilesModalTabs {
  FileUpload,
  FileList,
}
export const ProposalFilesModalContent = ({ proposalId }: ProposalFilesModalContentPropsType) => {
  const [fileTypeId, setFileTypeId] = useState<string>();
  const [file, setFile] = useState<File>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documentTypes, setDocumentTypes] = useState<GetDocumentTypesResponseType[]>([]);
  const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ProposalFilesModalTabs>(ProposalFilesModalTabs.FileList);
  const toastHandler = useToast();

  useEffect(() => {
    DocumentsService.getProposalDocumentTypes().then((result) => {
      setDocumentTypes(result);
      setIsLoading(false);
    }).catch((error) => {
      toastHandler.present({ type: 'error', messageOrContent: error.message, position: 'top-center' })
    })
  }, [])

  const handleChangeType = (event: SelectChangeEvent) => {
    setFileTypeId(event.target.value);
  };

  const onFileUploadHandler = (file: File | undefined) => {
    setFile(file)
  };

  const submitFile = async () => {
    if (fileTypeId && file) {
      setIsUploading(true)

      try {
        await ProposalDocumentsService.uploadProposalDocument(proposalId, fileTypeId, file);
        toastHandler.present({ type: 'success', messageOrContent: 'Arquivo enviado com sucesso!', position: 'top-center' })
        setFileUploadedSuccessfully(true)
        setActiveTab(ProposalFilesModalTabs.FileList)
        setTimeout(() => {
          setFileUploadedSuccessfully(false)
        }, 250)
      } catch (e: any) {
        toastHandler.present({ type: 'error', messageOrContent: e.message, position: 'top-center' })
      } finally {
        setIsUploading(false)
      }
    }
  };

  const validToUpload = useMemo(() => {
    return file && fileTypeId
  }, [fileTypeId, file])

  const handleTabChange = (_: any, tab: ProposalFilesModalTabs) => {
    setActiveTab(tab)
  }

  return (
      <>
        <Box>
          <Tabs value={activeTab} onChange={handleTabChange} variant={'fullWidth'} indicatorColor={'secondary'} textColor={'secondary'}>
            <Tab value={ProposalFilesModalTabs.FileList} label='Lista de Arquivos' disabled={isUploading} />
            <Tab value={ProposalFilesModalTabs.FileUpload} label='Envio de Arquivo' />
          </Tabs>

          {activeTab === ProposalFilesModalTabs.FileUpload && (
            <Box style={{
              minWidth: '25vw'
            }}>
              <InputLabel id="file_type_label">Tipo de Arquivo</InputLabel>
              <Select
                labelId="file_type_label"
                id="file_type_dropdown"
                value={fileTypeId}
                size="small"
                label="Tipo de Arquivo"
                onChange={handleChangeType}
                style={{
                  minWidth: '100%'
                }
                }
              >
                {
                  documentTypes.map(type => {
                    return (
                      <MenuItem value={type.id}>{type.description}</MenuItem>
                    )
                  })
                }
              </Select>
              <ProposalFilesUploadArea onFileUploadHandler={onFileUploadHandler} isLoadingProp={isUploading} clear={fileUploadedSuccessfully} />
              <Button
                color="primary"
                disableElevation
                variant={"contained"}
                onClick={submitFile}
                disabled={!validToUpload || isUploading}
                style={{
                  marginTop: '16px',
                  width: '100%'
                }}
              >
                {'Confirmar'}
              </Button>
            </Box>
          )}
          {activeTab === ProposalFilesModalTabs.FileList && (
            <ProposalFilesList proposalId={proposalId}></ProposalFilesList>
          )}
        </Box>
      </>

  )
}