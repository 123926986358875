import { useVForm } from "../../shared/forms";

export const globalCheckCEP = (e: any) => {
    const { formRef } = useVForm();
    const cep = e.target.value.replace(/\D/g, '');
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => res.json()).then(data => {
            formRef.current?.setFieldValue("addressZipcode", data.cep);
            formRef.current?.setFieldValue("addressStreet", data.logradouro);
            formRef.current?.setFieldValue("residenceTypeo", '');
            formRef.current?.setFieldValue("addressDistrict", data.bairro);
            formRef.current?.setFieldValue("addressCity", data.localidade);
            formRef.current?.setFieldValue("addressState", data.uf);
            formRef.current?.setFieldValue("addressIBGE", data.ibge);
        });
}