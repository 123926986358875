import { Replay } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { ModalContext, ToastContent } from "../../../../shared/components";
import { useToast } from "../../../../shared/hooks";
import { clientService } from "../../../../shared/services/api/client/clientService";

interface ContractsProps {
	client: string;
}

export default function Contracts({ client }: ContractsProps) {
	const [openModalConfirm, setOpenModalConfirm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const toastHandler = useToast();

	const handleOpenModalConfirm = () => {
		setOpenModalConfirm(true);
	};

	const handleCloseModalConfirm = () => {
		setOpenModalConfirm(false);
	};

	const handleResendContract = (clientID: string) => {
		setIsLoading(true);

		clientService
			.resendContract(clientID)
			.then(() => {
				handleCloseModalConfirm();
				setIsLoading(false);
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title={"Sucesso"} content={"Contrato reenviado com sucesso!"} />,
				});
			})
			.catch(() => {
				setIsLoading(false);
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao reenviar contrato!"} />,
				});
			});
	};

	return (
		<div>
			<Button variant="contained" endIcon={<Replay />} onClick={handleOpenModalConfirm}>
				Reenviar último contrato
			</Button>

			{openModalConfirm && (
				<ModalContext
					isCloseModal={handleCloseModalConfirm}
					isOpenModal={openModalConfirm}
					messageType={"alert"}
					showTitleIcon={false}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					IMessage={[
						<Box display={"flex"} gap={3}>
							<Button variant="outlined" onClick={handleCloseModalConfirm}>
								Cancelar
							</Button>
							<Button variant="contained" onClick={() => handleResendContract(client)}>
								Reenviar
							</Button>
							,
						</Box>,
					]}
					ITitle="Deseja realmente reenviar o contrato?"
				/>
			)}
		</div>
	);
}
