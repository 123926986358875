
import { string } from 'yup/lib/locale';
import { Environment } from '../../../environment';
import { Api } from "../axios-config";
import { format } from 'date-fns';
import { ISinisterValuesUI } from '../../../../pages/sinister/components';
import { promises } from 'dns';
import { SinisterDocumentType } from './types';


export interface ISinisterList {
    id: string,
    status: string,
    busyProperty?: string,
    email?: string,
    note?: string,
    finalizedNote?: string,
    reportedAt?: string,
    createdAt?: string,
    updatedAt?: string,
    proposalId?: string,
    clientId?: string,
    userId?: string,
    responsibleId?: string,
    peopleId?: string,
    responsible_at?: string,
    finalizedAt?: string,
    anceledAt?: string,
    imiteAt?: string,
    client: {
        id?: string,
        document: string,
        type_person?: string,
        status?: string,
        email?: string,
        creci?: string,
        name?: string,
        trade_name?: string,
        responsible_name?: string,
        responsible_document?: string;
        phone?: string,
        mobilePhone?: string,
        address_zipcode?: string,
        address_street?: string,
        address_number?: string,
        address_complement_type?: string,
        address_complement?: string,
        address_district?: string,
        address_city?: string,
        address_state?: string,
        address_ibge?: string,
        wallet_properties_count?: string,
        accept_terms_service?: string,
        residential_fee?: string,
        commercial_fee?: string,
        created_from?: string,
        lmi?: string,
        invoice_due_date?: string,
        agency_number?: string,
        bank_code?: string,
        bank_manager?: string,
        agency_digit?: string,
        account_number?: string,
        account_digit?: string,
        pix_key?: string,
        pix_key_type?: string,
        created_at?: string,
        updated_at?: string,
        typeId?: string,
        clientType?: {
            id?: string,
            description?: string,
            status?: string,
            level?: string,
            created_at?: string,
            updated_at?: string,
        }
    },
    proposal: {
        certificateNumber?: string,
        id: string,
        clientId?: string,
        analysisId?: string,
        status?: string,
        name: string,
        motherName?: string,
        type_person?: string,
        document: string,
        birthDate?: string,
        mobilePhone?: string,
        createdAt?: string,
        updatedAt?: string,
        canceledAt?: string,
        termBegin?: string,
        expiresAt?: string,
        expiredAt?: string,
        installmentsNumber?: string,
        createdFrom?: string,
        employmentRelationShip?: string,
        maritalStatus?: string,
        wifeName?: string,
        email?: string,
        phone?: string,
        rentalCost?: string,
        sendSerasa?: string,
        propertyType?: string,
        addressZipcode?: string,
        addressStreet?: string,
        addressResidenceType?: string,
        addressNumber?: string,
        addressComplement?: string,
        addressDistrict?: string,
        addressCity?: string,
        addressState?: string,
        addressIbge?: string,
        serasaId?: string,
        serasaResponse?: string,
        serasaAnalysisStatus?: string,
        paymentMethod?: string,
        rentMonthsCount?: string,
        residentialFee?: string,
        commercialFee?: string,
        rentAmount?: string,
        condominiumAmount?: string,
        electricEnergyAmount?: string,
        waterAmount?: string,
        gasAmount?: string,
        iptuAmount?: string,
        // analysisPlan?: string,
        assuranceMonthAmount?: string,
        lmi?: string,
        lmiAmount?: string,
        lmiBalance?: string,
        userId?: string,
        relation?: string,
        planId?: string,
        peopleId?: string,
        reasonId?: string,
        lessorName?: string,
        lessorCpf?: string,
        propertyId?: string,
        lessorTypePerson?: string,
        lessorBirthDate?: string,
        lessorEmail?: string,
        lessorPhone?: string,
        lessorMobilePhone?: string
    }
    sinisterResponsible: {
        name: string,
        id: string,
        email: string
    }
}

export interface ISinisterDetails {
    id: string,
    status?: string,
    busyProperty?: string,
    email?: string,
    note?: string,
    finalizedNote?: string,
    reportedAt?: string,
    createdAt?: string,
    updatedAt?: string,
    proposalId?: string,
    clientId?: string,
    userId?: string,
    finalizedAt?: string,
    anceledAt?: string,
    imiteAt?: string,
    client?: {
        id?: string,
        document?: string,
        type_person?: string,
        status?: string,
        email?: string,
        creci?: string,
        name?: string,
        trade_name?: string,
        responsible_name?: string,
        responsible_document?: string;
        phone?: string,
        mobilePhone?: string,
        address_zipcode?: string,
        address_street?: string,
        address_number?: string,
        address_complement_type?: string,
        address_complement?: string,
        address_district?: string,
        address_city?: string,
        address_state?: string,
        address_ibge?: string,
        wallet_properties_count?: string,
        accept_terms_service?: string,
        residential_fee?: string,
        commercial_fee?: string,
        created_from?: string,
        lmi?: string,
        invoice_due_date?: string,
        agency_number?: string,
        bank_code?: string,
        bank_manager?: string,
        agency_digit?: string,
        account_number?: string,
        account_digit?: string,
        pix_key?: string,
        pix_key_type?: string,
        created_at?: string,
        updated_at?: string,
        typeId?: string,
        clientType?: {
            id?: string,
            description?: string,
            status?: string,
            level?: string,
            created_at?: string,
            updated_at?: string,
        }
    },
    proposal?: {
        certificateNumber?: string,
        id?: string,
        clientId?: string,
        analysisId?: string,
        status?: string,
        name?: string,
        motherName?: string,
        type_person?: string,
        document: string,
        birthDate?: string,
        mobilePhone?: string,
        createdAt?: string,
        updatedAt?: string,
        canceledAt?: string,
        termBegin?: string,
        expiresAt?: string,
        expiredAt?: string,
        installmentsNumber?: string,
        createdFrom?: string,
        employmentRelationShip?: string,
        maritalStatus?: string,
        wifeName?: string,
        email?: string,
        phone?: string,
        rentalCost?: string,
        sendSerasa?: string,
        propertyType?: string,
        addressZipcode?: string,
        addressStreet?: string,
        addressResidenceType?: string,
        addressNumber?: string,
        addressComplement?: string,
        addressDistrict?: string,
        addressCity?: string,
        addressState?: string,
        addressIbge?: string,
        serasaId?: string,
        serasaResponse?: string,
        serasaAnalysisStatus?: string,
        paymentMethod?: string,
        rentMonthsCount?: string,
        residentialFee?: string,
        commercialFee?: string,
        rentAmount?: string,
        condominiumAmount?: string,
        electricEnergyAmount?: string,
        waterAmount?: string,
        gasAmount?: string,
        iptuAmount?: string,
        // analysisPlan?: string,
        assuranceMonthAmount?: string,
        lmi?: string,
        lmiAmount?: string,
        lmiBalance?: string,
        userId?: string,
        relation?: string,
        planId?: string,
        peopleId?: string,
        reasonId?: string,
        lessorName?: string,
        lessorCpf?: string,
        propertyId?: string,
        lessorTypePerson?: string,
        lessorBirthDate?: string,
        lessorEmail?: string,
        lessorPhone?: string,
        lessorMobilePhone?: string
    }
}

export interface ISinisterValues {
    sinisterId: string,
    status: string,
    typeId: string,
    value: string,
    valueApproved?: string,
    dueDate: string,
    reference: string,
    reason: string,
    reasonRefused?: string,
    reasonCancel?: string,
    reasonApproved?: string,
    reasonContested?: string,
    id?: string,
    file?: File
    sinisterValuesDocuments?: [],
    // description?: string,
    // type?: string,
    // value?: Number,
    // date?: string,
    // dueDate?: string,
    // paymentDate?: string,
    // sinisterId?: string,
    // status: string,
    createdAt?: string,
    // reference?: string,
}

export type TSinisterDocument = {
    id?: string
    originalName?: string,
    mimetype?: string,
    docType: string,
    checkListId?: number,
    docKey?: string
    filename?: string,
    createdAt?: string
}

export interface ISinistercheckList {
    Id: string,
    propertyType: string,
    description: string,
    ocupation: string,
    open: string,
    busy: string,
    quant: string;
    createdAt: string,
    updatedAt: string,
    status: string,
    openView: string,
    busyView: string,
    sendFile: string,
    repair: string
    sinisterDocuments: SinisterDocumentType[] | undefined
}

export interface IValuesSinisterById {
    id: string,
    status: string,
    typeId: string,
    description: string,
    reference: string,
    sinisterId: string,
    provisionId: string,
    value: string,
    dueDate: string,
    valuePaid: string,
    valueApproved: string,
    paymentDate: string,
    approvedAt: string,
    canceledAt: string,
    reason: string,
    reasonApproved: string,
    reasonCancel: string,
    reasonRefused: string,
    reasonContested: string,
    userId: string,
    validateUserId: string,
    validateAt: string,
    createdAt: string,
    updatedAt: string,
    sinisterType: string,
}

type TValueSinisterCountValue = {
    data: IValuesSinisterById[];
    totalCount: number;
}

export interface IISinistercheckList {
    data: ISinistercheckList[];
    totalCount: number;
    message?: string;
}

type TSinisterTotalCountValue = {
    data: ISinisterValuesUI[];
    totalCount: number;
}

export interface ISinisterCreate {
    id?: string,
    proposalId: string,
    busyProperty: string,
    status: string
}
export interface ISinisterCount {
    Status: string,
    quantidade: Number,
    aluguel: Number,
    lmi_total: Number,
    lmi_saldo: Number,
    valor_parcela: Number
};

type TSinisterTotalCount = {
    data: ISinisterList[];
    totalCount: number;
}

export interface ISinisterCountPorMes {
    mes: string,
    ano: Number,
    quantidade: Number,
    aluguel: Number,
    valorgarantia: Number,
    totalgarantia: Number,
    lmi: Number,
    lmi_amount: Number,

};

export interface ITKMedio {
    STATUS: string,
    quantidade: number,
    aluguel: number,
    total_garantia: number,
    ticket_medio: number,
    prazo_medio_duracao: number,
    soma_garantia: number,
    tkmedio_garantia: number,
    soma_lmi: number,
    soma_lmi_amount: number,
    tkmedio_soma_lmi: number,
    tkmedio_soma_lmi_amount: number

};

export interface IDuracaoVigencia {
    duracao: number,
    quantidade: number,
    lmi_total: number,
    valor_parcela: number,
};
export interface ITempoMedio {
    quantidade: number,
    duracao_real: number,
    media_real: number,
    duracao_vigencia: number,
    media_vigencia: number,
};

export interface ICancelAntes {
    quantidade: number,
    saldo: number,
    total: number,
    aluguel: number,
    parcela: number
};

export interface IGarantiaTaxas {
    taxa: number,
    quantidade: number
};

type TSinisterPorMes = {
    data: ISinisterCountPorMes[];
    totalCount: number;
}

export type TCountSinister = {
    total: number,
}
export interface ITotalStatus {
    status: string,
    valor: number,
    valorAprovado: number
}

export interface ITotalLmi {
    proposalId: string,
    lmiTotal: number,
    valor: number,
    valorAprovado: number,
    saldo: number
}

export interface ITotalStatusObj {
    status: ITotalStatus[]
}

export interface ICreateSinisterValues {
    typeId: string;
    value: string;
    dueDate: string;
    reference: string;
}

export interface IApprovedSinisterValues {
    valueApproved: string;
    reasonApproved: string;
}

export interface ICanceledSinisterValues {
    reasonCancel: string;
}

export interface ICanceledSinister {
    canceledReason?: string;
    canceledObs?: string;

}

export interface IContestedSinisterValues {
    reasonContested: string;
}

export interface IRefusedSinisterValues {
    reasonRefused: string;
}

export type ISinisterValueId = {
    id: string
}
export interface ICreateBordero {
    sinisterValuesRequest: ISinisterValueId[],
    dueDate: string,
    sinisterId: string,
    clientId: string
}

const getTotal = async (): Promise<TCountSinister | Error> => {
    try {
        const { data } = await Api().get(`/sinister/total`);
        if (data) {
            return data;
        }
        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const getAll = async (page = 1, busca = '', origem = '', status = '', typePeriod = '', imovel = '', typeField = '', clientFilter = '', dataini = '', datafim = '', managerId = '', lmiSaldo = ''): Promise<TSinisterTotalCount | Error> => {
    try {
        const urlRelativa = `/Sinister?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&nome_like=${busca}&origem=${origem}&status=${status}&typePeriod=${typePeriod}&typeimobile=${imovel}&typeField=${typeField}&clientId=${clientFilter}&managerId=${managerId}&dataini=${dataini}&datafim=${datafim}&lmiSaldo=${lmiSaldo}`;
        const { data, headers } = await Api().get(urlRelativa);
        if (data.results) {
            return {
                data: data.results,
                totalCount: data.total,
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};


const getValuesSinisterById = async (sinisterId: string) => {
    const { data } = await Api().get(`/sinister-values/provision/${sinisterId}`);
    console.log(data);
    if (data) {
        return data.results;
    }
    return [];
}


const getSinisterCheckList = async (id = '', ocupacao = ''): Promise<IISinistercheckList | undefined> => {
    try {
        const urlRelativa = `/sinister-check/uncheck/${id}/${ocupacao}`;
        const { data } = await Api().get(urlRelativa);

        if (data.results) {
            return {
                data: data.results,
                totalCount: data.total,
            }
        }
    } catch (error) {
        console.error(error);
        return undefined
    }
};


const getSinisterDocumentByList = async (sinisterId = '', list = ''): Promise<SinisterDocumentType[] | undefined> => {
    try {
        const urlRelativa = `/sinister-document/search/${sinisterId}/${list}`;
        const { data } = await Api().get(urlRelativa);
        if (data) {
            return data
        }
    } catch (error) {
        console.error(error);
        return undefined
    }
};


const getAllValues = async (page = 1, busca = '', tipo = '', status = '', id = ''): Promise<TSinisterTotalCountValue | Error> => {
    try {
        const urlRelativa = `/sinister-values?_page=${page}&_limit=${Environment.LIMITE_LINHAS_VALORES_BORDEROS}&nome_like=${busca}&id=${id}&tipo=${tipo}&status=${status}`;
        const { data, headers } = await Api().get(urlRelativa);
        if (data.results) {
            return {
                data: data.results,
                totalCount: data.total,
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const getById = async (id: string): Promise<ISinisterList | Error> => {
    try {
        const { data } = await Api().get(`/sinister/${id}`);
        if (data) {
            return data;
        }
        return new Error('Erro ao consultar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};


const create = async (busyProperty: string, proposalId: string, status: string, clientId: string): Promise<ISinisterDetails | Error> => {
    const request = {
        proposalId: proposalId,
        status: 'sketch',
        busyProperty: busyProperty,
        clientId: clientId
    }
    try {
        const { data } = await Api().post(`/Sinister/`, request);
        if (data) {
            return data;
        }
        else {
            return new Error('Erro ao criar o registro mensagem1.');
        }
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const updateById = async (id: string, dados: ISinisterList): Promise<void | Error> => {
    try {
        await Api().put(`/Sinister/${id}`, dados);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string): Promise<void | Error> => {
    try {
        await Api().delete(`/Sinister/${id}`);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};

const SinisterVigentes = async (): Promise<ISinisterCount | Error> => {
    try {
        const { data, headers } = await Api().get(`/Sinistervigentes`);
        if (data) {
            return {
                Status: data[0].Status,
                quantidade: data[0].quantidade,
                aluguel: data[0].aluguel,
                lmi_total: data[0].lmi_total,
                lmi_saldo: data[0].lmi_saldo,
                valor_parcela: data[0].valor_parcela
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const SinisterARenovar = async (): Promise<ISinisterCount | Error> => {
    try {
        const { data, headers } = await Api().get(`/Sinisterarenovar`);
        if (data) {
            return {
                Status: data[0].Status,
                quantidade: data[0].quantidade,
                aluguel: data[0].aluguel,
                lmi_total: data[0].lmi_total,
                lmi_saldo: data[0].lmi_saldo,
                valor_parcela: data[0].valor_parcela
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const SinisterCanceladas = async (): Promise<ISinisterCount | Error> => {
    try {
        const { data, headers } = await Api().get(`/Sinistercanceladas`);
        if (data) {
            return {
                Status: data[0].Status,
                quantidade: data[0].quantidade,
                aluguel: data[0].aluguel,
                lmi_total: data[0].lmi_total,
                lmi_saldo: data[0].lmi_saldo,
                valor_parcela: data[0].valor_parcela
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const SinisterExpiradas = async (): Promise<ISinisterCount | Error> => {
    try {
        const { data, headers } = await Api().get(`/Sinisterexpiradas`);
        if (data) {
            return {
                Status: data[0].Status,
                quantidade: data[0].quantidade,
                aluguel: data[0].aluguel,
                lmi_total: data[0].lmi_total,
                lmi_saldo: data[0].lmi_saldo,
                valor_parcela: data[0].valor_parcela
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};


const SinisterPorMes = async (dataini = '', datafim = ''): Promise<ISinisterCountPorMes[] | Error> => {
    try {
        const urlRelativa = `/SinisterPorMes?dataini=${dataini}&datafim=${datafim}`;
        const { data, headers } = await Api().get(urlRelativa);

        if (data) {
            return data
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const SinisterTKMedio = async (dataini = '', datafim = ''): Promise<ITKMedio | Error> => {
    try {
        const urlRelativa = `/Sinisterticketmedio?imobiliaria=&dataini=${dataini}&datafim=${datafim}`;
        const { data, headers } = await Api().get(urlRelativa);
        if (data) {
            return data[0];
        }

        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};


const SinisterDuracaoVigencia = async (dataini = '', datafim = ''): Promise<IDuracaoVigencia[] | Error> => {
    try {
        const urlRelativa = `/Sinisterduracaodevigencias?dataini=${dataini}&datafim=${datafim}`;
        const { data, headers } = await Api().get(urlRelativa);
        if (data) {

            return data.results
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const SinisterTempoMedio = async (dataini = '', datafim = ''): Promise<ITempoMedio[] | Error> => {
    try {
        const urlRelativa = `/SinisterPorMes?dataini=${dataini}&datafim=${datafim}`;
        const { data, headers } = await Api().get(urlRelativa);

        if (data) {
            return data.results
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const SinisterCanceladasAntes = async (): Promise<ICancelAntes | Error> => {
    try {
        const { data, headers } = await Api().get(`/Sinisterexpiradas`);
        if (data) {
            return {
                quantidade: data[0].quantidade,
                saldo: data[0].saldo,
                total: data[0].total,
                aluguel: data[0].aluguel,
                parcela: data[0].parcela
            };
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const SinisterTaxas = async (dataini = '', datafim = ''): Promise<IGarantiaTaxas[] | Error> => {
    try {
        const urlRelativa = `/Sinistergrouptaxa?dataini=${dataini}&datafim=${datafim}`;
        const { data, headers } = await Api().get(urlRelativa);
        if (data.results) {
            return data.results
        }
        return new Error('Erro ao listar registros.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const createValue = async (dados: ISinisterValues, file: File): Promise<any> => {
    const { data } = await Api().post('/sinister-values', { ...dados, file: file }, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return data as any;
};

const updateValueApproved = async (id: string, dados: IApprovedSinisterValues): Promise<any> => {
    const data = await Api().patch(`/sinister-values/approved/${id}`,
        {
            ...dados,
            status: 'approved',
            approvedAt: format(new Date(), 'yyyy-MM-dd 03:00:00')
        });

    return data as any;
};


const updateStatusSinister = async (id: string, dados: { status: string }): Promise<any> => {
    const data = await Api().patch(`/sinister/updateStatus/${id}`,
        {
            ...dados,
        });

    return data as any;
};

const updatePropertySinister  = async (id: string, dados: { busyProperty: string }): Promise<any> => {
    const data = await Api().patch(`/sinister/updateusoimovel/${id}`,
        {
            ...dados,
        });
    return data as any;
};

const updateValueCanceled = async (id: string, dados: ICanceledSinisterValues): Promise<any> => {
    const data = await Api().patch(`/sinister-values/cancel/${id}`,
        {
            ...dados,
            status: 'canceled',
            canceledAt: format(new Date(), 'yyyy-MM-dd 03:00:00')
        });
    return data as any;
};

const updateValueContested = async (id: string, dados: IContestedSinisterValues): Promise<any> => {
    const data = await Api().patch(`/sinister-values/contest/${id}`,
        {
            ...dados,
            status: 'contested',
        });
    return data as any;
};

const updateValueRefused = async (id: string, dados: IRefusedSinisterValues): Promise<any> => {
    const data = await Api().patch(`/sinister-values/refuse/${id}`,
        {
            ...dados,
            status: 'refused',
        });
    return data as any;
};

const totalStatus = async (sinisterId: string,): Promise<any> => {
    const data = await Api().get(`/sinister-values/totalStatus/${sinisterId}`);
    return data as any
};


const totalLmi = async (sinisterId: string,): Promise<any> => {
    const data = await Api().get(`/sinister-values/lmiBalance/${sinisterId}`);
    return data as any
};


const updateResponsible = async (sinisterId: string, userId: string): Promise<any> => {
    const dados = {
        "responsibleId": userId
    }

    const data = await Api().patch(`/sinister/responsible/${sinisterId}`,
        {
            ...dados,
        });
    return true;
};

const getListOff = async (sinisterId: string) => {
    const data = await Api().get(`/sinister/list-required-off/${sinisterId}`);
    return data;
}

const getDownloadAll = async (sinisterId: string) => {
    try {
        const response = await Api().get(`/sinister-document/download/${sinisterId}`, {
            responseType: "blob",
        });
        if (response.data) {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `arquivos_${sinisterId}.zip`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    } catch (error) {
        console.error('Erro ao fazer o download:', error);
    }
}

const confirmOpenSinister = async (sinisterId: string): Promise<any> => {

    const data = await Api().patch(`/sinister/open/${sinisterId}`);
    // return data as any;
    return data;
};


const generateBordero = async (id: string, dados: ICreateBordero): Promise<any> => {
    console.log('GenerateBordero....966', id, dados)
    const { data } = await Api().post(`/sinister-values/generate/provision/${id}`, { ...dados });
    return true;
}


const cancelSinister = async (id: string, dados: ICanceledSinister): Promise<any> => {
    try {
        const data = await Api().patch(`/sinister/cancel/${id}`,
            {
                ...dados,
                canceledAt: format(new Date(), 'yyyy-MM-dd 03:00:00')
            });
        return data as any;
    } catch (error) {
        console.error('Erro na chamada à API:', error);
        return undefined;
    }
};

export const SinisterService = {
    getAll,
    getAllValues,
    getById,
    create,
    updateById,
    deleteById,
    SinisterVigentes,
    SinisterARenovar,
    SinisterCanceladas,
    SinisterExpiradas,
    SinisterPorMes,
    SinisterTKMedio,
    SinisterDuracaoVigencia,
    SinisterTempoMedio,
    SinisterCanceladasAntes,
    SinisterTaxas,
    getSinisterCheckList,
    getTotal,
    createValue,
    updateValueApproved,
    updateStatusSinister,
    updatePropertySinister,
    updateValueCanceled,
    updateValueContested,
    updateValueRefused,
    totalStatus,
    totalLmi,
    updateResponsible,
    getListOff,
    confirmOpenSinister,
    getDownloadAll,
    generateBordero,
    getValuesSinisterById,
    cancelSinister,
    getSinisterDocumentByList

};