import { Grid, Paper } from '@mui/material';
import logoTitle from "../../../assets/logo_title.png";
import './Loading.css';

interface ILoadingProps {
    marginTop?: any;
    isLoading?: boolean;
}

export const Loading: React.FC<ILoadingProps> = ({ marginTop = null, isLoading = false }) => {


    return (

        <Grid container item component={Paper} width="99%"
            style={{ display: isLoading ? 'flex' : 'none' }}
            marginTop={marginTop}
            height="99%" display="flex" justifyContent="center"
            className='backGround-color-change'
            alignItems="center">
            <img style={{ width: "50px", display: isLoading ? 'flex' : 'none' }} className='animatingImg' alt="Garantti Logo" src={logoTitle} />
        </Grid>

    );

};