import { Box, Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import React, { useState } from 'react';
import * as yup from 'yup';
import { useReset } from '../../../../context'
import { ResetPasswordService } from '../../../services/api/reset-password/ResetPasswordService';
import { BasicModal } from '../../VModal/VModal';

export const ForgetPasswordButton: React.FC = () => {
  const modalText = 'Foi enviado um link com codigo de recuperação para o email cadastrado, verifique sua caixa de entrada e ou lixo/spam';
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {setRecoverPassword, setResetPassword, email} = useReset();
  const forgetPasswordSchema = yup.object().shape({
    email: yup.string().email().required(),
  })
  const handleRecover = async () => {
    try{
      const data = {email}
      const response = await ResetPasswordService.save(data);
      if(response === 201){
        // setRecoverPassword(true);
        // setResetPassword(false);
        setIsOpenModal(true);
      }

    } catch(error) {
      console.error(error);
      const err = error as Error;
      throw new Error(err.message);
    }

  }

  const confirmModal = () => {
    setRecoverPassword(true);
    setResetPassword(false);
    setIsOpenModal(false);
  }
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' width='100%'>
      <BasicModal 
      isOpenModal={isOpenModal} 
      messageType={'success'} 
      ITitle='Email Enviado'
      IMessage={modalText}
      textButtonConfirm='Ok'
      onclickConfirm={confirmModal}
      />
      <Button
          variant='contained'
          onClick={handleRecover}
          sx={{ width: 'inherit', background: '#1E325C', margin: '0.25rem 0' }}
      >
          Recuperar
      </Button>
    
</Box>
  )
}
