
import * as React from 'react';
import Modal from '@mui/material/Modal';
import { VTextField, VForm, useVForm, IVFormErros } from "../../forms";
import { useEffect, useState } from "react";
import {
    Box, Grid, Icon, InputAdornment, LinearProgress, MenuItem, Paper, Table, TableContainer,
    Typography, Button, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch,
    InputLabel,
    Select,
    Input,
} from "@mui/material";
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { AutoCompletePlan } from '../autoCompletePlan/AutoCompletePlan';
import { VMaskTextField } from '../../forms/VMaskTextField';
import { clientService, IPlanRelation } from '../../services/api/client/clientService';
import { returnMessageError } from '../../utils/returnMessageError';
import { useToast } from "../../../shared/hooks";
import { ToastContent } from "../../../shared/components";
import { useProfile } from '../../../context/ProfileContext';
import { ICommercialGrid } from '../../../pages/client/components/RelationComponents/Commercial';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '1px solid #605e5e',
    boxShadow: 24,
    p: 2,
    borderRadius: '6px'
};

const typeMessage: ITypeMessage[] = [
    {
        type: 'error',
        title: 'Erro',
        icon: 'cancel_Outlined_Icon',
        color: '#e87b9e'
    },
    {
        type: 'alert',
        title: 'Atenção',
        icon: 'info_Outlined_Icon',
        color: '#ffc107'
    },
    {
        type: 'success',
        title: 'Sucesso',
        icon: 'check_circle_Outline_Icon',
        color: '#39c463'
    },
    {
        type: 'info',
        title: 'Informação',
        icon: 'error_outline_Outlined_Icon',
        color: '#3f51b5'
    },
];

interface ITypeMessage {
    title: string;
    icon: string;
    color: string;
    type: string;
}

interface IPlanoModalProps {
    textButtonConfirm?: string;
    showButtonCancel?: boolean;
    isOpenModal: boolean;
    messageType: string;
    data: ICommercialGrid;
    onclickConfirm?: () => void;
    onclickCancel?: () => void;
    isCloseModal?: () => void;
    clientPlan?: string;
}

interface IFormData {
    lmi: string;
    commercialFee: string;
    residentialFee: string;
    planId: string;
    clientId: string;
    status: string;
}

const removeMask = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/[^0-9]/g, "")
        .replace(/(\d{1,2})$/, '.$1')
};

//const lmiArray = Array.from({ length: 72 }, (_, index) => index + 1);
const lmiArray = Array.from({ length: 72 }, (_, index) => index + 1).filter((value) => value >= 12);
export const PlanoModal: React.FC<IPlanoModalProps> = ({ textButtonConfirm,
    showButtonCancel, isOpenModal, messageType, clientPlan, data, isCloseModal, onclickConfirm }) => {
    showButtonCancel = true;
    //textButtonConfirm = 'Adicionar';


    const setTypeMessage = () => {
        switch (messageType) {
            case 'error':
                return typeMessage[0];
            case 'alert':
                return typeMessage[1];
            case 'success':
                return typeMessage[2];
            case 'info':
                return typeMessage[3];
            default: return typeMessage[0];
        }
    }

    const { formRef } = useVForm();
    const messageStyle: ITypeMessage = setTypeMessage();
    const { id = 'novo' } = useParams<'id'>();
    const [isLoading, setIsLoading] = useState(false);
    const [modified, setModified] = useState(true);
    const [isCheckAnalysis, setIsCheckAnalysis] = useState(data.flagAnalysis == '0' ? false : true);
    const [isCheckRenovation, setIsCheckRenovation] = useState(data.flagRenovation == '0' ? false : true);
    const [isCheckRateRent, setIsCheckRateRent] = useState(data.flagRateRent == '0' ? false : true);
    const [isCheckRateCharge, setIsCheckRateCharge] = useState(data.flagRateCharge == '0' ? false : true);
    const toastHandler = useToast();
    const [showButtonAdd, setShowButtonAdd] = useState(data.id != 'novo' ? true : false);
    const [rateCalc, setRateCalc] = useState<any>("");
    const { permissionLevel, isSuperAdmin } = useProfile();
    const [formData, setFormData] = useState({} as ICommercialGrid);
    const handleChangeAnalysis = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckAnalysis(event.target.checked);

    };
    const handleChangeRenovation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckRenovation(event.target.checked);
    };

    const handleChangeRateRent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckRateRent(event.target.checked);

    };
    const handleChangeRateCharge = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheckRateCharge(event.target.checked);
    };

    useEffect(() => {
        if (data && data.id) {
            setFormData(
                {
                    status: '',
                    id: data.id,
                    plan: data.plan,
                    planId: data.planId,
                    clientId: data.clientId,
                    lmi: data.lmi,
                    bonusAmount: data.bonusAmount,
                    residentialFee: data.residentialFee,
                    commercialFee: data.commercialFee,
                    flagAnalysis: data.flagAnalysis,
                    flagRenovation: data.flagRenovation,
                    flagRateRent: data.flagRateRent,
                    flagRateCharge: data.flagRateCharge,
                    productId: '',
                    createdAt: '',
                    updatedAt: '',
                    coverage: data.coverage,
                    setupTransfer: data.setupTransfer,

                });
            console.log(data);


            console.log('173...', formData);
        }
    }, [data]);

    useEffect(() => {
        if (formData?.id && formRef.current) {
            formRef.current?.setFieldValue('planId', formData.planId);
            formRef.current?.setFieldValue('clientId', formData.clientId);
            formRef.current?.setFieldValue('lmi', formData.lmi);
            formRef.current?.setFieldValue('bonusAmount', formData.bonusAmount);
            formRef.current?.setFieldValue('residentialFee', formData.residentialFee);
            formRef.current?.setFieldValue('commercialFee', formData.commercialFee);
            // formRef.current?.setFieldValue('flagAnalysis',formData.flagAnalysis == '1' ? true : false);
            // formRef.current?.setFieldValue('flagRenovation',formData.flagRenovation == '1' ? true : false);
            // formRef.current?.setFieldValue('flagRateRent',formData.flagRateRent == '1' ? true : false);
            // formRef.current?.setFieldValue('flagRateCharge',formData.flagRateCharge == '1' ? true : false);
            formRef.current?.setFieldValue('coverage', formData.coverage);
            formRef.current?.setFieldValue('setupTransfer', formData.setupTransfer);
            setIsCheckAnalysis(data.flagAnalysis == '1' ? true : false);
            setIsCheckRenovation(data.flagRenovation == '1' ? true : false);
            setIsCheckRateRent(data.flagRateRent == '1' ? true : false);
            setIsCheckRateCharge(data.flagRateCharge == '1' ? true : false);
            //else {
            //     console.error('Form reference is not defined.');
            // }

            // formRef.current?.setData({
            //     // id: data.id,
            //    // status: data.status,
            //     planId: data.planId,
            //     clientId: data.clientId,
            //     lmi: data.lmi,
            //     residentialFee: data.residentialFee,
            //     commercialFee: data.commercialFee,
            //     // bonusAmount: data.bonusAmount,
            //     // productId: data.productId,
            //     // createdAt: data.createdAt,
            //     // updatedAt: data.updatedAt,
            //     flagAnalysis: data.flagAnalysis? '1' : '0',
            //     flagRenovation: data.flagRenovation? '1' : '0',
            //     flagRateRent: data.flagRateRent? '1' : '0',
            //     flagRateCharge: data.flagRateCharge? '1' : '0',
            //     coverage: data.coverage,

            //   });
            // console.log('form....',formRef.current); 
            // setSelectedNames(data.contactType.split(','));
        }
    }, [formData, formData.id]);
    //data,isOpenModal

    const handleSave = (dados: IPlanRelation) => {
        dados.residentialFee = removeMask(dados.residentialFee)
        dados.commercialFee = removeMask(dados.commercialFee)
        dados.bonusAmount = removeMask(dados.bonusAmount)
        dados.clientId = clientPlan;
        dados.flagAnalysis = (isCheckAnalysis == false ? '0' : '1')
        dados.flagRenovation = (isCheckRenovation == false ? '0' : '1')
        dados.flagRateRent = (isCheckRateRent == false ? '0' : '1')
        dados.flagRateCharge = (isCheckRateCharge == false ? '0' : '1')
        dados.setupTransfer = removeMask(dados.setupTransfer);
        console.log(dados);
        //  dados.rateCalc = rateCalc;
        console.log('novo....', dados)
        if (data.id) {
            clientService
                .updatePlanRelation(data.id, dados)
                .then((result) => {
                    setIsLoading(false);

                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        });
                    } else {
                        toastHandler.present({
                            type: 'success',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={"Sucesso"} content={"Adicionado plano com sucesso."} />
                        });
                    }
                }).catch((err: any) => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                });
        }
        else {
            clientService
                .createPlanRelation(dados)
                .then((result) => {
                    setIsLoading(false);

                    if (result instanceof Error) {
                        const titleMessage = "Alerta!!";
                        const contentMessage = "Erro: " + result?.message;

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        });
                    } else {
                        toastHandler.present({
                            type: 'success',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={"Sucesso"} content={"Adicionado plano com sucesso."} />
                        });
                    }
                }).catch((err: any) => {
                    setIsLoading(false);
                    const { titleMessage, contentMessage } = returnMessageError(err);

                    toastHandler.present({
                        type: 'error',
                        position: 'top-right',
                        messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                    })
                });
        }

    }
    const handleMaxValue = (value: string) => {
        console.log(value);
        if (value == '' || value == '0') {
            formRef.current?.setFieldValue('setupTransfer', '0');
        }
        if (parseFloat(value) > 100) {
            formRef.current?.setFieldValue('setupTransfer', '100.00');
            const messageTitle = "Erro no valor";
            const messageContent =
                "Repasse não pode ser maior que 100%";

            toastHandler.present({
                type: "error",
                position: "top-right",
                messageOrContent: (
                    <ToastContent title={messageTitle} content={messageContent} />
                ),
            });
        }

    };

    return (
        <div>
            <Modal
                open={isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Icon style={{ color: "#7924c7", fontSize: "3rem", fontWeight: 100 }}>
                            {messageStyle.icon}
                        </Icon>
                        <Typography
                            variant='caption'
                            color={"#7924c7"}
                            marginBottom="1rem"
                        >
                            {messageStyle.title}
                        </Typography>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            color={"#7924c7"}
                        >
                            {data.id ? 'Alterar ' : 'Adicionar '}
                            um plano comercial
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
                        >
                            <VForm
                                id="formIdSave"
                                placeholder="Your placeholder"
                                onPointerEnterCapture={() => console.log("Pointer entered")}
                                onPointerLeaveCapture={() => console.log("Pointer left")}
                                ref={formRef}
                                onSubmit={handleSave}>
                                <Grid container direction="column" padding={1} spacing={2} >
                                    {isLoading && (
                                        <Grid item>
                                            <LinearProgress variant="indeterminate" />
                                        </Grid>
                                    )}
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            {!data.id && (<AutoCompletePlan
                                                isExternalLoading={isLoading}
                                                labelSelect={'Plano'}
                                                namecampo='planId'
                                                clientId={clientPlan}
                                                onClick={(value: any) => {
                                                    if (value) {
                                                        if (value.personalized == 'false') {
                                                            setModified(true)
                                                        } else {
                                                            setModified(false)
                                                        }
                                                        setShowButtonAdd(true);
                                                        formRef.current?.setFieldValue("lmi", value.lmi);
                                                        formRef.current?.setFieldValue("residentialFee", value.residentialFee);
                                                        formRef.current?.setFieldValue("commercialFee", value.commercialFee);
                                                        formRef.current?.setFieldValue("bonusAmount", value.bonusAmount);
                                                    } else {
                                                        setModified(true);
                                                        formRef.current?.setFieldValue("lmi", '');
                                                        formRef.current?.setFieldValue("residentialFee", ' ');
                                                        formRef.current?.setFieldValue("commercialFee", ' ');
                                                        formRef.current?.setFieldValue("bonusAmount", '');
                                                    }
                                                }}
                                            />)}
                                            {data.id && (<VTextField
                                                fullWidth
                                                label="Plano  Escolhido"
                                                name="planId"
                                                disabled={true}
                                                select
                                            >
                                                <MenuItem value={data.planId}>{data.plan.description}</MenuItem>
                                            </VTextField>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {isSuperAdmin &&
                                        (<Grid container item direction="row" spacing={2}>
                                            <Grid item xs={12} md={3}>
                                                <Typography>
                                                    Utilizado para:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControlLabel control={
                                                    <Switch
                                                        onChange={handleChangeAnalysis}

                                                        name='flagAnalysis'
                                                        color="secondary"
                                                        defaultChecked={isCheckAnalysis}
                                                        disabled={false}
                                                    />}
                                                    label="Analises"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControlLabel control={
                                                    <Switch
                                                        onChange={handleChangeRenovation}
                                                        name='flagRenovation'
                                                        color="secondary"
                                                        defaultChecked={isCheckRenovation}
                                                        disabled={false}
                                                    />}
                                                    label=" Renovação"
                                                />
                                            </Grid>

                                            {/* <InputLabel id="id_rateCalc">
                                                    Modelo de cobrança
                                                </InputLabel>
                                                <Select
                                                    labelId="label_rateCalc"
                                                    id="id_rateCalc"
                                                    fullWidth
                                                    disabled={isLoading}
                                                    // value={rateCalc}
                                                    style={{ height: "40px" }}
                                                    name="rateCalc"
                                                    label="Modelo de cobrança"
                                                    onChange={(e: any) => {
                                                        setRateCalc(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value={"todos"}>
                                                        Todos (aluguel e aluguel + encargos)
                                                    </MenuItem> 
                                                    <MenuItem value={"aluguel"}>
                                                        Somente Aluguel
                                                    </MenuItem>
                                                    <MenuItem value={"encargos"}>
                                                        Aluguel + Encargos
                                                    </MenuItem>

                                                </Select> */}


                                        </Grid>
                                        )}
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <Typography>
                                                Modelo de cobrança
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel control={
                                                <Switch
                                                    onChange={handleChangeRateRent}
                                                    name='flagRateRent'
                                                    color="secondary"
                                                    defaultChecked={isCheckRateRent}
                                                    disabled={false}
                                                />}
                                                label="Somente Aluguel"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel control={
                                                <Switch
                                                    onChange={handleChangeRateCharge}
                                                    name='flagRateCharge'
                                                    color="secondary"
                                                    defaultChecked={isCheckRateCharge}
                                                    disabled={false}
                                                />}
                                                label="Aluguel + encargos"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Cobertura'
                                                name="coverage"
                                                disabled={isLoading}
                                                mask="inteiro"
                                            // InputProps={{
                                            //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            // }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <VMaskTextField
                                                fullWidth
                                                label='% Repasse da taxa de setup'
                                                name="setupTransfer"
                                                disabled={isLoading}
                                                mask="real"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,

                                                }}

                                                onBlur={(e) => handleMaxValue(e.target.value)}
                                                inputProps={{ max: 100, min: 0 }}

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <VTextField
                                                fullWidth
                                                label='LMI'
                                                name="lmi"
                                                select
                                                disabled={modified}

                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            >
                                                {lmiArray.map((index) => {
                                                    return (
                                                        <MenuItem key={index} value={index.toString()}>
                                                            {index.toString()}
                                                        </MenuItem>
                                                    );
                                                })}
                                                {/* <MenuItem value={'12'}>12</MenuItem>
                                                <MenuItem value={'18'}>18</MenuItem>
                                                <MenuItem value={'20'}>20</MenuItem>
                                                <MenuItem value={'24'}>24</MenuItem>
                                                <MenuItem value={'30'}>30</MenuItem>
                                                <MenuItem value={'36'}>36</MenuItem>
                                                <MenuItem value={'40'}>40</MenuItem>
                                                <MenuItem value={'48'}>48</MenuItem>
                                                <MenuItem value={'50'}>50</MenuItem>
                                                <MenuItem value={'52'}>52</MenuItem>
                                                <MenuItem value={'54'}>54</MenuItem>
                                                <MenuItem value={'56'}>56</MenuItem>
                                                <MenuItem value={'58'}>58</MenuItem>
                                                <MenuItem value={'60'}>60</MenuItem> */}

                                            </VTextField>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Tx Residencial %'
                                                name="residentialFee"
                                                disabled={modified}
                                                mask="real"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Tx Comercial %'
                                                name="commercialFee"
                                                disabled={modified}
                                                mask="real"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <VMaskTextField
                                                fullWidth
                                                label='Vl Bônus'
                                                name="bonusAmount"
                                                disabled={modified}
                                                mask="real"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </VForm>
                        </Typography>
                    </DialogContent>
                    <DialogActions
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        {showButtonAdd &&
                            <Button
                                sx={{ background: "#7924c7" }}
                                disableElevation
                                variant={"contained"}
                                startIcon={< Icon > checkIcon</Icon >}
                                onClick={() => {
                                    formRef.current?.submitForm();
                                    onclickConfirm ? onclickConfirm() : false;
                                }}
                            >
                                {textButtonConfirm}
                            </Button>
                        }
                        {showButtonCancel && (
                            <Button
                                onClick={isCloseModal}
                                sx={{ color: "#7924c7" }}
                                disableElevation
                                variant={"outlined"}
                                startIcon={< Icon > closeIcon</Icon >}
                            >
                                Cancelar
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </Modal>
        </div>
    )
}
