import { useEffect, useMemo, useState } from "react";
import {
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { IListagemUsuario, userService } from "../../../shared/services/api/user/userService";
import { Loading, Toolbar, ToolbarGridUser } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useDebounce } from "../../../shared/hooks";
import { Environment } from "../../../shared/environment";
import 'moment/locale/pt-br';
import { checkByProfileGuard, ProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { ListSearchParams } from '../../analysis/types/list-search-params.type';
import moment from 'moment/moment';
import { useProfile } from '../../../context/ProfileContext';
import { useToast } from '../../../shared/hooks';
import { ToastContent } from '../../../shared/components';
import { returnMessageError } from "../../../shared/utils/returnMessageError";


function translate(traduzir: String) {

  if (traduzir === 'estate_admin') { return 'Admin Imobiliária' }
  if (traduzir === 'admin') { return 'Administrador' }

  return traduzir;

};


export const ListagemUsuariosPresentation: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IListagemUsuario[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const hasViewPermission = useMemo(() => {
    return checkByProfileGuard(PermissionRolesEnum.UsersOptions, PermissionActionsEnum.View)
  }, [])
  const toastHandler = useToast();
  const { userClientId, isSuperAdmin, permissionLevel } = useProfile();
  const userLogged: any = {
    ...useProfile()
  }

  const filterInitialParams: ListSearchParams = useMemo(() => {
    return {
      status: 'Todos',
      origem: 'Todos',
      dataIni: moment().startOf('month').subtract(24, 'months').format("YYYY-MM-DD"),
      dataFim: moment().endOf('month').format("YYYY-MM-DD")
    }
  }, [])

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      dataini: filterInitialParams.dataIni,
      datafim: filterInitialParams.dataFim,
      status: filterInitialParams.status
    }, { replace: true })
  }, [])

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const tipoUser = useMemo(() => {
    return (searchParams.get('tipo') || '');
  }, [searchParams]);

  const statusUser = useMemo(() => {
    return (searchParams.get('status') || '');
  }, [searchParams]);

  const clientId = useMemo(() => {
    const idClient = searchParams.get('clientId');
    const userClient = (isSuperAdmin || permissionLevel == 2) ? '' : userClientId;

    return idClient ? idClient : userClient;
  }, [searchParams]);


  const permissaoDescricao = useMemo(() => {
    const permissao = searchParams.get('permissaoDescricao');

    return permissao || '';
  }, [searchParams]);

  useEffect(() => {
    if (hasViewPermission) {
      setIsLoading(true);
      debounce(() => {
        userService.getAll(pagina, busca, tipoUser, statusUser, clientId, permissaoDescricao, JSON.stringify(userLogged))
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              const titleMessage = "Alerta!!";
              const contentMessage = "Erro: " + result?.message;

              toastHandler.present({
                type: 'error',
                position: 'top-right',
                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
              })
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          }).catch((err: any) => {

            setIsLoading(false);
            const { titleMessage, contentMessage } = returnMessageError(err);

            toastHandler.present({
              type: 'error',
              position: 'top-right',
              messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
            })
          });
      });
    }
  }, [busca, pagina, tipoUser, statusUser, clientId, permissaoDescricao]);


  return (
    <LayoutBaseDePagina
      titulo="Usuários"
      subtitulo="Listagem de usuários"
      barraDeFerramentas={
        <Toolbar
          filterInitialParams={filterInitialParams}
          textoButtonNew="novo"
          onClickNovo={() => navigate('/usuarios/detalhe/novo')}
          showInputOrigem={false}
          showPeriodo={false}
          showInputStatus={checkByProfileGuard(PermissionRolesEnum.UsersOptions, PermissionActionsEnum.Filter)}
          showInputClientFilter={checkByProfileGuard(PermissionRolesEnum.UsersOptions, PermissionActionsEnum.Filter)}
          showInputPermissionFilter={checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.View)}
          showButtonNew={checkByProfileGuard(PermissionRolesEnum.UsersOptions, PermissionActionsEnum.Add)}
          onChangeStatus={(retorno: string) => setSearchParams({ busca, pagina: String(pagina), tipo: tipoUser, status: retorno, clientId: clientId, permissaoDescricao }, { replace: true })}
          onChangeClientFilter={
            (retorno: string) => setSearchParams({ busca, pagina: String(pagina), tipo: tipoUser, status: statusUser, clientId: retorno, permissaoDescricao }, { replace: true })
          }
          onChangePermissionFilter={
            (retornoPermissao: string) => setSearchParams({ busca, pagina: String(pagina), tipo: tipoUser, status: statusUser, clientId: clientId, permissaoDescricao: retornoPermissao }, { replace: true })
          }

        />
      }
    >

      <TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: 'auto', padding: 4 }}>
        <Table aria-label="a dense table" >
          <TableHead>
            <TableRow >
              <TableCell colSpan={12} >
                <ToolbarGridUser
                  textSearchGrid={busca}
                  onChangeTextBuscaGrid={texto => setSearchParams({ busca: texto, pagina: '1', tipo: tipoUser, status: statusUser, clientId: clientId }, { replace: true })}
                  showInputBuscaGrid={checkByProfileGuard(PermissionRolesEnum.UsersOptions, PermissionActionsEnum.Filter)}
                  onClickTodos={() => setSearchParams({ busca, pagina: String(pagina), tipo: 'Todos', status: statusUser, clientId: clientId }, { replace: true })}
                  onClickUserImob={() => setSearchParams({ busca, pagina: String(pagina), tipo: 'estate_user', status: statusUser, clientId: clientId }, { replace: true })}
                  onClickImobiliaria={() => setSearchParams({ busca, pagina: String(pagina), tipo: 'estate_admin', status: statusUser, clientId: clientId }, { replace: true })}
                  onClickAdmin={() => setSearchParams({ busca, pagina: String(pagina), tipo: 'admin', status: statusUser, clientId: clientId }, { replace: true })}
                  selectTodos={tipoUser === 'Todos'}
                  selectUserImob={tipoUser === 'estate_user'}
                  selectImobiliaria={tipoUser === 'estate_admin'}
                  selectAdmin={tipoUser === 'admin'}
                  showBotaoAdmin={false}
                  showBotaoImobiliaria={false}
                  showBotaoTodos={false}
                  showBotaoUserImob={false}
                />
              </TableCell>
            </TableRow>

            <TableRow sx={{ background: '#f5fbfd' }} >
              <TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
              <TableCell sx={{ color: "#7e7777b5" }}> Cliente</TableCell>
              <TableCell sx={{ color: "#7e7777b5", textAlign: "center" }} colSpan={3}> Perfil</TableCell>
              <TableCell sx={{ color: "#7e7777b5" }}> Status</TableCell>
              <TableCell sx={{ color: "#7e7777b5" }}> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && (rows?.map(row => (
              <TableRow key={row.id}>
                <TableCell sx={{ fontWeight: "normal" }}>
                  {row.name}
                  <Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} >
                    {row.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal" }}>
                    {row.client ? row.client.name : ''}
                  </Typography>
                </TableCell>

                <TableCell sx={{ textAlign: "center" }} colSpan={3} >
                  <Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal" }}>
                    {translate(row.permission?.description || 'nao informado')}

                  </Typography>
                </TableCell>

                {row.status === "active" ?
                  < TableCell>
                    <IconButton size="small" sx={{ background: "#d9f8e5", borderRadius: "18px", paddingX: "15px" }}>
                      <Icon sx={{ color: "#34D399", fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
                      <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#0f8a0d", fontWeight: "normal" }}>
                        Ativo
                      </Typography>
                    </IconButton>
                  </TableCell>
                  :
                  row.status === "blocked" ?
                    < TableCell >
                      <IconButton size="small" sx={{ background: "#fce4e4", borderRadius: "18px", paddingX: "15px" }}>
                        <Icon sx={{ color: "#202020", fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
                        <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#202020", fontWeight: "normal" }}>
                          Bloqueado
                        </Typography>
                      </IconButton>
                    </TableCell>
                    :
                    < TableCell >
                      <IconButton size="small" sx={{ background: "#fce4e4", borderRadius: "18px", paddingX: "15px" }}>
                        <Icon sx={{ color: "#F87171", fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
                        <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#e40909", fontWeight: "normal" }}>
                          Inativo
                        </Typography>
                      </IconButton>
                    </TableCell>
                }

                <ProfileGuard requiredRole={PermissionRolesEnum.UsersOptions} requiredAction={PermissionActionsEnum.Update}>
                  <TableCell>
                    <IconButton size="small" onClick={() => navigate(`/usuarios/detalhe/${row.id}`)} sx={{ borderRadius: "18px" }}>
                      <Icon sx={{ color: "#0170B3", fontWeight: "normal" }}> app_registrationicon</Icon>
                      <Typography variant="subtitle2" marginLeft={1} sx={{ color: "#0170B3", fontWeight: "normal" }}>
                        Editar
                      </Typography>
                    </IconButton>
                  </TableCell>
                </ProfileGuard>
              </TableRow>
            )))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={5}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0) && (
              <TableRow>
                <TableCell colSpan={5} sx={{ borderBottom: "none" }}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString(), tipo: tipoUser, status: statusUser, clientId: clientId, permissaoDescricao }, { replace: true })}
                  />
                </TableCell>
                <TableCell colSpan={5} sx={{ borderBottom: "none" }} >
                  <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                    Exibindo {" "}
                    {(pagina * Environment.LIMITE_LINHAS) - (Environment.LIMITE_LINHAS - 1)}{" - "}
                    {(Environment.LIMITE_LINHAS * (pagina - 1)) +
                      (Environment.LIMITE_LINHAS * pagina) < totalCount ? Environment.LIMITE_LINHAS * pagina : totalCount} de
                    <Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
                      {" "}{totalCount} {" "}
                    </Typography>
                    registro(s)
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina >
  );
};