import { Clear, Search } from "@mui/icons-material";
import {
	Autocomplete,
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	Paper,
	TextField,
} from "@mui/material";
import * as React from "react";
import { Environment } from "../../environment";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { useNavigate } from "react-router-dom";
import { TAutoCompleteOption } from "../toolbar/ToolBar";

export type TAutoCompleteOptions = {
	id: string;
	label: string;
};

interface IToolbarFinancialProps {
	textSearchGrid?: string;
	showInputBuscaGrid?: boolean;
	onChangeTextBuscaGrid?: (newText: string) => void;
	aoLimparTextBuscaGrid?: (textEmpty: string) => void;

	textButtonFaturamento?: string;
	showButtonFaturamento?: boolean;
	onClickFaturamento?: () => void;
	selectFaturamento?: boolean;

	textButtonBoletos?: string;
	showButtonBoletos?: boolean;
	onClickBoletos?: () => void;
	selectBoletos?: boolean;

	textButtonBordero?: string;
	showButtonBordero?: boolean;
	onClickBordero?: () => void;
	selectBordero?: boolean;

	textButtonRecebimento?: string;
	showButtonRecebimento?: boolean;
	onClickRecebimento?: () => void;
	selectRecebimento?: boolean;

	textButtonReport?: string;
	showButtonReport?: boolean;
	onClickReport?: () => void;
	selectReport?: boolean;

	textButtonNfse?: string;
	showButtonNfse?: boolean;
	onClickNfse?: () => void;
	selectNfse?: boolean;

	textButtonSetup?: string;
	showButtonSetup?: boolean;
	onClickSetup?: () => void;
	selectSetup?: boolean;

	showPeriodo?: boolean;
	onChangeDataIni?: (text: string) => void;
	onChangeDataFim?: (text: string) => void;

	showInputManagerFilter?: boolean;
	textoButtonManager?: string;
	onChangeManagerFilter?: (text: string) => void;

	isDateStartDefault?: boolean;
	textFilterPeriod?: string;
	showFilterPeriod?: boolean;
}

export const ToolbarFinancial: React.FC<IToolbarFinancialProps> = ({
	textSearchGrid: textSearch = "",
	showInputBuscaGrid: showInputBusca = false,
	onChangeTextBuscaGrid: onChangeTextBusca,
	aoLimparTextBuscaGrid: aoLimparTextBusca,

	textButtonBoletos = "Boletos",
	showButtonBoletos = true,
	onClickBoletos,
	selectBoletos = false,

	textButtonBordero = "Bordero",
	showButtonBordero = false,
	onClickBordero,
	selectBordero = false,

	textButtonFaturamento = "Faturamento",
	showButtonFaturamento = true,
	onClickFaturamento,
	selectFaturamento = true,

	textButtonRecebimento = "Recebimento",
	showButtonRecebimento = false,
	onClickRecebimento,
	selectRecebimento = false,

	textButtonReport = "Relatorio",
	showButtonReport = true,
	onClickReport,
	selectReport = false,

	textButtonNfse = "Nota Fiscal",
	showButtonNfse = true,
	onClickNfse,
	selectNfse = false,

	textButtonSetup = "Taxa Setup",
	showButtonSetup = true,
	onClickSetup,
	selectSetup = false,

	showPeriodo = true,
	onChangeDataIni,
	onChangeDataFim,
	isDateStartDefault = false,

	showInputManagerFilter = false,
	textoButtonManager = "Gestor",
	onChangeManagerFilter,

	textFilterPeriod = "Filtrar período",
	showFilterPeriod = false,
}) => {
	const dataStartDefault = moment("01/01/2019").format("YYYY-MM-DD");
	const [dataIni, setDataIni] = useState(isDateStartDefault ? dataStartDefault : moment().startOf("month").format("YYYY-MM-DD"));
	const [dataFim, setDataFim] = useState(moment().endOf("month").format("YYYY-MM-DD"));
	const [isChecked, setIsChecked] = useState(false);
	const [isDisabledPeriodStart, setIsDisabledPeriodStart] = useState(true);
	const [selectedIdManager, setSelectedIdManager] = useState<string | undefined>();
	const [opcoesManager, setOpcoesManager] = useState<TAutoCompleteOption[]>([]);
	const [nameManager, setNameManager] = useState<string>("");
	const navigate = useNavigate();

	const autoCompleteSelectedManager = React.useMemo(() => {
		if (!selectedIdManager) return null;
		const selectedOption = opcoesManager.find((opcao) => opcao.id === selectedIdManager);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedIdManager, opcoesManager]);

	useEffect(() => {
		setIsDisabledPeriodStart(!isChecked);
		if (!isChecked && isDateStartDefault) setDataIni(dataStartDefault);
	}, [isChecked]);

	return (
		<Box gap={1} marginX={1} padding={2} paddingX={3} display="flex" alignItems="space-beetwen" component={Paper}>
			<ButtonGroup aria-label="outlined primary button group">
				{showButtonFaturamento && (
					<Button
						color="primary"
						disableElevation
						variant={selectFaturamento ? "contained" : "outlined"}
						onClick={onClickFaturamento}
						startIcon={<Icon>payments_outlined_Icon</Icon>}
					>
						{textButtonFaturamento}
					</Button>
				)}

				{showButtonBoletos && (
					<Button
						color="primary"
						disableElevation
						variant={selectBoletos ? "contained" : "outlined"}
						onClick={onClickBoletos}
						startIcon={<Icon>payment_outlined_icon</Icon>}
					>
						{textButtonBoletos}
					</Button>
				)}

				{showButtonNfse && (
					<Button
						color="primary"
						disableElevation
						variant={selectNfse ? "contained" : "outlined"}
						onClick={() => {
							navigate(`/notas/`);
							selectNfse;
						}}
						startIcon={<Icon>money_outlined_icon</Icon>}
					>
						{textButtonNfse}
					</Button>
				)}

				{showButtonBordero && (
					<Button
						color="primary"
						disableElevation
						variant={selectBordero ? "contained" : "outlined"}
						onClick={onClickBordero}
						startIcon={<Icon>paid_outlined_icon</Icon>}
					>
						{textButtonBordero}
					</Button>
				)}

				{showButtonSetup && (
					<Button
						color="primary"
						disableElevation
						variant={selectSetup ? "contained" : "outlined"}
						onClick={onClickSetup}
						startIcon={<Icon>paid_outlined_icon</Icon>}
					>
						{textButtonSetup}
					</Button>
				)}

				{showButtonRecebimento && (
					<Button
						color="secondary"
						disableElevation
						variant={selectRecebimento ? "contained" : "outlined"}
						onClick={onClickRecebimento}
						startIcon={<Icon>done_all_icon</Icon>}
					>
						{textButtonRecebimento}
					</Button>
				)}
			</ButtonGroup>

			{showPeriodo && (
				<>
					<Grid item xs={12} sm={12} md={2}>
						<TextField
							fullWidth
							label="Data Inicial"
							name="datainicial"
							size="small"
							type="date"
							disabled={isDateStartDefault ? isDisabledPeriodStart : false}
							value={dataIni}
							onChange={(e) => (setDataIni(moment(e.target.value).format("YYYY-MM-DD")), onChangeDataIni?.(e.target.value))}
						></TextField>
					</Grid>
					<Grid item xs={12} sm={12} md={2}>
						<TextField
							fullWidth
							label="Data Final"
							name="datafinal"
							size="small"
							type="date"
							value={dataFim}
							onChange={(e) => (setDataFim(moment(e.target.value).format("YYYY-MM-DD")), onChangeDataFim?.(e.target.value))}
						></TextField>
					</Grid>
				</>
			)}

			{showInputManagerFilter && (
				<Box width={"200px"}>
					<FormControl fullWidth>
						<Autocomplete
							fullWidth
							openText="Abrir"
							closeText="Fechar"
							noOptionsText="Nenhuma opção"
							loadingText="Loading ..."
							disablePortal
							value={autoCompleteSelectedManager}
							options={opcoesManager}
							size={"small"}
							onInputChange={(_, newValue) => {
								setNameManager(newValue);
							}}
							onChange={(_, value: any) => {
								setSelectedIdManager(value?.id ? value?.id : "");
								onChangeManagerFilter?.(value?.id ? value?.id : ("" as string));
							}}
							renderInput={(params) => <TextField {...params} label={textoButtonManager} name="gestor" />}
						/>
					</FormControl>
				</Box>
			)}

			{showFilterPeriod && (
				<Grid item xs={12} sm={12} md={2}>
					<FormControlLabel
						control={
							<Checkbox sx={{ color: "primary", marginLeft: "15px" }} checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
						}
						sx={{ color: "primary" }}
						label={textFilterPeriod}
					/>
				</Grid>
			)}

			<Box flex={1} display="flex" justifyContent="end">
				{showButtonReport && (
					<Button
						color="primary"
						disableElevation
						variant={selectReport ? "contained" : "outlined"}
						onClick={onClickReport}
						startIcon={<Icon>print_outlined_icon</Icon>}
					>
						{textButtonReport}
					</Button>
				)}
				{showInputBusca && (
					<TextField
						size="small"
						value={textSearch}
						placeholder={Environment.INPUT_BUSCA}
						onChange={(e) => onChangeTextBusca?.(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" edge="start">
										<Search />
									</IconButton>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										edge="end"
										sx={{ color: "#dbd5d5" }}
										onClick={() => onChangeTextBusca?.("")}
									>
										<Clear />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}
			</Box>
		</Box>
	);
};
