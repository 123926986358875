import { Box, Button } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useReset } from '../../../../context'
import { ResetPasswordService } from '../../../services/api/reset-password/ResetPasswordService';
import { BasicModal } from '../../VModal/VModal';

const RecoverPasswordButton: React.FC = () => {
  const {confirmPassword, password, token} = useReset();
  const modalText = 'Sua senha foi alterada com sucesso, faça um novo login para utilizar o sistema';
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleSubmit = async () => {
    try{
      const response = await ResetPasswordService.recoverPassword({
        confirmPassword, 
        password, 
        token
      })
      if(response === 200){
        setIsOpenModal(true);
      } 
    } catch(error: any){
      const err = error as Error;
      alert(err.message)
    }

  }

  const handleConfirm = () => {
    window.location.reload();
  }
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' width='100%'>
      <BasicModal 
      isOpenModal={isOpenModal} 
      messageType={'success'} 
      ITitle='Tudo Certo'
      IMessage={modalText}
      textButtonConfirm='Ok'
      onclickConfirm={handleConfirm}
      />
        <Button
            variant='contained'
            onClick={handleSubmit}
            sx={{ width: 'inherit', background: '#1E325C', margin: '0.25rem 0' }}
        >
            Recuperar Teste
        </Button>
    
    </Box>
  )
}

export default RecoverPasswordButton